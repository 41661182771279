export default {
	nav: {
		data: {
			default: 'My Workspace',
			overview: 'Dashboard'
		},
		page: 'เซลเพจ',
		settings: {
			default: 'ตั้งค่า',
			general: 'ข้อมูลทั่วไป',
			introduction: 'วิดีโอธุรกิจ',
			password: 'เปลี่ยนรหัสผ่าน',
			upgrade: 'อัพเกรดสถานะ',
		},
		account: {
			default: 'การจัดการลูกค้า',
			accounts: 'ลูกค้า',
			orders: 'คำสั่งซื้อ'
		},
		shopping: {
			default: 'ซื้อขายสินค้า',
			product: {
				default: 'สินค้า',
				categories: 'หมวดหมู่สินค้า',
				products: 'รายการสินค้า',
			},
			stock: {
				default: 'สต็อกสินค้า',
				inventories: 'สินค้า',
				movements: 'ความเคลื่อนไหวสต็อก',
			},
			member_orders: 'รายการสั่งซื้อส่วนตัว',
			agent_orders: 'รายการสั่งซื้อจากตัวแทน',
			customer_orders: {
				default: 'คำสั่งซื้อจากลูกค้า',
				orders: 'รายการคำสั่งซื้อ',
				waiting: 'รายการรอจัดส่ง',
				tracking: 'รายการจัดส่งสินค้า',
			}
		},
		accounting: {
			default: 'บัญชี',
			expense_categories: 'หมวดหมู่ค่าใช้จ่าย',
			periods: 'รอบบัญชี',
		},
		report: {
			default: 'รายงาน',
			account: 'ลูกค้า',
			order: 'คำสั่งซื้อ'
		},
		apps: {
			default: 'เชื่อมต่อบริการอื่นๆ',
			liff: 'Line Share',
			page: 'เซลเพจ',
			facebook_group: 'กลุ่มเฟซบุ๊ก'
		},
		shipping: {
			default: 'ระบบขนส่ง',
			general: 'ตั้งค่าทั่วไป',
			couriers: 'รายการขนส่ง'
		},
		payment: {
			default: 'ระบบจ่ายเงินออนไลน์',
			general: 'ตั้งค่าทั่วไป',
			methods: 'วิธีการจ่ายเงิน'
		},
	},
	result_table: {
		filter_action: 'แสดงตัวกรอง',
		hint: ' รายการที่พบ หน้า ',
		hint2: ' จาก ',
		hint_no_page: 'รวมทั้งหมด %{count} รายการ',
		rows_per_page: 'แถวต่อหน้า:',
		page_hint: 'จาก',
		page_hint2: 'มากกว่า ',
		loading: 'กำลังเชื่อมต่อข้อมูล...'
	},
	result_grid: {
		sorting: 'จัดเรียง:',
	},
	empty_data: {
		desc: 'รายละเอียดการค้นหาของคุณไม่ตรงกับข้อมูลที่มีอยู่ กรุณาลองค้นหาด้วย',
		desc_2: 'ข้อมูลอื่นอีกครั้งหรือหากต้องการรีเซ็ตการค้นหาข้อมูลใหม่, เพียงคลิก',
		btn_reset: 'ตั้งค่าค้นหาข้อมูลใหม่',
		remark: 'เพื่อดำเนินการต่อ',
	},
	notifications: {
		title: 'แจ้งเตือน'
	},
	login: {
		title: 'Online Marketing Platform',
		header: 'Online Marketing Platform',
		email: 'อีเมล / ชื่อล็อกอิน',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		forgot_password: 'ลืมรหัสผ่าน ?',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์ / ชื่อล็อกอิน หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!',
		member: {
			header: 'ระยะเวลาใช้งานหมดลงแล้ว',
			contact: 'กรุณาติดต่อผู้ Sponsor',
		}
	},
	forgot_password: {
		title: 'Online Marketing Platform',
		header: 'ระบบจะทำการส่งชื่อผู้ใช้และรหัสผ่านใหม่ ไปยังอีเมลที่ระบุด้านล่างนี้',
		email: 'อีเมล',
		forgot: 'ส่งคำขอรหัสผ่าน',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
		},
		save_success: 'ระบบทำการส่งรายละเอียดเข้าอีเมลเรียบร้อยแล้ว',
		save_fail: 'อีเมล์นี้ไม่มีในระบบ กรุณาติดต่อผู้ดูแลอีกครั้ง',
	},
	privacy: {
		title: 'สัญญาเงื่อนไขและกฏระเบียบ',
		sub_header: 'สัญญาเงื่อนไขและกฏระเบียบ',
		btn_action: 'ดาวน์โหลดเอกสาร PDF'
	},
	overview: {
	},
	settings: {
		title: 'ตั้งค่า',
		tab: {
			default: 'ข้อมูลทั่วไป',
			profile: 'ข้อมูลส่วนตัว',
			group_id: 'Member group',
			group_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
			sub_domain: 'ชื่อเว็บไซต์ส่วนตัว',
			sub_domain_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 3 ตัวอักษร',
			username: 'ชื่อล็อกอิน',
			username_hint: 'รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น ขั้นต่ำ 3 ตัวอักษร',
			email: 'อีเมล',
			signup_url: 'ลิงค์สมัครนักธุรกิจ',
			signup_url_hint: 'ติดต่อผู้ดูแลระบบเพื่อรับลิงค์ส่วนตัวของคุณ',
			firstname: 'ชื่อจริง',
			lastname: 'นามสกุล',
			nick_name: 'ชื่อเล่น',
			birthdate: 'วันเกิด',
			card_id: 'หมายเลขบัตรประชาชน',
			phone: 'เบอร์โทรศัพท์',
			address: 'ที่อยู่',
			postal_code: 'รหัสไปรษณีย์',
			province_name: 'จังหวัด',
			city_name: 'อำเภอ/เขต',
			district_name: 'ตำบล/แขวง',
			phone_placeholder: '081 234 5678',
			social: 'Social Network',
			line: 'Line Id',
			line_placeholder: '@line',
			facebook: 'Facebook',
			facebook_placeholder: 'www.facebook.com',
			instagram: 'Instagram',
			instagram_placeholder: 'www.instagram.com',
			hashtag: 'แฮชแท็ก',
			hashtag_placeholder: 'ceoonline',
			save_fail: 'ชื่อเว็บไซต์ส่วนตัว อีเมล หรือ ชื่อล็อกอินของคุณมีสมาชิกคนอื่นใช้แล้ว กรุณาลองใหม่อีกครั้ง',
			save_success: 'บันทึกเรียบร้อยแล้วว',
			upload_profile_success: 'เปลี่ยนภาพโปรไฟล์เรียบร้อยแล้ว',
			validator: {
				sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
				sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
				sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
				email_invalid: '^อีเมล์ไม่ถูกต้อง',
				username: '^กรุณากรอกชื่อล็อกอิน',
				username_length: '^ความยาวชื่อล็อกอินอย่างน้อย 6 ตัวอักษร',
				username_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
				signup_url_invalid: '^กรุณาระบุ http:// หรือ https:// นำหน้า',
				firstname: '^กรุณากรอกชื่อจริง',
				lastname: '^กรุณากรอกนามสกุล',
				nick_name: '^กรุณากรอกชื่อเล่น',
				card_id: '^กรุณากรอกหมายเลขบัตรประชาชน',
				phone: '^กรุณากรอกเบอร์โทรศัพท์',
				address: '^กรุณากรอกที่อยู่',
				postal_code: '^กรุณากรอกรหัสไปรษณีย์'
			},
		},
		tab2: {
			default: 'ความปลอดภัย',
			change_password: 'เปลี่ยนรหัสผ่าน',
			password: 'รหัสผ่าน',
			confirm_password: 'ยืนยันรหัสผ่าน',
			save_success: 'บันทึกเรียบร้อยแล้วว',
			validator: {
				password: '^กรุณากรอกรหัสผ่าน',
				confirm_password: '^กรุณากรอกยืนยันรหัสผ่าน',
				password_not_match: 'รหัสผ่านไม่ตรงกัน'
			},
		},
		tab3: {
			default: 'Line Notify',
			stepper: {
				step1: {
					default: 'ยืนยันข้อมูล Line ส่วนตัว',
					header: 'รับการแจ้งเตือนจากเว็บเซอร์วิสทาง LINE',
					sub_header: 'หลังเสร็จสิ้นการเชื่อมต่อกับเว็บเซอร์วิสแล้ว คุณจะได้รับการแจ้งเตือนจากบัญชีทางการ "LINE Notify" ซึ่งให้บริการโดย LINE คุณสามารถเชื่อมต่อกับบริการที่หลากหลาย และรับการแจ้งเตือนทางกลุ่มได้ด้วย',
					btn_action: 'ยืนยันข้อมูล Line Account'
				},
				step2: {
					default: 'สร้าง Line Access Token',
					header: 'สร้าง Line Notify Access Token',
					sub_header: 'เมื่อยืนยันตัวตนผ่าน Line แล้ว ขั้นตอนต่อไปคือการขอ Access Token สำหรับส่งข้อความผ่าน "LINE Notify"',
					btn_action: 'สร้าง Token'
				},
				step3: {
					default: 'บันทึก Token ลงข้อมูลส่วนตัว',
					header: 'บันทึกข้อมูล Access Token',
					sub_header: 'โปรดอย่าส่ง Access Token (แสดงด้านล่าง) ให้บุคคลอื่นๆ เนื่องจากข้อมูลนี้เป็นข้อมูลเฉพาะของคุณเองเท่านั้น',
					btn_action: 'Update Profile with Token',
					testing_message: 'เริ่มต้นการเชื่อมต่อ Line Notify'
				}
			},
			save_success: 'บันทึก Line Notify Token เรียบร้อยแล้วว',
			fail_success: 'ไม่สามารถร้องขอ Line Notify Token ได้, กรุณาเริ่มขั้นตอนที่ 1 ใหม่',
		},
	},
	page: {
		title: 'เซลเพจ',
		header: 'รายการเซลเพจ',
		action: 'เพิ่มรายการ',
		import_action: 'ดาวน์โหลดเซลเพจตั้งต้น',
		limit: 'จำนวนเซลเพจที่สร้างแล้ว %{total}/%{limit} หน้า',
		no_data: {
			title: 'ไม่พบรายการเซลเพจ',
			desc: 'คุณสร้างสามารถสร้างเซลเพจของคุณเองได้',
			desc_2: 'ผ่านตัวช่วยสร้างเซลเพจที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
			btn_add: 'เพิ่มเซลเพจใหม่ทันที',
			remark: 'เพื่อดำเนินการต่อ',
		},
		card: {
			default: 'หน้าหลัก',
		},
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		create: {
			header: 'สร้างข้อมูลเซลเพจ',
			save_fail: 'ไม่สามารถบันทึกข้อมูลเซลเพจ เนื่องจากลิงค์โปรโมทมีอยู่แล้วในระบบ',
		},
		main: {
			title: 'ดาวน์โหลดเซลเพจตั้งต้น',
			subheader: 'ด้านล่างนี้เป็นเซลเพจกลางที่ผู้ใช้สามารถนำไปใช้โปรโมทได้',
			item_action: 'ดาวน์โหลด',
			item_status: 'ดาวน์โหลดแล้ว',
		},
		detail: {
			title: 'แก้ไขรายการ​เซลเพจ',
			header: 'จัดการข้อมูลเซลเพจ',
			shopping_header: 'โปรโมทเว็บไซต์สินค้า',
			tab: {
				title: 'ข้อมูลทั่วไป',
				general: {
					header: 'ข้อมูลทั่วไป',
					type: 'ประเภท',
					type_lead: 'ค้นหาผู้มุ่งหวัง',
					type_product: 'ขายสินค้า',
					type_business: 'แนะนำธุรกิจ',
					type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					product_id: 'เลือกสินค้า',
					product_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title: 'ชื่อเซลเพจ',
					title_hint: 'จำกัดตัวอักษร %{count}/50',
					real_url: 'ลิงค์จริง',
					shortened_url: 'ลิงค์โปรโมท',
					shortened_url_placeholder: 'ไม่พบลิงค์โปรโมท คลิกปุ่มด้านขวาเพื่อสร้างลิงค์ใหม่',
					thanks_redirect_url: 'ลิงค์ Redirect สำหรับหน้าขอบคุณ',
					thanks_redirect_url_hint: 'เข้าสู่ลิงค์นี้โดยอัตโนมัติเมื่อเข้าสู่หน้าขอบคุณ',
					thanks_redirect_delay: 'ระยะเวลาก่อนเปลี่ยนลิงค์​ (วินาที)',
					is_default: 'ตั้งค่าเป็นหน้าหลักของคุณ',
					is_default_hint: 'เปิด-ปิด เพื่อตั้งค่าเซลเพจเป็นหน้าหลักโดเมนของคุณ',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเซลเพจต่อผู้ใช้ทั่วไป',
					copy_action: 'คัดลอกลิงค์',
					copy_result: 'คัดลอกลิงค์เรียบร้อยแล้ว',
					product_rates: {
						header: 'กำหนดรูปแบบสินค้ารายการย่อย',
						title: 'รายละเอียดสินค้า',
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						no_data: 'ไม่พบรูปแบบสินค้ารายการย่อยที่กำหนดไว้'
					},
					rates: {
						header: 'กำหนดค่าขนส่งตามยอดสั่งซื้อ',
						subheader: '***หากไม่คิดค่าจัดส่ง สามารถข้ามการกรอกส่วนนี้ได้',
						startPrice: 'ยอดสั่งซื้อระหว่าง',
						endPrice: 'ถึง',
						shippingPrice: 'คิดค่าขนส่ง',
						no_data: 'ไม่พบรายการค่าขนส่งกำหนดไว้'
					},
					image: {
						header: 'อัพโหลดภาพปก',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 480x480'
					},
					logo: {
						header: 'อัพโหลดภาพโลโก้',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดความสูงภาพแนะนำ 64px'
					},
					validator: {
						title: '^กรุณากรอกชื่อเซลเพจ',
						title_length: '^ความยาวชื่อเซลเพจอย่างน้อย 6 ตัวอักษร',
						title_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
						product_id: '^กรุณาเลือกสินค้า',
					},
					other_actions: {
						header: 'ดำเนินการอื่นๆ',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลเซลเพจ'
					}
				},
				caption: {
					header: 'Caption สำหรับแชร์ผ่าน Line',
					caption: 'ข้อความสำหรับแชร์',
				},
				social: {
					header: 'Social Network',
					line: 'Line Id',
					line_placeholder: '@line',
					messenger: 'Facebook messenger',
					messenger_placeholder: '@m.me',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: '081 234 5678',
				},
				save_success: 'บันทึกข้อมูลเซลเพจเรียบร้อยแล้ว',
				save_fail: 'ไม่สามารถบันทึกข้อมูลเซลเพจ เนื่องจากลิงค์โปรโมทมีอยู่แล้วในระบบ',
			},
			tab2: {
				title: 'Page Editor',
				title_2: 'Thanks Editor',
				no_data: 'ไม่พบการแสดงผลในหน้านี้ กรุณาเลือกเครื่องมือทางด้านขวา',
				components: {
					text: 'ข้อความ',
					image: 'รูปภาพ',
					video: 'วิดีโอ',
					contact_form: 'ฟอร์มติดต่อ',
					call_to_actions: 'Call to Actions',
					slide_images: 'สไลด์รูปภาพ',
					slide_products: 'ขายสินค้า',
					countdown_timer: 'Flash Sale',
				},
				blocks: {
					slide_images: {
						image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						image_info: 'ภาพทั้งหมดที่นำมาใส่ควรมีขนาดเท่ากัน'
					},
					slide_products: {
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						no_data: 'ไม่พบรายการสินค้า กด + เพื่อเพิ่มสินค้า',
						modal: {
							header: 'เพิ่มรายการสินค้า',
							search_placeholder: 'ค้นหาชื่อสินค้า',
						}
					},
					image: {
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 800x800',
						action: 'ตั้งค่ารูปภาพ',
						settings: {
							header: 'ตั้งค่ารูปภาพ',
							sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
							link: 'ลิงค์',
						}
					},
					contact_form: {
						email: 'อีเมล',
						email_hint: 'john.doe@mail.com',
						firstname: 'ชื่อจริง',
						firstname_hint: 'John',
						lastname: 'นามสกุล',
						lastname_hint: 'Doe',
						phone: 'เบอร์โทรศัพท์',
						phone_hint: '089 281 8212',
						action: 'ตั้งค่าฟอร์ม',
						settings: {
							header: 'ตั้งค่าฟอร์มติดต่อ',
							sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
							mode: 'รูปแบบการแสดงผล',
							mode_normal: 'ปกติ',
							mode_popup: 'ป๊อบอัพ',
						}
					},
					video: {
						label: 'Url วิดีโอ',
						hint: 'รองรับ YouTube, Vimeo'
					},
					two_block_image: {
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 800x800'
					},
					countdown_timer: {
						title: 'รายละเอียด Flash Sale',
						target_date: 'วันที่สิ้นสุด',
						target_time: 'เวลา'
					}
				},
				actions: {
					up: 'เลื่อนขึ้น',
					down: 'เลื่อนลง',
					remove: 'นำออก'
				},
				butttons: {
					header: 'ตั้งค่าปุ่ม Call to Actions',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้ปุ่มแสดงผลต่อผู้ใช้ได้ตรงตามความต้องการ',
					text: 'ข้อความ',
					link: 'ลิงค์',
					color: 'สี',
					outlined: 'เส้นขอบ',
					outlined_hint: 'แสดงเส้นขอบพื้นหลักตามสีที่เลือก และไม่แสดงผลพื้นหลังของปุ่ม'
				},
				settings: {
					header: 'ตั้งค่า',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้การจัดการ Page ทำได้ง่ายและตามความต้องการของผู้ใช้มากขึ้น',
					percent_line: 'แสดงระดับการ Scroll',
					percent_line_hint: 'สำหรับ Page Scroll 0 - 100% ซึ่งจะสอดคล้องกับ Facebook Pixel',
					display_mobile: 'ตัวเลือกแสดงผลบน PC, Laptop หรือมือถือ',
					display_mobile_hint: '"เปิด" เพื่อตั้งค่า Page เป็นแบบกว้างสำหรับ PC, Laptop และ "ปิด" เพื่อตั้งค่าแบบเล็กสำหรับบนมือถือ',
				},
				save_success: 'บันทึกข้อมูลเซลเพจเรียบร้อยแล้ว',
			},
			tab3: {
				title: 'Analytics',
				seo_title: 'Google SEO',
				seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
				meta_title: 'Title',
				meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
				meta_desc: 'Description',
				meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
				meta_keyword: 'Keyword',
				meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
				meta_keyword_placeholder: 'กรอกคีย์เวิร์ดและกดปุ่ม "Enter"',
				pixel_title: 'Facebook Pixel',
				pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
				og_title: 'Title',
				og_title_hint: 'จำกัดตัวอักษร %{count}/95',
				og_desc: 'Description',
				og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
				og_image: 'ภาพประกอบ',
				og_image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				og_image_info: 'ขนาดภาพแนะนำ 1200x630',
				validator: {
					meta_title: '^กรุณากรอก Title',
					meta_desc: '^กรุณากรอก Description',
					og_title: '^กรุณากรอก Title',
					og_desc: '^กรุณากรอก Description',
				},
				save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
			},
			tab4: {
				title: 'Ads & Pixel',
				google: {
					title: 'Google Tag Manager',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					gtm_container_id: 'รหัสคอนเทนเนอร์',
					validator: {
						gtm_container_id: '^กรุณากรอกรหัสคอนเทนเนอร์',
					}
				},
				facebook: {
					title: 'Facebook Pixel',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					facebook_domain_verify: 'รหัสยืนยันโดเมน',
					facebook_domain_verify_hint: 'อัพเกรดความน่าเชื่อถือของโดเมนเพื่อรองรับ iOS 14',
					main_facebook_pixel: 'Pixel หลัก',
					others_facebook_pixel: 'Pixel รอง',
					others_facebook_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_facebook_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					main_facebook_token: 'Conversion Token หลัก',
					others_facebook_token: 'Conversion Token รอง',
					others_facebook_token_hint: 'ไม่จำกัดจำนวน Conversion Token รอง',
					others_facebook_token_placeholder: 'กรอก Conversion Token และกดปุ่ม "Enter"',
					validator: {
						facebook_domain_verify: '^กรุณากรอกรหัสยืนยันโดเมน',
						main_facebook_pixel: '^กรุณากรอก Pixel หลัก',
						main_facebook_token: '^กรุณากรอก Conversion Token หลัก',
					}
				},
				tiktok: {
					title: 'Tiktok - Website Pixel',
					sub_header: 'เพิ่มประสิทธิภาพในการเข้าถึงเว็บไซต์ของคุณผ่าน Tiktok',
					main_tiktok_pixel: 'Pixel หลัก',
					others_tiktok_pixel: 'Pixel รอง',
					others_tiktok_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_tiktok_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					validator: {
						main_tiktok_pixel: '^กรุณากรอก Pixel หลัก',
					}
				},
				save_success: 'บันทึกข้อมูล Ads & Pixel เรียบร้อยแล้ว',
			},
		}
	},
	accounting: {
		periods: {
			title: 'รายการรอบบัญชี',
			header: 'รายการรอบบัญชี',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อรายการ',
			result_title: 'รายการรอบบัญชีทั้งหมด',
			item_col: 'ชื่อรายการ',
			item_col2: 'ช่วงเวลา',
			item_col3: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการรอบบัญชี',
			delete_success: 'ลบข้อมูลรายการเรียบร้อยแล้ว',
			create: {
				header: 'สร้างรายการรอบบัญชี',
				save_fail: 'ไม่สามารถสร้างข้อมูลรายการได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'รายละเอียดรอบบัญชี',
				header: 'รายละเอียดรอบบัญชี',
				tab: {
					default: 'ข้อมูลทั่วไป',
					title: 'แก้ไขรอบบัญชี',
					header: 'จัดการข้อมูลรอบบัญชี',
					status_active: 'เปิดสาธารณะ',
					status_inactive: 'ซ่อนการมองเห็น',
					form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
					title_: 'ชื่อรายการ',
					title_hint: 'จำกัดตัวอักษร %{count}/100',
					start_date: 'เริ่มรอบตั้งแต่',
					end_date: 'จนถึง',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
					save_success: 'บันทึกรายการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
					validator: {
						title: '^กรุณากรอกชื่อรายการ',
						start_date: '^กรุณากรอกวันเริ่มต้น',
						end_date: '^กรุณากรอกวันสิ้นสุด',
					},
					other_actions: {
						header: 'Other Actions',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลรายการรอบบัญชี',
					}
				},
				tab2: {
					default: 'รายการค่าใช้จ่าย',
					search: {
						search_placeholder: 'ค้นหารายการค่าใช้จ่าย',
						start_date: 'ตั้งแต่วันที่',
						end_date: 'จนถึง',
					},
					result_title: 'รายการค่าใช้จ่ายทั้งหมด',
					item_col: 'วันที่',
					item_col2: 'หมวดหมู่',
					item_col3: 'รายละเอียด',
					item_col4: 'รูปแบบการชำระ',
					item_col4_credit: 'บัตร Credit/Debit',
					item_col4_transfer: 'โอนเงิน',
					item_col4_cash: 'เงินสด',
					item_col4_others: 'อื่นๆ',
					item_col5: 'จำนวนเงิน',
					item_col6: 'Action',
					total_price: 'รวมทั้งสิ้น',
					no_data: 'ไม่พบข้อมูลรายการค่าใช้จ่ายจากรอบบัญชีนี้',
					payment_method: {
						credit: 'บัตร Credit/Debit',
						transfer: 'โอนเงิน',
						cash: 'เงินสด',
						others: 'อื่นๆ',
					},
					create: {
						header: 'สร้างรายการค่าใช้จ่าย',
						header_2: 'แก้ไขรายการค่าใช้จ่าย',
						target_date: 'วันที่',
						category_id: 'หมวดหมู่',
						category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						title_: 'รายละเอียด',
						title_hint: 'จำกัดตัวอักษร %{count}/100',
						payment_method: 'รูปแบบการชำระ',
						payment_method_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						total_price: 'จำนวนเงิน',
						remark: 'หมายเหตุ',
						save_fail: 'ไม่สามารถสร้างข้อมูลค่าใช้จ่ายได้ กรุณาติดต่อผู้ดูแลระบบ',
						validator: {
							target_date: '^กรุณากรอกชื่อรายการ',
							title: '^กรุณากรอกรายละเอียด',
							total_price: '^กรุณากรอกจำนวนเงิน'
						},
					},
				},
			}
		},
		expense: {
			categories: {
				title: 'รายการหมวดหมู่ค่าใช้จ่าย',
				name: 'รายการหมวดหมู่ค่าใช้จ่าย',
				header: 'รายการหมวดหมู่ค่าใช้จ่าย',
				action: 'เพิ่มรายการ',
				search_placeholder: 'ค้นหาชื่อหมวดหมู่',
				result_title: 'รายการหมวดหมู่ค่าใช้จ่ายทั้งหมด',
				item_col: 'ชื่อหมวดหมู่',
				item_col2: 'สถานะ',
				item_col3: 'Actions',
				no_data: 'ไม่พบข้อมูลรายการโปรโมชัน',
				delete_success: 'ลบข้อมูลโปรโมชันเรียบร้อยแล้ว',
				create: {
					header: 'สร้างรายการหมวดหมู่ค่าใช้จ่าย',
					save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ค่าใช้จ่ายได้ กรุณาติดต่อผู้ดูแลระบบ'
				},
				detail: {
					name_: 'แก้ไขรายการหมวดหมู่ค่าใช้จ่าย',
					header: 'จัดการข้อมูลหมวดหมู่ค่าใช้จ่าย',
					status_active: 'เปิดสาธารณะ',
					status_inactive: 'ซ่อนการมองเห็น',
					form_header: 'แก้ไขหมวดหมู่ค่าใช้จ่าย',
					form_sub_header: 'รายละเอียดหมวดหมู่ค่าใช้จ่ายสำหรับการกรอกตัดรอบบัญชี',
					name: 'ชื่อหมวดหมู่ค่าใช้จ่าย',
					name_hint: 'จำกัดตัวอักษร %{count}/100',
					discount_percent: 'ส่วนลดเปอร์เซ็นต์',
					discount_price: 'ส่วนลดเงินสด',
					start_date: 'เริ่มโปรโมชันตั้งแต่',
					end_date: 'จนถึง',
					is_enabled: 'เปิดเผยต่อสาธารณะ',
					is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าหมวดหมู่สำหรับรายจ่ายทั้งหมดของรอบบัญชี',
					save_success: 'บันทึกรายการข้อมูลหมวดหมู่ค่าใช้จ่ายเรียบร้อยแล้ว',
					validator: {
						name: '^กรุณากรอกชื่อรายการหมวดหมู่ค่าใช้จ่าย',
						start_date: '^กรุณากรอกเวลาเริ่มต้น',
						end_date: '^กรุณากรอกเวลาสิ้นสุด',
					},
					other_actions: {
						header: 'ดำเนินการอื่นๆ',
						delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลค่าใช้จ่ายที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
						btn_delete: 'ลบข้อมูลรายการหมวดหมู่ค่าใช้จ่าย'
					}
				}
			},
		},
	},
	promotion: {
		promotions: {
			title: 'รายการโปรโมชัน',
			header: 'รายการโปรโมชัน',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อโปรโมชัน',
			result_title: 'รายการโปรโมชันทั้งหมด',
			item_col: 'ชื่อโปรโมชัน',
			item_col2: 'ช่วงเวลา',
			item_col3: 'ลดราคา',
			item_col4: 'สถานะ',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการโปรโมชัน',
			delete_success: 'ลบข้อมูลโปรโมชันเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลโปรโมชัน',
				save_fail: 'ไม่สามารถสร้างข้อมูลโปรโมชันได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title_: 'แก้ไขรายการโปรโมชัน',
				header: 'จัดการข้อมูลโปรโมชัน',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขโปรโมชัน',
				form_sub_header: 'รายละเอียดโปรโมชันส่วนลดสำหรับสินค้าผ่านเซลเพจ',
				title: 'ชื่อโปรโมชัน',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				discount_percent: 'ส่วนลดเปอร์เซ็นต์',
				discount_price: 'ส่วนลดเงินสด',
				start_date: 'เริ่มโปรโมชันตั้งแต่',
				end_date: 'จนถึง',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าโปรโมชันสำหรับสินค้าทั้งหมดบนเซลเพจ',
				save_success: 'บันทึกรายการข้อมูลโปรโมชันเรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อสินค้า',
					start_date: '^กรุณากรอกเวลาเริ่มต้น',
					end_date: '^กรุณากรอกเวลาสิ้นสุด',
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลโปรโมชัน'
				}
			}
		},
	},
	coupon: {
		coupons: {
			title: 'รายการคูปอง',
			header: 'รายการคูปอง',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อคูปอง',
			result_title: 'รายการคูปองทั้งหมด',
			item_col: 'ชื่อคูปอง',
			item_col2: 'ช่วงเวลา',
			item_col3: 'ลดราคา',
			item_col4: 'สถานะ',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการคูปอง',
			delete_success: 'ลบข้อมูลคูปองเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลคูปอง',
				save_fail: 'ไม่สามารถสร้างข้อมูลคูปองได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title_: 'แก้ไขรายการคูปอง',
				header: 'จัดการข้อมูลคูปอง',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขคูปอง',
				form_sub_header: 'รายละเอียดคูปองส่วนลดสำหรับสินค้าผ่านเซลเพจ',
				title: 'ชื่อคูปอง',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				discount_percent: 'ส่วนลดเปอร์เซ็นต์',
				discount_price: 'ส่วนลดเงินสด',
				min_price: 'จำนวนเงินขั้นต่ำ',
				start_date: 'เริ่มคูปองตั้งแต่',
				end_date: 'จนถึง',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าคูปองสำหรับสินค้าทั้งหมดบนเซลเพจ',
				save_success: 'บันทึกรายการข้อมูลคูปองเรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อคูปอง',
					start_date: '^กรุณากรอกเวลาเริ่มต้น',
					end_date: '^กรุณากรอกเวลาสิ้นสุด',
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลคูปอง'
				}
			}
		},
	},
	shopping: {
		categories: {
			title: 'รายการหมวดหมู่',
			header: 'รายการหมวดหมู่',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อหมวดหมู่',
			result_title: 'รายการหมวดหมู่ทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'จำนวนสินค้า',
			item_col3: 'แก้ไขล่าสุด',
			item_col4: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
			delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลหมวดหมู่',
				save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลหมวดหมู่',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขหมวดหมู่',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อหมวดหมู่',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อหมวดหมู่',
				},
				related_list: {
					header: 'สินค้าที่เกี่ยวข้อง',
					btn_see_all: 'ดูสินค้าทั้งหมด',
					no_data: 'ไม่พบข้อมูลรายการสินค้าในหมวดหมู่นี้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลหมวดหมู่'
				}
			}
		},
		products: {
			title: 'รายการสินค้า',
			header: 'รายการสินค้า',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ชื่อสินค้า',
			result_title: 'รายการสินค้าทั้งหมด',
			item_col: 'ชื่อสินค้า',
			item_col2: 'หมวดหมู่',
			item_col3: 'สถานะคงเหลือ',
			item_col3_in: 'มีสินค้า',
			item_col3_out: 'สินค้าใกล้หมด',
			item_col4: 'สถานะ',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการสินค้า',
			delete_success: 'ลบข้อมูลสินค้าเรียบร้อยแล้ว',
			create: {
				header: 'สร้างข้อมูลสินค้า',
				save_fail: 'ไม่สามารถสร้างข้อมูลสินค้าได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการสินค้า',
				header: 'จัดการข้อมูลสินค้า',
				status_active: 'เปิดสาธารณะ',
				status_inactive: 'ซ่อนการมองเห็น',
				form_header: 'แก้ไขสินค้า',
				form_sub_header: 'รายละเอียดสินค้าสำหรับเว็บไซต์สินค้า',
				category_id: 'หมวดหมู่สินค้า',
				category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				name: 'ชื่อสินค้า',
				name_hint: 'จำกัดตัวอักษร %{count}/250',
				stock: 'จำนวนสินค้าใน Stock',
				notify_stock: 'แจ้งเตือนจำนวนสินค้าใน Stock ขั้นต่ำ',
				price: 'ราคาเต็ม',
				sale_price: 'ราคาขาย',
				has_attribute: 'ตัวเลือกลักษณะสินค้า',
				has_attribute_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงตัวเลือกลักษณะสินค้า',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงสินค้านี้ต่อผู้ใช้ระบบ โดยจะมีผลภายใน 24 ชม.',
				save_success: 'บันทึกรายการข้อมูลสินค้าเรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อสินค้า',
					price: '^กรุณากรอกราคาเต็ม',
					sale_price: '^กรุณากรอกราคาขาย',
					stock: '^กรุณากรอกจำนวนสินค้า',
					notify_stock: '^กรุณากรอกแจ้งเตือนจำนวนสินค้า',
					variation_name: '^กรุณากรอกชื่อตัวเลือกลักษณะสินค้า',
				},
				images: {
					header: 'ภาพประกอบสินค้า (%{count}/%{maximum})',
					sub_header: 'ขนาดภาพแนะนำ 800x800 และภาพที่ 1 จะกลายเป็นภาพหลักของสินค้า',
				},
				rates: {
					header: 'กำหนดค่าส่งตามรายการสินค้า',
					price: 'ราคาจัดส่งเหมารวม',
					start: 'จำนวนระหว่าง',
					end: 'ถึง',
					no_data: 'กรุณาเพิ่มรายการค่าส่งตามรายการสินค้า'
				},
				variations: {
					header: 'กำหนดคุณลักษณะรายการสินค้า',
					sku: 'SKU',
					stock: 'จำนวน',
					name: 'ลักษณะ',
					price: 'ราคาต่อชิ้น',
					sale_price: 'ราคาขาย',
					no_data: 'กรุณาเพิ่มรายการคุณลักษณะรายการสินค้า'
				},
				features: {
					header: 'ตัวช่วยการขาย',
					sub_header: 'ตั้งค่าการแสดงผลข้อมูลพิเศษเพิ่มเติม',
					is_new: 'สินค้าใหม่',
					is_top: 'สินค้าขายดี'
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลสินค้า'
				}
			}
		},
		stock: {
			inventories: {
				title: 'รายการสินค้า',
				header: 'รายการสินค้า',
				search_placeholder: 'ชื่อสินค้า',
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'จำนวน',
				item_col4: 'อัพเดตล่าสุด',
				no_data: 'ไม่พบรายการสินค้าในสต็อก'
			},
			movements: {
				title: 'รายการความเคลื่อนไหวสินค้า',
				header: 'รายการความเคลื่อนไหวสินค้า',
				search: {
					search_placeholder: 'ค้นหาชื่อสินค้า',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'ต้นทาง/ปลายทาง',
				item_col3_default: 'บริษัท',
				item_col4: 'จำนวน',
				item_col5: 'เกิดขึ้นเมื่อ',
				no_data: 'ไม่พบรายการความเคลื่อนไหวสินค้า'
			},
		},
		customer_orders: {
			title: 'รายการคำสั่งซื้อจากลูกค้า',
			header: 'รายการคำสั่งซื้อจากลูกค้า',
			search: {
				search_placeholder: 'หมายเลขคำสั่งซื้อ, ชื่อลูกค้า',
				status: 'สถานะ',
				all_status: 'ทั้งหมด',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			result_title: 'รายการคำสั่งซื้อทั้งหมด',
			item_col: 'หมายเลขคำสั่งซื้อ',
			item_col_cod: 'COD',
			item_col2: 'ยอดรวมทั้งหมด',
			item_col3: 'สถานะ',
			item_col4: 'วันที่สั่งซื้อ',
			item_col5: 'Action',
			no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			save_status_success: 'บันทึกสถานะสั่งซื้อเรียบร้อยแล้ว',
			status: {
				cancelled: 'ยกเลิกคำสั่งซื้อ',
				new: 'ได้รับคำสั่งซื้อ',
				paid: 'จ่ายเงินแล้ว',
				confirm_paid: 'ยืนยันการจ่ายแล้ว',
				ready_to_ship: 'พร้อมจัดส่ง',
				shipped: 'จัดส่งแล้ว',
				delivered: 'ส่งถึงปลายทางแล้ว',
				cancel: 'ยกเลิก',
				return: 'ส่งสินค้าคืน',
			},
			detail: {
				title: 'คำสั่งซื้อจากลูกค้า',
				header: 'จัดการข้อมูลคำสั่งซื้อจากลูกค้า',
				status_action: 'บันทึกสถานะ',
				shipping_action: 'เลือกขนส่งที่จะใช้บริการ',
				form_header: 'หมายเลขคำสั่งซื้อ',
				form_sub_header: 'รายการสินค้าและรายละเอียดที่อยู่จัดส่ง',
				created_date: 'จัดทำวันที่',
				results: {
					item_col0: 'ลำดับ',
					item_col: 'ชื่อสินค้า',
					item_col2: 'ราคาต่อชิ้น',
					item_col3: 'จำนวน',
					item_col4: 'ยอดรวม',
					unit_price: 'ราคา',
					qty: 'จำนวน',
					total: 'ยอดรวม',
					shipping_price: 'ค่าจัดส่ง',
					discount_price: 'ส่วนลด',
					total_price: 'ราคาสินค้า',
					net_price: 'ยอดที่ได้รับชำระ',
				},
				payment: {
					header: 'รายละเอียดการชำระเงิน',
					cod: 'ลูกค้าเลือกชำระปลายทาง (COD)',
					no_data: 'ยังไม่ได้รับแจ้งการจ่ายเงิน',
					action: 'ยืนยันรับชำระเงิน',
					confirm_message: 'ยืนยันการชำระเงินถูกต้องตามคำสั่งซื้อ?',
					save_success: 'ยืนยันการชำระเงินเรียบร้อยแล้ว'
				},
				parcels: {
					header: 'รายการจัดเตรียมพัสดุ',
					sub_header: 'หากมีการปรับเปลี่ยนข้อมูลพัสดุ กรุณาบันทึกข้อมูล เพื่อแสดงรายการขนส่งตามจำนวนพัสดุ',
					cod_hint: '***ค่าเก็บเงินปลายทางอยู่ในช่วง 100 - 50,000 บาท',
					is_parcel_ready: 'เตรียมพัสดุพร้อมแล้วสำหรับการจัดส่ง',
					confirm_ready: 'กรุณายืนยันการเตรียมพัสดุก่อนการส่ง ซึ่งจะทำให้คุณจะไม่สามารถแก้ไขรายการพัสดุและตรวจสอบขนส่งได้',
					no_data: 'กรุณาเพิ่มรายการพัสดุที่บรรจุสินค้า',
					box: 'ขนาดกล่อง / ซอง',
					parcel_weight: 'น้ำหนัก',
					parcel_width: 'กว้าง',
					parcel_length: 'ยาว',
					parcel_height: 'สูง',
					g: 'กรัม',
					cm: 'ซม',
					cod_amount: 'เก็บเงินปลายทาง',
				},
				shipping: {
					header: 'ตรวจสอบขนส่ง',
					courier_id: 'เลือกขนส่ง',
					courier_parcel: 'ชิ้นที่ %{index} - %{remark}',
					courier_parcel_optional: 'สามารถส่งได้',
					courier_parcel_hint: '***ตัวอักษรสีแดงแทนพัสดุที่ขนส่งไม่สามารถส่งได้ กรุณาตรวจสอบพัสดุและเลือกขนส่งใหม่อีกครั้ง',
				},
				booking: {
					header: 'รายการจัดส่งพัสดุ',
					courier: 'ดำเนินการขนส่งโดย : %{courier}',
					date: 'อัพเดตสถานะเมื่อวันที่ : %{time}',
					parcel: 'พัสดุชิ้นที่ #%{index}',
					description: '%{title}, น้ำหนัก %{weight} กรัม',
					cod: 'เก็บเงินปลายทาง',
				},
				printing: {
					title: 'รายละเอียดใบสั่งซื้อ #%{no}',
					header: 'รายละเอียดใบสั่งซื้อ #%{no}',
					action: 'สั่งพิมพ์ใบสั่งซื้อ',
					order_no: 'หมายเลขคำสั่งซื้อ #%{no}',
					email: 'อีเมล',
					phone: 'เบอร์โทรศัพท์',
					created_date: 'วันที่สั่งซื้อ',
					issued_date: 'วันที่ออกเอกสาร',
					notes: 'กรุณาตรวจสอบรายละเอียดของสินค้าและข้อมูลของผู้ซื้อ-ผู้ขายให้เรียบร้อยก่อนการนำส่งเอกสาร',
				},
				save_success: 'บันทึกข้อมูลเรียบร้อยแล้ว',
			},
			waiting: {
				title: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				header: 'รายการขนส่งที่จัดเตรียมพัสดุ',
				result_title: 'รายการขนส่งทั้งหมด',
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				item_col: 'ชื่อขนส่ง',
				item_col2: 'ช่วงเวลาตัดยอด',
				item_col3: 'การเข้ารับ',
				item_col4: 'จำนวนขั้นต่ำ',
				item_col5: 'จำนวนที่ต้องส่ง',
				item_col6: 'Action',
				action_booking: 'พร้อมส่งพัสดุ',
				status_booked: 'จัดส่งเรียบร้อยแล้ว',
				confirm_message: 'กรุณายืนยันรายละเอียดด้านล่าง ก่อนเริ่มการจัดส่งพัสดุทั้งหมด',
				no_data: 'ไม่มีพัสดุสำหรับการจัดส่งนี้',
			},
			tracking: {
				title: 'รายการจัดส่งสินค้า',
				header: 'รายการจัดส่งสินค้า',
				action: 'เพิ่มรายการ',
				search: {
					search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				no_shipping_enabled: 'เปิดการใช้งานเชื่อมต่อบริการระบบขนส่ง เพื่อเข้าถึงการใช้งานที่สะดวกยิ่งขึ้น',
				result_title: 'รายการจัดส่งสินค้าทั้งหมด',
				item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
				item_col2: 'ชื่อขนส่ง',
				item_col3: 'วันที่จัดส่ง',
				item_col4: ' ',
				no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
				modal: {
					title: 'หมายเลขจัดส่ง #%{no}',
					subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
					item_no: 'พัสดุ #%{index} - %{no}',
					item_action: 'ดูรายละเอียด',
					printing_action: 'สั่งพิมพ์ใบปะหน้า',
				},
				printing: {
					title: 'รายละเอียดใบปะหน้า',
					header: 'รายละเอียดใบปะหน้า #%{no}',
					action: 'สั่งพิมพ์ใบปะหน้า',
					search: {
						search_placeholder: 'ค้นหาจากหมายเลขจัดส่ง',
						size: {
							default: 'ขนาดใบปะหน้า',
							a4: 'กระดาษ A4',
							a5: 'กระดาษ A5',
							a6: 'กระดาษ A6',
							receipt: 'ใบเสร็จ',
							letter: 'ซองจดหมาย',
							letter4x6: 'ซองจดหมาย 4x6',
							sticker: 'สติกเกอร์ 8x8 เซนติเมตร',
							sticker4x6: 'สติกเกอร์ 4x6 นิ้ว',
							paperang: 'เครื่องปริ๊น Paperang',
						},
						tracking_code: 'หมายเลข Tracking Code',
						all_tracking_code: 'ทั้งหมด'
					},
					result_title: 'รายการจัดส่งสินค้าทั้งหมด',
					item_col: 'หมายเลขจัดส่ง (จำนวนพัสดุ)',
					item_col2: 'ชื่อขนส่ง',
					item_col3: 'วันที่จัดส่ง',
					item_col4: ' ',
					no_data: 'ไม่พบข้อมูลรายการจัดส่งสินค้า',
					modal: {
						title: 'หมายเลขจัดส่ง #%{no}',
						subheader: 'หมายเลขคำสั่งซื้อและรายการพัสดุตามรายละเอียดด้านล่างนี้',
						item_no: 'พัสดุ #%{index} - %{no}',
						item_action: 'ดูรายละเอียด',
						printing_action: 'สั่งพิมพ์ใบปะหน้า',
					}
				},
			},
		}
	},
	apps: {
		shipping: {
			title: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			header: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					is_cod_enabled: 'เปิดการใช้งานตัวเลือกชำระเงิน Cash on Delivery (COD)',
					is_cod_enabled_hint: 'เปิดเพื่อให้ลูกค้าสามารถเลือกรูปแบบการชำระเงินกับระบบขนส่งได้',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการส่งสินค้าผ่าน Shippop',
					is_enabled_hint: 'เชื่อมต่อกับ Shippop หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณากรอกแบบฟอร์มด้านล่างเพื่อรอเจ้าหน้าที่ติดต่อกลับ, หรือปิดตัวเลือกนี้หากต้องการจัดการส่งสินค้าดัวตัวเอง',
					api_key: 'API Key',
					api_key_placeholder: 'เช่น dv2e3e186c8a17f392451502354b134ae...',
					api_key_hint: 'ข้อมูลนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ Shippop แล้ว',
				},
				embeded: {
					header: 'สมัครเพื่อเปิดใช้งานขนส่งกับ Shippop',
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'รายการขนส่ง',
				header: 'รายการขนส่งทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการขนส่งที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
		payment: {
			title: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			header: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					bank_code: 'ชื่อธนาคาร',
					bank_account_no: 'หมายเลขบัญชีธนาคาร',
					bank_account_no_placeholder: 'XXX-X-XXXXX-X',
					bank_account_name: 'ชื่อบัญชีธนาคาร',
					bank_account_name_placeholder: 'ชื่อเจ้าของบัญชี',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการจ่ายเงินผ่าน GB Prime Pay',
					is_enabled_hint: 'เชื่อมต่อกับ GB Prime Pay หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณาจัดเตรียมเอกสารตามแบบฟอร์มด้านล่าง, หรือปิดตัวเลือกนี้หากต้องการใช้เฉพาะวิธีการจ่ายแบบโอนผ่านบัญชีธนาคาร',
					token_key: 'Token Key',
					token_key_placeholder: 'เช่น XKfU1U4NMU20umP7DsDuYgf5iQMcweOiF...',
					public_key: 'Public Key',
					public_key_placeholder: 'เช่น giYI8590ihq14zdTOEDeVK6JhG6qd1Nq',
					secret_key: 'Secret Key',
					secret_key_placeholder: 'เช่น q3YzkesZgHOe4bTrtdfVE15uHl1hJffo',
					secret_key_hint: 'ข้อมูล 3 ชุดนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ GB Prime Pay แล้ว',
					validator: {
						bank_code: '^กรุณาเลือกธนาคาร',
						bank_account_no: '^กรุณากรอกหมายเลขบัญชีธนาคาร',
						bank_account_name: '^กรุณากรอกชื่อบัญชีธนาคาร',
					}
				},
				embeded: {
					header: 'สมัครเพื่อเปิดใช้งานการจ่ายเงินกับ GB Prime Pay',
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'วิธีการจ่ายเงิน',
				header: 'รายการวิธีจ่ายเงินทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการวิธีจ่ายเงินที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
		page: {
			title: 'เซลเพจ',
			header: 'รายการเซลเพจ',
			import_action: 'ดาวน์โหลดเซลเพจตั้งต้น',
			limit: 'จำนวนเซลเพจที่สร้างแล้ว %{total}/%{limit} หน้า',
			no_data: 'ไม่พบข้อมูลรายการของเซลเพจ',
			card: {
				default: 'หน้าหลัก',
				btn_example: 'ดูตัวอย่างแชร์',
				btn_share_now: 'แชร์เลย'
			},
			example: {
				header: 'ตัวอย่างการแสดงผล Content',
			},
			send_success: 'แชร์เซลเพจผ่าน Line เรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
		},
	},
	account: {
		accounts: {
			title: 'รายการลูกค้า',
			header: 'รายการลูกค้า',
			search_placeholder: 'ชื่อลูกค้า, เบอร์โทรศัพท์, อีเมล',
			result_title: 'รายการลูกค้าทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'เบอร์โทรศัพท์',
			item_col3: 'จังหวัด',
			item_col4: 'วันที่ซื้อสินค้าครั้งแรก',
			item_col5: 'Actions',
			no_data: 'ไม่พบข้อมูลรายการลูกค้า',
			filter: {
				section: 'รายละเอียดการค้นหา',
				province_code: 'จังหวัด',
				province_code_all: 'ทุกจังหวัด',
			},
		},
		details: {
			title: 'รายละเอียดลูกค้า',
			header: 'รายละเอียดลูกค้า',
			tab: {
				default: 'ข้อมูลทั่วไป',
				converted_date: 'วันที่กลายเป็นลูกค้า',
				profile: {
					header: 'ข้อมูลส่วนตัว',
					firstname: 'ชื่อจริง',
					lastname: 'นามสกุล',
					email: 'อีเมล',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: 'เช่น 081 234 5678',
					address: 'ที่อยู่',
					address_placeholder: 'เช่น บ้านเลขที่ 1/127 ถนนเพชรบุรี',
					postal_code: 'รหัสไปรษณีย์',
					province_name: 'จังหวัด',
					city_name: 'อำเภอ/เขต',
					district_name: 'ตำบล/แขวง',
					validator: {
						email: '^กรุณากรอกอีเมล์',
						email_invalid: '^อีเมล์ไม่ถูกต้อง',
						firstname: '^กรุณากรอกชื่อจริง',
						lastname: '^กรุณากรอกนามสกุล',
						phone: '^กรุณากรอกเบอร์โทรศัพท์',
						address: '^กรุณากรอกที่อยู่',
						postal_code: '^กรุณากรอกรหัสไปรษณีย์',
						sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
						sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
						sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
					},
				}
			},
			tab2: {
				default: 'ประวัติสั่งซื้อ',
				search: {
					search_placeholder: 'ค้นหาหมายเลขคำสั่งซื้อ',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการคำสั่งซื้อทั้งหมด',
				item_col: 'หมายเลขคำสั่งซื้อ',
				item_col_cod: 'COD',
				item_col2: 'ยอดรวมทั้งหมด',
				item_col3: 'สถานะ',
				item_col4: 'วันที่สั่งซื้อ',
				item_col5: 'Action',
				no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			},
		}
	},
};
