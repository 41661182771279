import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	colors,
	Typography
} from '@material-ui/core';

import FaceRoundedIcon from '@material-ui/icons/FaceRounded';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodataTHLow from '@amcharts/amcharts4-geodata/thailandHigh';
import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

import NumberFormat from 'react-number-format';

import provinces from 'src/assets/data/provinces';

import alpha from 'src/utils/alpha';
import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0, 0),
		height: '100%'
	},
	content: {
		position: 'relative',
		height: '100%'
	},
	chart: {
		height: '100%',
		[theme.breakpoints.down('sm', 'xs')]: {
			height: 500
		}
	},
	floatingCard: {
		display: 'flex',
		position: 'absolute',
		top: 0,
		right: 0,
		minWidth: 180,
	},
	floatingCardContent: {
		display: 'flex',
		padding: `${theme.spacing(2)}px !important`
	},
	avatar: {
		borderRadius: 8
	}
}));

function Map({ className, ...rest }) {
	const classes = useStyles();
	const theme = useTheme();

	const [object, setObject] = useState(null);
	const [objects, setObjects] = useState(null);

	const [mPolygonSeries, setMPolygonSeries] = useState(null);

	//	Functions

	useEffect(() => {
		am4core.useTheme(am4themesAnimated);

		//	Create map instance
		const chart = am4core.create('mapChart', am4maps.MapChart);

		//	Re-values
		const newFeatures = am4geodataTHLow.features.map((item) => {
			const newItem = { ...item };
			const province = provinces.find((e) => e.id === item.id);
			if (province) {
				newItem.properties.name = province.name;
				newItem.properties.value = 0;
			}
			return newItem;
		});
		chart.geodata = {
			...am4geodataTHLow,
			features: newFeatures
		};
		chart.projection = new am4maps.projections.Miller();
		chart.homeZoomLevel = 1;
		chart.chartContainer.wheelable = false;

		const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
		polygonSeries.heatRules.push({
			property: 'fill',
			target: polygonSeries.mapPolygons.template,
			min: chart.colors.getIndex(1).brighten(1),
			max: chart.colors.getIndex(1).brighten(-0.3)
		});
		polygonSeries.useGeodata = true;

		//	Set up heat legend
		const heatLegend = chart.createChild(am4maps.HeatLegend);
		heatLegend.series = polygonSeries;
		heatLegend.align = 'right';
		heatLegend.valign = 'bottom';
		heatLegend.width = am4core.percent(20);
		heatLegend.marginRight = am4core.percent(4);
		heatLegend.minValue = 0;
		heatLegend.maxValue = 40000000;

		//	Set up custom heat map legend labels using axis ranges
		const minRange = heatLegend.valueAxis.axisRanges.create();
		minRange.value = heatLegend.minValue;
		minRange.label.text = 'Min';
		const maxRange = heatLegend.valueAxis.axisRanges.create();
		maxRange.value = heatLegend.maxValue;
		maxRange.label.text = 'Max';

		//	Blank out internal heat legend value axis labels
		heatLegend.valueAxis.renderer.labels.template.adapter.add('text', () => '');

		//	Configure series tooltip
		const polygonTemplate = polygonSeries.mapPolygons.template;
		polygonTemplate.tooltipText = '{name}: {value}';
		polygonTemplate.fill = am4core.color(alpha(theme.palette.primary.light, 0.2));
		polygonTemplate.stroke = am4core.color('#FFFFFF');
		polygonTemplate.nonScalingStroke = true;
		polygonTemplate.propertyFields.fill = 'fill';

		//	Create hover state and set alternative fill color
		const hs = polygonTemplate.states.create('hover');
		hs.properties.fill = am4core.color('#3c5bdc');

		setMPolygonSeries(polygonSeries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.OVERVIEW_LOCATIONS)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							//	Find top province
							const items = data.data;
							if (items.length > 0) {
								const province = provinces.find((e) => e.id.includes(items[0].code));
								setObject({ id: `TH-${items[0].code}`, name: province.name, value: items[0].total_price });

								//	Set locations
								setObjects(items);
								mPolygonSeries.data = items.map((item) => ({
									id: `TH-${item.code}`,
									value: item.total_price
								}));
							}
						}
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mPolygonSeries]);

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>

			<Box className={classes.content}>
				<Box id="mapChart" className={classes.chart} />
				{(objects && objects.length > 0) && (
					<Card
						className={classes.floatingCard}
					>
						<CardContent className={classes.floatingCardContent}>
							<Avatar
								className={classes.avatar}
								variant="rounded"
								style={{ backgroundColor: colors.green[400] }}
							>
								<FaceRoundedIcon />
							</Avatar>
							<Box marginLeft={2}>
								<Typography variant="h5">
									{object.name}
								</Typography>
								<Typography variant="h4">
									<strong>
										<NumberFormat value={object.value} displayType="text" prefix="฿" thousandSeparator />
									</strong>
								</Typography>
							</Box>
						</CardContent>
					</Card>
				)}
			</Box>
		</Box>
	);
}

Map.propTypes = {
	className: PropTypes.string,
};

export default Map;
