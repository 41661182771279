import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	colors,
	Typography
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import Lottie from 'react-lottie';
import animationData from 'src/assets/lotties/online-learning';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
	},
	content: {
	},
	avatarGroup: {
		marginTop: theme.spacing(1),
		'& > *': {
			width: 36,
			height: 36,
			fontSize: 12,
			fontWeight: 'bold'
		},
		'& > div:nth-child(1)': {
			backgroundColor: colors.pink[400]
		},
		'& > div:nth-child(2)': {
			backgroundColor: colors.green[400]
		},
		'& > div:nth-child(3)': {
			backgroundColor: colors.purple.A700
		}
	},
	list: {

	}
}));

function Knowledges({ className, ...rest }) {
	const classes = useStyles();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardContent className={classes.content}>
				<Lottie
					options={defaultOptions}
					width="100%"
					height={140}
				/>
				<Box marginTop={1}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography
							variant="h5"
						>
							<strong>{locale.t('sales.overview.knowledges.header')}</strong>
						</Typography>
						<AvatarGroup
							className={classes.avatarGroup}
							max={3}
						>
							<Avatar>S</Avatar>
							<Avatar>E</Avatar>
							<Avatar>T</Avatar>
							<Avatar>S</Avatar>
						</AvatarGroup>
					</Box>
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{locale.t('sales.overview.knowledges.description')}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
}

export default Knowledges;
