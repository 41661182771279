import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Avatar,
	Box,
	Divider,
	List,
	ListItem,
	ListItemIcon,
} from '@material-ui/core';

import AvatarMenuPopover from 'src/components/AvatarMenuPopover';
import ConfirmDialog from 'src/components/Dialogs/ConfirmDialog';

import AppLauncher from './Components/AppLauncher';

import { logout } from 'src/actions';

import getInitials from 'src/utils/getInitials';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		// position: 'fixed',
		// zIndex: 999,
		// left: 0,
		// height: '100%',
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing(1, 1.5, 0),
	},
	item: {
		position: 'relative',
		maxWidth: 36,
		padding: theme.spacing(0.75),
		margin: theme.spacing(1, 0.5, 0.5),
		borderRadius: theme.spacing(0.75),
		transition: '0.3s',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.25)'
		},
		'&.Mui-selected': {
			backgroundColor: 'rgba(255, 255, 255, 0.3)'
		}
	},
	itemIcon: {
		minWidth: 0,
		color: theme.palette.primary.contrastText
	},
	itemArrow: {
		position: 'absolute',
		right: -16,
		width: 0,
		height: 0,
		borderTop: '6px solid transparent',
		borderBottom: '6px solid transparent',
		borderRight: '6px solid #FFFFFF'
	},
	notificationsBadge: {
		top: -2,
		right: -2,
		color: theme.palette.primary.main,
		backgroundColor: 'white'
	},
	avatar: {
		width: 32,
		height: 32,
		left: -4
	},
}));

function MiniNav({ className, ...rest }) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const session = useSelector((state) => state.session);

	const avatarMenuRef = useRef(null);
	const [openAvatarMenu, setOpenAvatarMenu] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	//	Functions

	const handleAvatarMenuOpen = () => {
		setOpenAvatarMenu(true);
	};

	const handleAvatarMenuClose = () => {
		setOpenAvatarMenu(false);
	};

	const handleAvatarItemClick = (code) => {
		if (code === 'wallet/member') {
			const { origin } = window.location;
			const shareUrl = `${origin}/wallet/member/${session.user.no}`;
			window.open(shareUrl, '_blank');
		} else if (code === 'profile') {
			// history.push('/settings/profile');
		} else if (code === 'logout') {
			setOpenDialog(true);
		}
		setOpenAvatarMenu(false);
	};

	const handleDialogClose = (isConfirm) => {
		if (isConfirm) {
			// eslint-disable-next-line no-use-before-define
			handleLogout();
		}
		setOpenDialog(false);
	};

	const handleLogout = () => {
		dispatch(logout());

		//	Clear session timer first
		const { localStorage } = window;
		localStorage.setItem('session-timer', null);
		setTimeout(() => {
			window.location = '/auth/login';
		}, 300);
	};

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Box>
				<img
					alt=""
					src="/images/logos/mini-logo.png"
					style={{ height: 40 }}
				/>
			</Box>
			<Divider className={classes.divider} />
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				flexGrow={1}
			>
				<List>
					<AppLauncher
						id="apps"
					/>
				</List>
				<Box>
					<Divider className={classes.divider} />
					<List>
						<ListItem
							button
							ref={avatarMenuRef}
							className={classes.item}
							onClick={handleAvatarMenuOpen}
						>
							<ListItemIcon className={classes.itemIcon}>
								<Avatar
									alt={session.user.firstname}
									className={classes.avatar}
									src={session.user.avatar}
								>
									{getInitials(session.user.firstname)}
								</Avatar>
							</ListItemIcon>
						</ListItem>
					</List>
					<AvatarMenuPopover
						anchorEl={avatarMenuRef.current}
						onClose={handleAvatarMenuClose}
						onItemClick={handleAvatarItemClick}
						open={openAvatarMenu}
					/>
					<ConfirmDialog
						title={locale.t('topbar.signout.title')}
						message={locale.t('topbar.signout.message')}
						onClose={handleDialogClose}
						open={openDialog}
						primaryAction={locale.t('btn.signout')}
					/>
				</Box>
			</Box>
		</Box>
	);
}

MiniNav.propTypes = {
	className: PropTypes.string,
};

export default MiniNav;
