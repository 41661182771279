export default {
	app_name: 'TumTum',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		ok: 'ตกลง',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม',
		submit: 'Submit',
		setting: 'ตั้งค่า',
		see_all: 'ดูทั้งหมด',
		view_report: 'ดูรายงาน',
		back: 'ย้อนกลับ',
		done: 'เสร็จสิ้น',
		send: 'ส่ง',
		detail: 'รายละเอียด',
		export_excel: 'ดาวน์โหลด Excel'
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'ยกเลิกการเปลี่ยนแปลงที่เกิดขึ้น?',
		delete_data_message: 'คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
		remove_component_message: 'ต้องการลบข้อมูลนี้ออกใช่หรือไม่?'
	},
	alert: {
		title: {
			recommend: 'คำแนะนำ',
			warning: 'คำแนะนำ'
		}
	},
	badge: {
		status: {
			active: 'เปิดสาธารณะ',
			inactive: 'ซ่อนการมองเห็น',
		},
		member_order: {
			rejected: 'ไม่อนุมัติ',
			waiting: 'รอการตรวจสอบ',
			waiting_admin: 'ส่งต่อผู้ดูแลตรวจสอบ',
			approved: 'อนุมัติแล้ว',
		},
		app: {
			enable: 'เปิดการใช้งาน',
			disable: 'ปิดการใช้งาน',
		},
		kpi: {
			system: 'System'
		}
	},
	preloader: {
		loading: 'ระบบกำลังเตรียมความพร้อม, กรุณารอซักครู่...',
		loading_2: 'เริ่มการแสดงผลข้อมูลที่จำเป็นสำหรับคุณ',
		too_long: 'หากใช้เวลานานเกินไป, กรุณาติดต่อผู้ดูแลระบบ'
	},
	unit: {
		minute: 'นาที',
		time: 'ครั้ง',
		people: 'คน',
		point: 'คะแนน',
		day: 'วัน',
	},
	order: {
		status: {
			cancelled: 'ยกเลิกคำสั่งซื้อ',
			new: 'ได้รับคำสั่งซื้อ',
			paid: 'จ่ายเงินแล้ว',
			confirm_paid: 'ยืนยันการจ่ายแล้ว',
			ready_to_ship: 'พร้อมจัดส่ง',
			shipped: 'จัดส่งแล้ว',
			delivered: 'ส่งถึงปลายทางแล้ว',
		},
	},
	result_table: {
		filter_action: 'แสดงตัวกรอง',
		hint: ' รายการที่พบ หน้า ',
		hint2: ' จาก ',
		hint_no_page: 'รวมทั้งหมด %{count} รายการ',
		rows_per_page: 'แถวต่อหน้า:',
		page_hint: 'จาก',
		page_hint2: 'มากกว่า ',
		loading: 'กำลังเชื่อมต่อข้อมูล...'
	},
	result_grid: {
		sorting: 'จัดเรียง:',
	},
	empty_data: {
		desc: 'รายละเอียดการค้นหาของคุณไม่ตรงกับข้อมูลที่มีอยู่ กรุณาลองค้นหาด้วย',
		desc_2: 'ข้อมูลอื่นอีกครั้งหรือหากต้องการรีเซ็ตการค้นหาข้อมูลใหม่, เพียงคลิก',
		btn_reset: 'ตั้งค่าค้นหาข้อมูลใหม่',
		remark: 'เพื่อดำเนินการต่อ',
	},
	topbar: {
		menu: {
			wallet_member: 'บัตรตัวแทน',
			profile: 'ข้อมูลส่วนตัว',
			logout: 'ออกจากระบบ',
		},
		signout: {
			title: 'แน่ใจหรือไม่?',
			message: 'คุณกำลังดำเนินการออกจากระบบ กรุณาตรวจสอบอีกครั้งเพื่อยืนยัน'
		}
	},
	notification: {
		title: 'Notifications',
		no_data: {
			title: 'ไม่พบรายการแจ้งเตือน',
			desc: 'คุณจะได้รับการอัพเดตข้อมูลจากเราอย่างใกล้ชิด',
			desc_2: 'ผ่านตัวช่วยแจ้งเตือนเรื่องต่างๆ ภายใน Platform นี้ อย่างรวดเร็ว',
		},
	},
	help_center: {
		title: 'Learn & Get new skills',
		nav: {
			home: 'Home'
		}
	},
	team: {
		title: 'สร้างทีมของคุณบน ',
		subheader: 'เพิ่มรายละเอียดตามด้านล่าง',
		form: {
			email: 'เพิ่มอีเมลสมาชิกของทีมคุณ',
			firstname: 'ชื่อจริง',
			lastname: 'นามสกุล',
			password: 'รหัสผ่าน',
			save_fail: 'อีเมลนี้มีสมาชิกคนอื่นใช้แล้ว กรุณาลองใหม่อีกครั้ง',
			save_success: 'บันทึกเรียบร้อยแล้ว',
			validator: {
				email: '^กรุณากรอกอีเมล',
				email_invalid: '^อีเมลไม่ถูกต้อง',
				firstname: '^กรุณากรอกชื่อจริง',
				lastname: '^กรุณากรอกนามสกุล',
				password: '^กรุณากรอกรหัสผ่าน',
				password_length: '^ความยาวรหัสผ่านอย่างน้อย 6 ตัวอักษร'
			},
		},
		members: {
			header: 'บุคคลที่มีสิทธิ์เข้าใช้งาน',
			owner: 'เจ้าของ',
			you: ' (คุณ)',
			pending: 'รอการยืนยัน',
			delete_success: 'ลบข้อมูลสมาชิกเรียบร้อยแล้ว',
		}
	},
	app_store: {
		title: ' - App Store',
		subheader: 'ค้นหาแอพพลิเคชัน และบริการพิเศษอื่นๆ บนแพลตฟอร์มของเรา',
		btn_download: 'ดาวน์โหลด',
		btn_installed: 'ติดตั้งแล้ว'
	},
	plans: {
		title: 'อัพเกรดการใช้งาน ',
		subheader: 'เพื่อให้คุณเข้าถึงการขยายขีดความสามารถของ Platform ที่มากขึ้น',
	},
};
