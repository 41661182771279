import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import {
	Card,
	CardContent,
	CardHeader,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ActivityCalendar from 'react-activity-calendar';
import ReactTooltip from 'react-tooltip';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles(() => ({
	root: {
		outline: 'none',
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

function HeatMap({ className, ...rest }) {
	const classes = useStyles();

	const [objects, setObjects] = useState(null);

	const min = 0;
	const max = 100;
	const slot = 4;
	const defaultValues = [
		{
			count: 0,
			date: '2022-01-01',
			level: 0
		},
		{
			count: 0,
			date: '2022-12-31',
			level: 0
		},
	];

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_ACTIVITIES)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							const items = data.data.map((item) => {
								const newItem = { ...item };
								if (item.count === 0) {
									newItem.level = 1;
								} else if (item.count >= max) {
									newItem.level = slot;
								} else {
									newItem.level = ((item.count / (100 / slot)) + 1).toFixed(0);
								}
								return newItem;
							});

							//	Find first & last date of this year
							let index = items.findIndex((item) => item.date === '2022-01-01');
							if (index === -1) {
								items.splice(0, 0, { count: 0, date: '2022-01-01', level: 0 });
							}
							index = items.findIndex((item) => item.date === '2022-12-31');
							if (index === -1) {
								items.push({ count: 0, date: '2022-12-31', level: 0 });
							}
							setObjects(items);
						}
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						variant="h5"
					>
						<strong>{locale.t('sales.overview.activities.header')}</strong>
					</Typography>
				)}
				subheader={(
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{locale.t('sales.overview.activities.sub_header')}
						{' '}
						1 Jan - 31 Dec
						{' '}
						{moment().year()}
					</Typography>
				)}
			/>
			<CardContent className={classes.content}>
				<ActivityCalendar
					data={objects || defaultValues}
					labels={{
						legend: {
							less: `${min}`,
							more: `${max}+`
						},
						months: [
							'Jan',
							'Feb',
							'Mar',
							'Apr',
							'May',
							'Jun',
							'Jul',
							'Aug',
							'Sep',
							'Oct',
							'Nov',
							'Dec'
						],
						tooltip: locale.t('sales.overview.activities.tooltip'),
						totalCount: locale.t('sales.overview.activities.total_count'),
						weekdays: [
							'Sun',
							'Mon',
							'Tue',
							'Wed',
							'Thu',
							'Fri',
							'Sat'
						]
					}}
				>
					<ReactTooltip html />
				</ActivityCalendar>
			</CardContent>
		</Card>
	);
}

export default HeatMap;
