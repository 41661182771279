import common from './common';
import auth from './auth';
import sales from './sales';
import crm from './crm';
import accounting from './accounting';
import stock from './stock';
import apps from './apps';
import component from './component';

export default {
	...common,
	auth: { ...auth },
	sales: { ...sales },
	crm: { ...crm },
	accounting: { ...accounting },
	stock: { ...stock },
	apps: { ...apps },
	component: { ...component }
};
