export default {
	nav: {
		data: {
			default: 'My Workspace',
			overview: 'Dashboard'
		},
		accounting: {
			default: 'รายการบัญชี',
			expense_categories: 'หมวดหมู่ค่าใช้จ่าย',
			periods: 'รอบบัญชี'
		}
	},
	overview: {
		welcome: {
			getting_started: {
				header: 'Welcome to',
				header_2: 'Workspace',
				sub_header: 'We\'re here to show you around and help you get started',
			},
			btn_later: 'I\'ll do it later',
			btn_start: 'Start Tour',
			btn_finish: 'Ready to work',
		}
	},
	targets: {
		title: 'รายการเป้าหมาย',
		header: 'รายการเป้าหมาย',
		action: 'เพิ่มรายการ',
		search_placeholder: 'ค้นหาชื่อรายการ',
		result_title: 'รายการเป้าหมายทั้งหมด',
		item_col: 'สร้างโดย',
		item_col2: 'แก้ไขล่าสุด',
		item_col3: 'สถานะแสดงผล',
		no_data: 'ไม่พบข้อมูลรายการเป้าหมาย',
		delete_success: 'ลบข้อมูลรายการเป้าหมายเรียบร้อยแล้ว',
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		create: {
			header: 'สร้างรายการเป้าหมาย',
			save_fail: 'ไม่สามารถสร้างข้อมูลรายการได้ กรุณาติดต่อผู้ดูแลระบบ'
		},
		detail: {
			title: 'แก้ไขเป้าหมาย',
			header: 'จัดการข้อมูลเป้าหมาย',
			form_header: 'แก้ไขเป้าหมาย',
			form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับรอบบัญชี รายรับ และรายจ่าย ซึ่งจะแสดงผลต่อสมาชิกคนอื่นๆ',
			title_: 'ชื่อรายการ',
			title_hint: 'จำกัดตัวอักษร %{count}/100',
			profit_per_order: 'กำไรเฉลี่ย/คำสั่งซื้อ',
			sales_volume: 'ยอดขายทั้งหมด',
			net_profit: 'กำไรทั้งหมด',
			num_orders: 'จำนวนคำสั่งซื้อ',
			income_target: 'เป้าหมายเก็บเงิน',
			start_date: 'เริ่มตั้งแต่',
			end_date: 'จนถึง',
			is_enabled: 'เปิดเผยต่อสาธารณะ',
			is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงรายงานต่อสมาชิกที่ใช้ระบบ',
			save_success: 'บันทึกรายการข้อมูลเป้าหมายใหม่เรียบร้อยแล้ว',
			validator: {
				title: '^กรุณากรอกชื่อรายการ',
				start_date: '^กรุณากรอกวันเริ่มต้น',
				end_date: '^กรุณากรอกวันสิ้นสุด',
			},
			other_actions: {
				header: 'Other Actions',
				delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลรายงานเป้าหมายที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
				btn_delete: 'ลบข้อมูลเป้าหมาย',
			}
		}
	},
	expense: {
		categories: {
			title: 'รายการหมวดหมู่ค่าใช้จ่าย',
			name: 'รายการหมวดหมู่ค่าใช้จ่าย',
			header: 'รายการหมวดหมู่ค่าใช้จ่าย',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อหมวดหมู่',
			result_title: 'รายการหมวดหมู่ค่าใช้จ่ายทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'แก้ไขล่าสุด',
			item_col3: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่ค่าใช้จ่าย',
			delete_success: 'ลบข้อมูลหมวดหมู่ค่าใช้จ่ายเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างรายการหมวดหมู่ค่าใช้จ่าย',
				save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ค่าใช้จ่ายได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่ค่าใช้จ่าย',
				header: 'จัดการข้อมูลหมวดหมู่ค่าใช้จ่าย',
				form_header: 'แก้ไขหมวดหมู่ค่าใช้จ่าย',
				form_sub_header: 'รายละเอียดหมวดหมู่ค่าใช้จ่ายสำหรับการกรอกตัดรอบบัญชี',
				name: 'ชื่อหมวดหมู่',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าหมวดหมู่สำหรับรายจ่ายทั้งหมดของรอบบัญชี',
				save_success: 'บันทึกรายการข้อมูลหมวดหมู่ค่าใช้จ่ายเรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อหมวดหมู่',
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลค่าใช้จ่ายที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลหมวดหมู่ค่าใช้จ่าย'
				}
			}
		},
	},
	periods: {
		title: 'รายการรอบบัญชี',
		header: 'รายการรอบบัญชี',
		action: 'เพิ่มรายการ',
		search_placeholder: 'ค้นหาชื่อรายการ',
		result_title: 'รายการรอบบัญชีทั้งหมด',
		item_col: 'สร้างโดย',
		item_col2: 'แก้ไขล่าสุด',
		item_col3: 'สถานะแสดงผล',
		no_data: 'ไม่พบข้อมูลรายการรอบบัญชี',
		delete_success: 'ลบข้อมูลรายการรอบบัญชีเรียบร้อยแล้ว',
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		create: {
			header: 'สร้างรายการรอบบัญชี',
			save_fail: 'ไม่สามารถสร้างข้อมูลรายการได้ กรุณาติดต่อผู้ดูแลระบบ'
		},
		detail: {
			title: 'รายละเอียดรอบบัญชี',
			header: 'รายละเอียดรอบบัญชี',
			tab: {
				default: 'ข้อมูลทั่วไป',
				title: 'แก้ไขรอบบัญชี',
				header: 'จัดการข้อมูลรอบบัญชี',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				title_: 'ชื่อรายการ',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				start_date: 'เริ่มรอบตั้งแต่',
				end_date: 'จนถึง',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อรายการ',
					start_date: '^กรุณากรอกวันเริ่มต้น',
					end_date: '^กรุณากรอกวันสิ้นสุด',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลค่าใช้จ่ายที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลรอบบัญชี',
				}
			},
			tab2: {
				default: 'รายการค่าใช้จ่าย',
				search: {
					search_placeholder: 'ค้นหารายการค่าใช้จ่าย',
					start_date: 'ตั้งแต่วันที่',
					end_date: 'จนถึง',
				},
				result_title: 'รายการค่าใช้จ่ายทั้งหมด',
				action: 'เพิ่มรายการ',
				item_col: 'วันที่',
				item_col2: 'หมวดหมู่',
				item_col3: 'รายละเอียด',
				item_col4: 'รูปแบบการชำระ',
				item_col4_credit: 'บัตร Credit/Debit',
				item_col4_transfer: 'โอนเงิน',
				item_col4_cash: 'เงินสด',
				item_col4_others: 'อื่นๆ',
				item_col5: 'จำนวนเงิน',
				item_col6: 'Action',
				total_price: 'รวมทั้งสิ้น',
				no_data: 'ไม่พบข้อมูลรายการค่าใช้จ่ายจากรอบบัญชีนี้',
				payment_method: {
					credit: 'บัตร Credit/Debit',
					transfer: 'โอนเงิน',
					cash: 'เงินสด',
					others: 'อื่นๆ',
				},
				create: {
					header: 'สร้างรายการค่าใช้จ่าย',
					header_2: 'แก้ไขรายการค่าใช้จ่าย',
					target_date: 'วันที่',
					category_id: 'หมวดหมู่',
					category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					title_: 'รายละเอียด',
					title_hint: 'จำกัดตัวอักษร %{count}/100',
					payment_method: 'รูปแบบการชำระ',
					payment_method_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
					total_price: 'จำนวนเงิน',
					remark: 'หมายเหตุ',
					save_fail: 'ไม่สามารถสร้างข้อมูลค่าใช้จ่ายได้ กรุณาติดต่อผู้ดูแลระบบ',
					validator: {
						target_date: '^กรุณากรอกชื่อรายการ',
						title: '^กรุณากรอกรายละเอียด',
						total_price: '^กรุณากรอกจำนวนเงิน'
					},
				},
			},
		}
	},
};
