import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
} from '@material-ui/core';

import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';

const useStyles = makeStyles(() => ({
	root: {
		overflow: 'hidden'
	},
	player: {
		position: 'relative',
		width: '100%',
		paddingBottom: '56.25%',
		'& iframe': {
			position: 'absolute',
			width: '100%',
			height: '100%',
		},
	},
}));

const VideoContent = ({ className, ...rest }) => {
	const classes = useStyles();

	const object = {
		media_url : 'https://www.youtube.com/embed/RepKqyhMoxU'
	};
	const isVimeo = object.media_url.includes('vimeo');

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>
			{isVimeo && (
				<Vimeo
					className={classes.player}
					video={object.media_url}
					style={{ width: '100%' }}
				/>
			)}
			{!isVimeo && (
				<ReactPlayer
					className={classes.player}
					controls
					width="100%"
					url={object.media_url}
				/>
			)}
		</Box>
	);
};

VideoContent.propTypes = {
	className: PropTypes.string,
};

export default VideoContent;
