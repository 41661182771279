import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	Card,
	CardContent,
	CardHeader,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Chart from './Chart';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
	},
	content: {
		// display: 'flex',
		// alignItems: 'center',
		// flexDirection: 'column',
		// paddingTop: 0
		position: 'relative',
		padding: '0 !important'
	},
	chart: {
		height: 80
	}
}));

function GraphChart({ className, ...rest }) {
	const classes = useStyles();

	const [objects, setObjects] = useState(null);

	const data = {
		thisWeek: {
			data: [],
			labels: []
		},
		thisMonth: {
			data: [],
			labels: []
		},
		thisYear: {
			// data: [10, 5, 11, 20, 13, 28, 18, 4, 13, 12, 13, 5],
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			labels: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			]
		}
	};

	useEffect(() => {
		let mounted = true;

		const fetchObjects = () => {
			axios.get(WebAPI.OVERVIEW_ACTIVITIES)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObjects(data.data);
						}
					}
				});
		};
		fetchObjects();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{/* <strong>{locale.t('sales.product.categories.result_title')}</strong> */}
						ยอดขายเฉลี่ยต่อไตรมาส
					</Typography>
				)}
				subheader={(
					<Typography
						variant="h4"
					>
						<strong>0</strong>
					</Typography>
				)}
			/>
			<CardContent className={classes.content}>
				<Chart
					className={classes.chart}
					data={data.thisYear.data}
					labels={data.thisYear.labels}
				/>
			</CardContent>
		</Card>
	);
}

export default GraphChart;
