export default {
	nav: {
		data: {
			default: 'My Workspace',
			overview: 'Dashboard'
		},
		account: {
			default: 'ข้อมูลลูกค้า',
			accounts: 'รายชื่อลูกค้า',
			products: 'รายการสินค้าครบกำหนด',
		},
		broadcast: {
			default: 'ติดต่อลูกค้า',
			sms: 'ข้อความ SMS',
		},
	},
	overview: {
		welcome: {
			getting_started: {
				header: 'Welcome to',
				header_2: 'Workspace',
				sub_header: 'We\'re here to show you around and help you get started',
			},
			btn_later: 'I\'ll do it later',
			btn_start: 'Start Tour',
			btn_finish: 'Ready to work',
		}
	},
	account: {
		accounts: {
			title: 'รายการลูกค้า',
			header: 'รายการลูกค้า',
			search_placeholder: 'ชื่อลูกค้า, เบอร์โทรศัพท์, อีเมล',
			result_title: 'รายการลูกค้าทั้งหมด',
			item_col: 'ชื่อ-อีเมล',
			item_col2: 'คะแนน',
			item_col3: 'จำนวนคำสั่งซื้อ',
			item_col4: 'วันที่สั่งซื้อครั้งแรก',
			no_data: 'ไม่พบข้อมูลรายการลูกค้า',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			filter: {
				section: 'รายละเอียดการค้นหา',
				province_code: 'จังหวัด',
				province_code_all: 'ทุกจังหวัด',
			},
		},
		detail: {
			title: 'รายละเอียดลูกค้า',
			header: 'รายละเอียดลูกค้า',
			tab: {
				default: 'ข้อมูลทั่วไป',
				converted_date: 'วันที่กลายเป็นลูกค้า',
				profile: {
					header: 'ข้อมูลส่วนตัว',
					firstname: 'ชื่อจริง',
					lastname: 'นามสกุล',
					email: 'อีเมล',
					phone: 'เบอร์โทรศัพท์',
					phone_placeholder: 'เช่น 081 234 5678',
					address: 'ที่อยู่',
					address_placeholder: 'เช่น บ้านเลขที่ 1/127 ถนนเพชรบุรี',
					postal_code: 'รหัสไปรษณีย์',
					province_name: 'จังหวัด',
					city_name: 'อำเภอ/เขต',
					district_name: 'ตำบล/แขวง',
					validator: {
						email: '^กรุณากรอกอีเมล์',
						email_invalid: '^อีเมล์ไม่ถูกต้อง',
						firstname: '^กรุณากรอกชื่อจริง',
						lastname: '^กรุณากรอกนามสกุล',
						phone: '^กรุณากรอกเบอร์โทรศัพท์',
						address: '^กรุณากรอกที่อยู่',
						postal_code: '^กรุณากรอกรหัสไปรษณีย์',
						sub_domain: '^กรุณากรอกชื่อเว็บไซต์ส่วนตัว',
						sub_domain_length: '^ความยาวชื่อเว็บไซต์ส่วนตัวอย่างน้อย 6 ตัวอักษร',
						sub_domain_invalid: '^รองรับเฉพาะตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวเลขเท่านั้น',
					},
				}
			},
			tab2: {
				default: 'ประวัติสั่งซื้อ',
				search: {
					search_placeholder: 'ค้นหาหมายเลขคำสั่งซื้อ',
					status: 'สถานะ',
					all_status: 'ทั้งหมด',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการคำสั่งซื้อทั้งหมด',
				item_col: 'หมายเลขคำสั่งซื้อ',
				item_col_cod: 'COD',
				item_col2: 'ยอดรวมทั้งหมด',
				item_col3: 'สถานะ',
				item_col4: 'วันที่สั่งซื้อ',
				item_col5: 'Action',
				no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
			},
			tab3: {
				default: 'Redeem',
				search: {
					search_placeholder: 'ค้นหาหมายคูปอง',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการแลกคูปองทั้งหมด',
				item_col: 'หมายเลขคำสั่งซื้อ',
				item_col2: 'วันที่แลก',
				item_col3: 'วันที่หมดอายุ',
				no_data: 'ไม่พบข้อมูลรายการแลกคูปองจากลูกค้า',
			},
		},
		products: {
			title: 'รายการสินค้าครบกำหนด',
			header: 'รายการสินค้าครบกำหนด',
			search_placeholder: 'ชื่อลูกค้า, เบอร์โทรศัพท์, อีเมล',
			result_title: 'รายการสินค้าครบกำหนดทั้งหมด',
			item_col: 'อายุสินค้า %{day} วัน',
			item_col2: 'ชื่อลูกค้า',
			item_col3: 'สั่งซื้อวันที่',
			item_col4: 'คาดการณ์วันซื้อซ้ำ',
			item_col5_line: 'ติดต่อผ่าน Line',
			item_col5_sms: 'ส่งข้อความ SMS',
			no_data: 'ไม่พบข้อมูลรายการสินค้าครบกำหนด',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			sms_modal: {
				header: 'ส่งข้อความ SMS',
				message: 'ข้อความ',
				message_hint: 'จำกัดตัวอักษร %{count}/70',
				save_success: 'ส่งข้อความ SMS เรียบร้อยแล้ว',
				save_fail: 'ไม่สามารถส่งข้อความ SMS ได้ กรุณาติดต่อผู้ดูแลระบบ',
				validator: {
					message: '^กรุณากรอกข้อความ',
				},
			},
		}
	},
	broadcast: {
		sms: {
			title: 'ข้อความ SMS',
			header: 'SMS Broadcast',
			form_header: 'ส่งข้อความหาลูกค้าของคุณ',
			form_sub_header: 'ข่าวสาร โปรโมชัน หรือสิทธิพิเศษให้กับลูกค้าของคุณ เพื่อช่วยกระตุ้นยอดขายให้มากขึ้น',
			account_ids: 'รายการลูกค้า',
			account_ids_hint: 'เลือกได้ไม่เกิน 1,000 คน',
			account_ids_placeholder: 'ค้นหาชื่อ',
			message: 'ข้อความ',
			message_hint: 'จำกัดตัวอักษร %{count}/70',
			btn_send_sms: 'เริ่มส่งข้อความ',
			save_success: 'ส่งข้อความ SMS เรียบร้อยแล้ว',
			save_fail: 'ไม่สามารถส่งข้อความ SMS ได้ กรุณาติดต่อผู้ดูแลระบบ',
			validator: {
				message: '^กรุณากรอกข้อความ',
			},
			simulator: {
				sender_name: 'TumTum',
				sub_header: 'Text Message',
				sample_message: 'ตัวอย่างข้อความ'
			}
		}
	}
};
