import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Typography
} from '@material-ui/core';

import Lottie from 'react-lottie';
import animationData from 'src/assets/lotties/bmtr-cover';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		}
	},
}));

const GettingStarted = ({ className, ...rest }) => {
	const classes = useStyles();

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	};

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Lottie
				options={defaultOptions}
				width="100%"
				height={250}
			/>
			<Box marginTop={4}>
				<Typography
					align="center"
					variant="h4"
					gutterBottom
				>
					{locale.t('sales.overview.welcome.getting_started.header')}
					{' '}
					<Typography
						color="primary"
						component="span"
						variant="inherit"
					>
						<strong>{locale.t('app_name')}</strong>
					</Typography>
					{' '}
					{locale.t('sales.overview.welcome.getting_started.header_2')}
				</Typography>
				<Typography
					align="center"
					color="textSecondary"
					variant="body1"
				>
					{locale.t('sales.overview.welcome.getting_started.sub_header')}
				</Typography>
			</Box>
		</Box>
	);
};

GettingStarted.propTypes = {
	className: PropTypes.string,
};

export default GettingStarted;
