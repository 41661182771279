import axios from 'axios';
import qs from 'qs';
import * as WebAPI from 'src/configs/WebAPI';
import { configureStore } from 'src/store';
import { logout, refreshToken } from 'src/actions';

const { store } = configureStore();

const instance = axios.create();

instance.interceptors.request.use((config) => {
	const newConfig = { ...config };
	newConfig.timeout = 180000;
	const { session } = store.getState();
	if (!newConfig.url.includes(WebAPI.LINE_TOKEN) && !newConfig.url.includes(WebAPI.LINE_NOTIFY)) {
		newConfig.headers.authorization = `Bearer ${session.user.access_token}`;
	}
	return newConfig;
}, (error) => Promise.reject(error));
instance.interceptors.response.use((response) => response, (error) => {
	const { config } = error;
	if (!config.url.includes(WebAPI.LOGIN) && !config.url.includes(WebAPI.FORGOT_PASSWORD)) {
		try {
			if ((error.response.status === 401 || error.response.status === 403) && !config.retry) {
				//	Refresh access token
				const { session } = store.getState();
				config.retry = true;
				const json = {
					email: session.user.email,
					refresh_token: session.user.refresh_token,
					project_id: session.project.id
				};
				return axios.create().post(WebAPI.REFRESH_TOKEN,
					qs.stringify(json))
					// eslint-disable-next-line consistent-return
					.then((response) => {
						if (response.status === 200) {
							const { data } = response;
							if (data.success) {
								//	Update access token
								store.dispatch(refreshToken(data.data.token));

								config.headers.authorization = `Bearer ${data.data.token.access_token}`;
								return instance.request(config);
							}
						}
					})
					.catch((mError) => {
						if (mError.response.status === 400 || mError.response.status === 401) {
							//	Clear session timer first
							const { localStorage } = window;
							localStorage.setItem('session-timer', null);

							//	No token found
							store.dispatch(logout());
							window.location = '/auth/login';
						}
					});
			}
		} catch {
			//	Network error
			// window.location = '/errors/error-500';
		}
	}
	return Promise.reject(error);
});

export default instance;
