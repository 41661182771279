import React, { useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
	Backdrop,
	Card,
	CardContent,
	CardHeader,
	Fade,
	Modal,
	Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Chart from './Chart';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
	},
	content: {
		// display: 'flex',
		// alignItems: 'center',
		// flexDirection: 'column',
		// paddingTop: 0
		position: 'relative',
		padding: '0 !important'
	},
	chart: {
		height: 80
	}
}));

function GraphChart({ className, ...rest }) {
	const classes = useStyles();

	const data = {
		thisWeek: {
			data: [],
			labels: []
		},
		thisMonth: {
			data: [],
			labels: []
		},
		thisYear: {
			// data: [28, 18, 4, 13, 12, 13, 5, 10, 5, 11, 20, 13],
			data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			labels: [
				'Jan',
				'Feb',
				'Mar',
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			]
		}
	};

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Typography
						color="textSecondary"
						variant="body2"
					>
						{/* <strong>{locale.t('sales.product.categories.result_title')}</strong> */}
						ยอดขายเฉลี่ยต่อเดือน
					</Typography>
				)}
				subheader={(
					<Typography
						variant="h4"
					>
						<strong>0</strong>
					</Typography>
				)}
			/>
			<CardContent className={classes.content}>
				<Chart
					className={classes.chart}
					data={data.thisYear.data}
					labels={data.thisYear.labels}
				/>
			</CardContent>
		</Card>
	);
}

export default GraphChart;
