import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Tooltip,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem
} from '@material-ui/core';

import AppsIcon from '@material-ui/icons/Apps';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';

const useStyles = makeStyles((theme) => ({
	item: {
		position: 'relative',
		maxWidth: 36,
		padding: theme.spacing(0.75),
		margin: theme.spacing(1, 0.5, 0.5),
		borderRadius: theme.spacing(0.75),
		transition: '0.3s',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.25)'
		},
		'&.Mui-selected': {
			backgroundColor: 'rgba(255, 255, 255, 0.3)'
		}
	},
	itemIcon: {
		minWidth: 0,
		color: theme.palette.primary.contrastText
	},
	itemArrow: {
		position: 'absolute',
		right: -16,
		width: 0,
		height: 0,
		borderTop: '6px solid transparent',
		borderBottom: '6px solid transparent',
		borderRight: '6px solid #FFFFFF'
	},
}));

function AppLauncher() {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	const moreRef = useRef(null);
	const [openMenu, setOpenMenu] = useState(false);

	const apps = [
		{ id: 'sales', title: 'Sales', icon: <StarsRoundedIcon /> },
		{ id: 'stock', title: 'Stock', icon: <HomeWorkRoundedIcon /> },
		{ id: 'crm', title: 'CRM', icon: <FaceRoundedIcon /> },
	];
	const filteredApps = apps.filter((a) => session.apps.findIndex((e) => e.code === a.id) !== -1);

	//	Functions

	const handleMenuOpen = () => {
		setOpenMenu(true);
	};

	const handleMenuClose = () => {
		setOpenMenu(false);
	};

	const handleMenuClick = (id) => {
		// dispatch(switchApp(id));
		setOpenMenu(false);

		//	Redirect
		window.location = `/${id}/overview`;
	};

	return (
		<>
			<ListItem
				className={classes.item}
				button
				onClick={handleMenuOpen}
				ref={moreRef}
			>
				<ListItemIcon className={classes.itemIcon}>
					<Tooltip
						arrow
						title="My Apps"
						placement="right"
					>
						<AppsIcon />
					</Tooltip>
				</ListItemIcon>
			</ListItem>
			<Menu
				anchorEl={moreRef.current}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				elevation={3}
				onClose={handleMenuClose}
				open={openMenu}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
			>
				{filteredApps.map((item) => (
					<MenuItem
						key={item.id}
						onClick={() => handleMenuClick(item.id)}
					>
						<ListItemIcon>
							{item.icon}
						</ListItemIcon>
						<ListItemText primary={item.title} />
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

export default AppLauncher;
