import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
// import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Container,
	Grid,
	Typography
	// useMediaQuery
} from '@material-ui/core';

import Page from 'src/components/Page';

import Map from './Components/Map';
import ProjectSummary from './Components/ProjectSummary';
import Products from './Components/Products';
import HeatMap from './Components/HeatMap';
import GraphChart from './Components/GraphChart';
import GraphChart2 from './Components/GraphChart2';
import Knowledges from './Components/Knowledges';

import WelcomeModal from './WelcomeModal';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		position: 'relative',
		zIndex: 9,
	},
	mapContainer: {
		position: 'absolute',
		zIndex: 2,
		width: 'calc(100% - 320px)',
		height: '100%',
		top: 0,
		left: 320
	}
}));

function Overview() {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	const [open, setOpen] = useState(false);

	//	Functions

	useEffect(() => {

		return () => {
			// mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleWelcomeClose = () => {
		setOpen(false);
	};

	return (
		<Page
			className={clsx(classes.root, {
				[classes.background]: session.user.role === 'MEMBER'
			})}
			title={locale.t('sales.overview.title')}
		>
			<Container
				className={classes.container}
				maxWidth="lg"
			>
				<Grid
					container
					spacing={3}
				>
					<Grid
						item
						md={6}
					>
						<Box>
							<Typography
								variant="h1"
							>
								{locale.t('sales.overview.header')}
							</Typography>
						</Box>
						<Box marginTop={5}>
							<ProjectSummary />
						</Box>
						<Box marginTop={10}>
							<Grid
								container
								spacing={3}
							>
								<Grid
									item
									md={12}
									xs={12}
								>
									<Products />
								</Grid>
								<Grid
									item
									md={6}
									xs={6}
								>
									<GraphChart />
								</Grid>
								<Grid
									item
									md={6}
									xs={6}
								>
									<GraphChart2 />
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid
						item
						md={6}
						xs={12}
					>
						<Map />
					</Grid>
					<Grid
						item
						md={9}
						xs={12}
					>
						<HeatMap />
					</Grid>
					<Grid
						item
						md={3}
						xs={6}
					>
						<Knowledges />
					</Grid>
				</Grid>
			</Container>
			{open && (
				<WelcomeModal
					open={open}
					onClose={handleWelcomeClose}
				/>
			)}
		</Page>
	);
}

export default Overview;
