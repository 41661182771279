// export const API_URL = 'http://localhost:7777/v3.0/';
export const API_URL = 'https://api.welacoffeeroaster.com/v3.0/';
export const MEMBER_API_URL = `${API_URL}member/`;
export const CORS_PROXY_URL = 'https://cors.neonetwork.tech/proxy/';

//  Project
export const PROJECT = `${MEMBER_API_URL}project`;

//  Authen
export const LOGIN = `${MEMBER_API_URL}auth/login`;
export const REFRESH_TOKEN = `${MEMBER_API_URL}auth/token`;
export const FORGOT_PASSWORD = `${MEMBER_API_URL}auth/forgot-password`;

//  Dashboard
export const MEMBER_WEBSITE = `${MEMBER_API_URL}website/member`;
export const GUEST_WEBSITE = `${MEMBER_API_URL}website/guest`;

//  Members
export const TEAM_MEMBERS = `${MEMBER_API_URL}member/team/members`;

//  Apps
export const APPS = `${MEMBER_API_URL}app/apps`;
export const APPS_DOWNLOAD = `${MEMBER_API_URL}app/apps/download`;
export const APPS_INSTALLED = `${MEMBER_API_URL}app/apps/installed`;

//  Overview
export const OVERVIEW_SALES = `${MEMBER_API_URL}sales/overview/sales`;
export const OVERVIEW_PRODUCTS = `${MEMBER_API_URL}sales/overview/products`;
export const OVERVIEW_LOCATIONS = `${MEMBER_API_URL}sales/overview/locations`;
export const OVERVIEW_ACTIVITIES = `${MEMBER_API_URL}sales/overview/activities`;
export const OVERVIEW_INCOME = '';

//  Content
export const CONTENTS = `${MEMBER_API_URL}sales/content/contents`;
export const UPLOAD_CONTENT_IMAGE = `${MEMBER_API_URL}sales/content/contents/upload`;

//  Sales
export const MENU_CATEGORIES = `${MEMBER_API_URL}sales/menu/categories`;
export const UPLOAD_MENU_CATEGORY_IMAGE = `${MEMBER_API_URL}sales/menu/categories/upload`;
export const MENUS = `${MEMBER_API_URL}sales/menu/menus`;
export const UPLOAD_MENU_IMAGE = `${MEMBER_API_URL}sales/menu/menus/upload`;

//  Sales
export const PRODUCT_CATEGORIES = `${MEMBER_API_URL}sales/product/categories`;
export const UPLOAD_PRODUCT_CATEGORY_IMAGE = `${MEMBER_API_URL}sales/product/categories/upload`;
export const PRODUCTS = `${MEMBER_API_URL}sales/product/products`;
export const UPLOAD_PRODUCT_IMAGE = `${MEMBER_API_URL}sales/product/products/upload`;

//  Page
export const PAGES = `${MEMBER_API_URL}sales/pages`;
export const PAGES_MAIN = `${MEMBER_API_URL}sales/pages/main`;
export const UPLOAD_PAGES_IMAGE = `${MEMBER_API_URL}sales/pages/upload`;
export const UPLOAD_PAGES_EDITOR_IMAGE = `${MEMBER_API_URL}sales/pages/editor/upload`;
export const PAGES_PRODUCTS = `${MEMBER_API_URL}sales/pages/components/products`;
export const PAGE_LOG = `${MEMBER_API_URL}sales/pages/log`;
export const PAGE_BANNERS = `${MEMBER_API_URL}sales/pages/banners`;
export const UPLOAD_PAGE_BANNERS_IMAGE = `${MEMBER_API_URL}sales/pages/banners/upload`;

//  Promotion
export const PROMOTIONS = `${MEMBER_API_URL}sales/promotion/promotions`;
export const MASTER_COUPONS = `${MEMBER_API_URL}sales/promotion/master-coupons`;
export const COUPONS = `${MEMBER_API_URL}sales/promotion/coupons`;
export const COUPON_HISTORIES = `${MEMBER_API_URL}sales/promotion/coupon/histories`;

//  Order
export const ORDERS = `${MEMBER_API_URL}sales/orders`;
export const EXPORT_ORDERS = `${MEMBER_API_URL}sales/orders/export`;
export const UPLOAD_ORDER_IMAGE = `${MEMBER_API_URL}sales/orders/upload`;

export const CUSTOMER_ORDERS = `${MEMBER_API_URL}sales/orders/customer`;
export const WAITING_CUSTOMER_ORDERS = `${MEMBER_API_URL}sales/orders/customer/waiting`;
export const CUSTOMER_ORDERS_TRACKING = `${MEMBER_API_URL}sales/orders/customer/tracking`;
export const CUSTOMER_ORDERS_TRACKING_PRINTING = `${MEMBER_API_URL}sales/orders/customer/tracking/printing`;
export const COURIERS_PRICE = `${MEMBER_API_URL}sales/orders/customer/check-price`;


//  Stock
export const STOCK_PRODUCT_ATTRIBUTES = `${MEMBER_API_URL}stock/product/attributes`;
export const STOCK_PRODUCT_VARIATIONS = `${MEMBER_API_URL}stock/product/variations`;
export const STOCK_INVENTORIES = `${MEMBER_API_URL}stock/inventory/imports`;
export const STOCK_MOVEMENTS = `${MEMBER_API_URL}stock/inventory/movements`;


//  CRM
export const CRM_EXPIRED_PRODUCT = `${MEMBER_API_URL}crm/product/expiring`;
export const CRM_EXPIRED_PRODUCT_SMS = `${MEMBER_API_URL}crm/product/expiring/sms`;
export const CRM_BROADCAST_SMS = `${MEMBER_API_URL}crm/broadcast/sms`;



//  Profile
export const PROFILE = `${MEMBER_API_URL}member/profile`;
export const CHANGE_PASSWORD = `${MEMBER_API_URL}member/profile/password`;
export const UPLOAD_PROFILE_AVATAR = `${MEMBER_API_URL}member/profile/upload`;

export const WEBSITE = `${MEMBER_API_URL}member/website`;
export const UPLOAD_WEBSITE_IMAGE = `${MEMBER_API_URL}member/website/upload`;





//  Accounting
export const ACCOUNTING_TARGETS = `${MEMBER_API_URL}accounting/targets`;
export const ACCOUNTING_EXPENSES_CATEGORIES = `${MEMBER_API_URL}accounting/expense/categories`;
export const ACCOUNTING_PERIODS = `${MEMBER_API_URL}accounting/periods`;
export const ACCOUNTING_EXPENSES = `${MEMBER_API_URL}accounting/expenses`;

//  Apps
export const APP_SHIPPING = `${MEMBER_API_URL}app/shipping`;
export const APP_PAYMENT = `${MEMBER_API_URL}app/payment`;

//  Couriers
export const COURIERS = `${MEMBER_API_URL}utils/couriers`;

//  Accounts
export const ACCOUNTS = `${MEMBER_API_URL}account/accounts`;
export const EXPORT_ACCOUNTS = `${MEMBER_API_URL}account/accounts/export`;
export const ACCOUNT_PROFILE = `${MEMBER_API_URL}account/profile`;
export const ACCOUNT_ORDERS = `${MEMBER_API_URL}account/orders`;
export const ACCOUNT_REDEEMS = `${MEMBER_API_URL}account/redeems`;

//  Common
export const COMMON_PROJECT_PRIVACY = `${API_URL}common/project/privacy`;

export const COMMON_BOXES = `${API_URL}common/shipping/boxes`;
export const COMMON_COURIERS = `${API_URL}common/shipping/couriers`;
export const COMMON_PAYMENT_METHODS = `${API_URL}common/payment/methods`;
export const COMMON_PAYMENT_BANKS = `${API_URL}common/payment/banks`;


//  Utils
export const ADDRESS = `${API_URL}common/address`;

//  Common

//  Others
export const PROVINCES = `${API_URL}data/provinces.json`;
export const BANKS = `${API_URL}data/banks.json`;

export const LINE_AUTH = 'https://notify-bot.line.me/oauth/authorize?';
export const LINE_TOKEN = 'https://notify-bot.line.me/oauth/token';
export const LINE_NOTIFY = 'https://notify-api.line.me/api/notify';
