import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000';

export default {
	primary: {
		contrastText: white,
		dark: '#606977',
		main: '#272537',
		light: '#D1DCE2',
	},
	secondary: {
		contrastText: black,
		dark: '#FFC100',
		main: '#FFDC02',
		light: '#FCDAA5',
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400]
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600],
		link: colors.blue[600]
	},
	link: colors.blue[800],
	icon: colors.blueGrey[600],
	background: {
		// default: '#F4F6F8',
		// default: '#fafbfd',
		default: '#f5f5fc',
		paper: white
	},
	divider: colors.grey[200]
};
