import * as actionTypes from 'src/actions';

const initialState = {
	/* loggedIn: true,
	user: {
		first_name: 'Shen',
		last_name: 'Zhi',
		email: 'demo@devias.io',
		avatar: '/images/avatars/avatar_11.png',
		bio: 'Brain Director',
		role: 'ADMIN' // ['GUEST', 'USER', 'ADMIN']
	} */
	loggedIn: false,
	user: {
		role: 'GUEST' // ['GUEST', 'USER', 'ADMIN']
	},
	apps: [],
	app: 'sales',
	sponsor: {},
	website: {},
	project: {},
	liff: {},
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_PROJECT: {
			return {
				...state,
				project: action.payload
			};
		}

		case actionTypes.SESSION_LOGIN: {
			return {
				...state,
				loggedIn: true,
				user: action.payload.user,
				apps: action.payload.apps
			};
		}

		case actionTypes.SESSION_LOGOUT: {
			return {
				...initialState,
				website: state.website,
				project: state.project
			};
		}

		case actionTypes.REFRESH_TOKEN: {
			const newState = { ...state };
			newState.user.access_token = action.payload.access_token;
			return {
				...newState,
			};
		}

		case actionTypes.UPDATE_PROFILE: {
			const newState = { ...state };
			const { payload } = action;
			if (payload.email) {
				newState.user.email = payload.email;
			}
			newState.user.firstname = payload.firstname;
			newState.user.lastname = payload.lastname;
			if (payload.phone) {
				newState.user.phone = payload.phone;
			}
			if (payload.avatar) {
				newState.user.avatar = payload.avatar;
			}

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_WEBSITE: {
			const newState = { ...state };
			const { payload } = action;
			newState.website = payload.website;
			newState.liff = payload.liff;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_WELCOME_DATE: {
			const newState = { ...state };
			const { payload } = action;
			newState.welcomeDate = payload;

			return {
				...newState
			};
		}

		case actionTypes.UPDATE_APPS: {
			const newState = { ...state };
			const { payload } = action;
			newState.apps = payload;

			return {
				...newState
			};
		}

		case actionTypes.SWITCH_APP: {
			const newState = { ...state };
			const { payload } = action;
			newState.app = payload;

			return {
				...newState
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
