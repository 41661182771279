import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import OverviewView from './views/Sales/Overview';
import DashboardLayout from './layouts/Dashboard';
import FullLayout from './layouts/Full';

export default [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/sales/overview" />
	},
	{
		path: '/auth',
		component: AuthLayout,
		routes: [
			{
				path: '/auth/login',
				exact: true,
				component: lazy(() => import('src/views/Auth/Login'))
			},
			{
				path: '/auth/forgot-password',
				exact: true,
				component: lazy(() => import('src/views/Auth/ForgotPassword'))
			},
			{
				path: '/auth/privacy-policy',
				exact: true,
				component: lazy(() => import('src/views/PrivacyPolicy'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/sales',
		component: DashboardLayout,
		routes: [
			{
				path: '/sales/overview',
				exact: true,
				component: OverviewView
			},
			{
				path: '/sales/content/articles',
				exact: true,
				component: lazy(() => import('src/views/Sales/Contents/ArticleList'))
			},
			{
				path: '/sales/content/articles/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Contents/ArticleDetails'))
			},
			{
				path: '/sales/content/promotions',
				exact: true,
				component: lazy(() => import('src/views/Sales/Contents/PromotionList'))
			},
			{
				path: '/sales/content/promotions/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Contents/PromotionDetails'))
			},
			{
				path: '/sales/products',
				exact: true,
				component: lazy(() => import('src/views/Sales/Products/ProductList'))
			},
			{
				path: '/sales/products/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Products/ProductDetails'))
			},
			{
				path: '/sales/product/categories',
				exact: true,
				component: lazy(() => import('src/views/Sales/Products/CategoryList'))
			},
			{
				path: '/sales/product/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Products/CategoryDetails'))
			},
			{
				path: '/sales/menu/categories',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/CategoryList'))
			},
			{
				path: '/sales/menu/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/CategoryDetails'))
			},
			{
				path: '/sales/menu/categories',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/CategoryList'))
			},
			{
				path: '/sales/menu/categories/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/CategoryDetails'))
			},
			{
				path: '/sales/menus',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/MenuList'))
			},
			{
				path: '/sales/menus/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Menus/MenuDetails'))
			},
			{
				path: '/sales/pages',
				exact: true,
				component: lazy(() => import('src/views/Sales/Pages/PageList'))
			},
			{
				path: '/sales/pages/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Pages/PageDetails'))
			},
			{
				path: '/sales/pages/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Sales/Pages/PageDetails'))
			},
			{
				path: '/sales/coupons',
				exact: true,
				component: lazy(() => import('src/views/Sales/Promotions/CouponList'))
			},
			{
				path: '/sales/coupons/add',
				exact: true,
				component: lazy(() => import('src/views/Sales/Promotions/CouponDetailsAdd'))
			},
			{
				path: '/sales/coupon/histories',
				exact: true,
				component: lazy(() => import('src/views/Sales/Promotions/CouponHistoryList'))
			},
			{
				path: '/sales/coupons/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Promotions/CouponDetails'))
			},
			{
				path: '/sales/orders/summary',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/OrderList'))
			},
			{
				path: '/sales/orders/waiting',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/WaitingList'))
			},
			{
				path: '/sales/orders/tracking',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/TrackingList'))
			},
			{
				path: '/sales/orders/tracking/printing/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/TrackingPrinting'))
			},
			{
				path: '/sales/orders/summary/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/OrderDetails'))
			},
			{
				path: '/sales/orders/printing/:id',
				exact: true,
				component: lazy(() => import('src/views/Sales/Orders/OrderPrinting'))
			},
			{
				path: '/sales/apps/shipping',
				exact: true,
				component: lazy(() => import('src/views/Sales/Apps/Shipping')),
			},
			{
				path: '/sales/apps/shipping/:tab',
				exact: true,
				component: lazy(() => import('src/views/Sales/Apps/Shipping')),
			},
			{
				path: '/sales/apps/payment',
				exact: true,
				component: lazy(() => import('src/views/Sales/Apps/Payment')),
			},
			{
				path: '/sales/apps/payment/:tab',
				exact: true,
				component: lazy(() => import('src/views/Sales/Apps/Payment')),
			},
			{
				path: '/sales/apps/page',
				exact: true,
				component: lazy(() => import('src/views/Sales/Apps/SalePage')),
			},
			{
				path: '/sales/website',
				exact: true,
				component: lazy(() => import('src/views/Sales/Website'))
			},
			{
				path: '/sales/website/:tab',
				exact: true,
				component: lazy(() => import('src/views/Sales/Website'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/stock',
		component: DashboardLayout,
		routes: [
			{
				path: '/stock/overview',
				exact: true,
				component: lazy(() => import('src/views/Stock/Overview'))
			},
			{
				path: '/stock/product/attributes',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/AttributeList'))
			},
			{
				path: '/stock/product/attributes/:id',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/AttributeDetails'))
			},
			{
				path: '/stock/products',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/ProductList'))
			},
			{
				path: '/stock/products/:id',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/ProductDetails'))
			},
			{
				path: '/stock/products/variations/:id',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/VariationDetails'))
			},
			{
				path: '/stock/products/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/Stock/Products/ProductDetails'))
			},
			// {
			// 	path: '/stock/inventories',
			// 	exact: true,
			// 	component: lazy(() => import('src/views/Stock/Stock/InventoryList'))
			// },
			{
				path: '/stock/inventory/imports',
				exact: true,
				component: lazy(() => import('src/views/Stock/Inventories/ImportList'))
			},
			{
				path: '/stock/inventory/movements',
				exact: true,
				component: lazy(() => import('src/views/Stock/Inventories/MovementList'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/crm',
		component: DashboardLayout,
		routes: [
			{
				path: '/crm/overview',
				exact: true,
				component: lazy(() => import('src/views/CRM/Overview'))
			},

			{
				path: '/crm/accounts',
				exact: true,
				component: lazy(() => import('src/views/CRM/Accounts/AccountList'))
			},
			{
				path: '/crm/accounts/:id',
				exact: true,
				component: lazy(() => import('src/views/CRM/Accounts/AccountDetails'))
			},
			{
				path: '/crm/accounts/:id/:tab',
				exact: true,
				component: lazy(() => import('src/views/CRM/Accounts/AccountDetails'))
			},
			{
				path: '/crm/products',
				exact: true,
				component: lazy(() => import('src/views/CRM/Products/ProductList'))
			},
			{
				path: '/crm/broadcast/sms',
				exact: true,
				component: lazy(() => import('src/views/CRM/Broadcast/SMSDetails'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '/apps',
		component: AuthLayout,
		routes: [
			{
				path: '/apps/install/:id',
				exact: true,
				component: lazy(() => import('src/views/Apps'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	// {
	// 	path: '*',
	// 	component: DashboardLayout,
	// 	routes: [
	// 		{
	// 			path: '/settings',
	// 			exact: true,
	// 			component: lazy(() => import('src/views/Sales/Settings/Profile'))
	// 		},
	// 		{
	// 			path: '/settings/profile/:tab',
	// 			exact: true,
	// 			component: lazy(() => import('src/views/Sales/Settings/Profile'))
	// 		},
	// 		{
	// 			component: () => <Redirect to="/errors/error-404" />
	// 		}
	// 	]
	// }
];
