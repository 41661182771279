import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';

import 'react-perfect-scrollbar/dist/css/styles.css';
// import { theme, themeWithRtl } from './theme';
import { configureStore } from './store';
import routes from './routes';
// import GoogleAnalytics from './components/GoogleAnalytics';
// import CookiesNotification from './components/CookiesNotification';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mixins/cookie';

import './assets/scss/main.scss';
import { baseTheme } from './theme';

const history = createBrowserHistory();
const { store, persistor } = configureStore();

function App() {
	const [direction] = useState('ltr');
	const theme = createMuiTheme(baseTheme);

	return (
		<StoreProvider store={store}>
			<ThemeProvider theme={theme}>
				<StylesProvider direction={direction}>
					<PersistGate loading={null} persistor={persistor}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<Router history={history}>
								<ScrollReset />
								{/* <GoogleAnalytics /> */}
								{/* <CookiesNotification /> */}
								{renderRoutes(routes)}
							</Router>
						</MuiPickersUtilsProvider>
					</PersistGate>
				</StylesProvider>
			</ThemeProvider>
		</StoreProvider>
	);
}

export default App;
