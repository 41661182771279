const locale = require('react-redux-i18n').I18n;

window.addEventListener('load', () => {
	// obtain plugin
	const cc = window.initCookieConsent();
	// run plugin with your configuration
	cc.run({
		current_lang: 'th',
		autoclear_cookies: true, // default: false
		page_scripts: true, // default: false
		gui_options: {
			consent_modal: {
				layout: 'cloud', // box/cloud/bar
				position: 'bottom center', // bottom/middle/top + left/right/center
				transition: 'slide', // zoom/slide
				swap_buttons: false // enable to invert buttons
			},
			settings_modal: {
				layout: 'box', // box/bar
				// position: 'left',           // left/right
				transition: 'slide' // zoom/slide
			}
		},
		languages: {
			th: {
				consent_modal: {
					title: 'เว็บไซต์นี้ใช้คุกกี้ ' + locale.t('app_name'),
					description: 'เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอมการใช้คุกกี้ได้ โดยคลิก <button type="button" data-cc="c-settings" class="cc-link">"การตั้งค่าคุกกี้"</button>',
					primary_btn: {
						text: 'ยอมรับทั้งหมด',
						role: 'accept_all'// 'accept_selected' or 'accept_all'
					},
				},
				settings_modal: {
					title: 'การตั้งค่าความเป็นส่วนตัว',
					save_settings_btn: 'ยืนยันตัวเลือกของคุณ',
					accept_all_btn: 'ยอมรับทั้งหมด',
					close_btn_label: 'Close',
					cookie_table_headers: [
						{ col1: 'Name' },
						{ col2: 'Domain' },
						{ col3: 'Expiration' },
						{ col4: 'Description' }
					],
					blocks: [
						{
							title: 'การใช้คุกกี้ 🍪',
							description: 'Wela Coffee Roaster ใช้คุกกี้เพื่อให้แน่ใจว่าฟังก์ชันพื้นฐานของเว็บไซต์และเพื่อปรับปรุงประสบการณ์ออนไลน์ของคุณ สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับคุกกี้และข้อมูลสำคัญอื่นๆ โปรดอ่านรายละเอียดที่ <a href="https://says.social/terms/privacy-policy" class="cc-link">ความเป็นส่วนตัว</a>.'
						},
						{
							title: 'คุกกี้พื้นฐานที่จำเป็น',
							description: 'จำเป็นสำหรับการทำงานที่เหมาะสมของเว็บไซต์ หากไม่มีคุกกี้เหล่านี้ เว็บไซต์ก็จะทำงานไม่ถูกต้อง (ไม่สามารถปิดการใช้งานได้)',
							toggle: {
								value: 'necessary',
								enabled: true,
								readonly: true
							}
						}
					]
				}
			}
		}
	});
});
