export default [
	{
		id: 'TH-10',
		code: 'Bangkok Metropolis',
		name: 'กรุงเทพมหานคร'
	},
	{
		id: 'TH-11',
		code: 'Samut Prakan',
		name: 'สมุทรปราการ'
	},
	{
		id: 'TH-12',
		code: 'Nonthaburi',
		name: 'นนทบุรี'
	},
	{
		id: 'TH-13',
		code: 'Pathum Thani',
		name: 'ปทุมธานี'
	},
	{
		id: 'TH-14',
		code: 'Phra Nakhon Si Ayutthaya',
		name: 'พระนครศรีอยุธยา'
	},
	{
		id: 'TH-15',
		code: 'Ang Thong',
		name: 'อ่างทอง'
	},
	{
		id: 'TH-16',
		code: 'Lop Buri',
		name: 'ลพบุรี'
	},
	{
		id: 'TH-17',
		code: 'Sing Buri',
		name: 'สิงห์บุรี'
	},
	{
		id: 'TH-18',
		code: 'Chai Nat',
		name: 'ชัยนาท'
	},
	{
		id: 'TH-19',
		code: 'Saraburi',
		name: 'สระบุรี'
	},
	{
		id: 'TH-20',
		code: 'Chon Buri',
		name: 'ชลบุรี'
	},
	{
		id: 'TH-21',
		code: 'Rayong',
		name: 'ระยอง'
	},
	{
		id: 'TH-22',
		code: 'Chanthaburi',
		name: 'จันทบุรี'
	},
	{
		id: 'TH-23',
		code: 'Trat',
		name: 'ตราด'
	},
	{
		id: 'TH-24',
		code: 'Chachoengsao',
		name: 'ฉะเฉิงเทรา'
	},
	{
		id: 'TH-25',
		code: 'Prachin Buri',
		name: 'ปราจีนบุรี'
	},
	{
		id: 'TH-26',
		code: 'Nakhon Nayok',
		name: 'นครนายก'
	},
	{
		id: 'TH-27',
		code: 'Sa Kaeo',
		name: 'สระแก้ว'
	},
	{
		id: 'TH-30',
		code: 'Nakhon Ratchasima',
		name: 'นครราชสีมา'
	},
	{
		id: 'TH-31',
		code: 'Buri Ram',
		name: 'บุรีรัมย์'
	},
	{
		id: 'TH-32',
		code: 'Surin',
		name: 'สุรินทร์'
	},
	{
		id: 'TH-33',
		code: 'Si Sa Ket',
		name: 'ศรีสะเกษ'
	},
	{
		id: 'TH-34',
		code: 'Ubon Ratchathani',
		name: 'อุบลราชธานี'
	},
	{
		id: 'TH-35',
		code: 'Yasothon',
		name: 'ยโสธร'
	},
	{
		id: 'TH-36',
		code: 'Chaiyaphum',
		name: 'ชัยภูมิ'
	},
	{
		id: 'TH-37',
		code: 'Amnat Charoen',
		name: 'อำนาจเจริญ'
	},
	{
		id: 'TH-38',
		code: 'Bueng Kan',
		name: 'บึงกาฬ'
	},
	{
		id: 'TH-39',
		code: 'Nong Bua Lam Phu',
		name: 'หนองบัวลำภู'
	},
	{
		id: 'TH-40',
		code: 'Khon Kaen',
		name: 'ขอนแก่น'
	},
	{
		id: 'TH-41',
		code: 'Udon Thani',
		name: 'อุดรธานี'
	},
	{
		id: 'TH-42',
		code: 'Loei',
		name: 'เลย'
	},
	{
		id: 'TH-43',
		code: 'Nong Khai',
		name: 'หนองคาย'
	},
	{
		id: 'TH-44',
		code: 'Maha Sarakham',
		name: 'มหาสารคาม'
	},
	{
		id: 'TH-45',
		code: 'Roi Et',
		name: 'ร้อยเอ็ด'
	},
	{
		id: 'TH-46',
		code: 'Kalasin',
		name: 'กาฬสินธุ์'
	},
	{
		id: 'TH-47',
		code: 'Sakon Nakhon',
		name: 'สกลนคร'
	},
	{
		id: 'TH-48',
		code: 'Nakhon Phanom',
		name: 'นครพนม'
	},
	{
		id: 'TH-49',
		code: 'Mukdahan',
		name: 'มุกดาหาร'
	},
	{
		id: 'TH-50',
		code: 'Chiang Mai',
		name: 'เชียงใหม่'
	},
	{
		id: 'TH-51',
		code: 'Lamphun',
		name: 'ลำพูน'
	},
	{
		id: 'TH-52',
		code: 'Lampang',
		name: 'ลำปาง'
	},
	{
		id: 'TH-53',
		code: 'Uttaradit',
		name: 'อุตรดิตถ์'
	},
	{
		id: 'TH-54',
		code: 'Phrae',
		name: 'พลา'
	},
	{
		id: 'TH-55',
		code: 'Nan',
		name: 'น่าน'
	},
	{
		id: 'TH-56',
		code: 'Phayao',
		name: 'พะเยา'
	},
	{
		id: 'TH-57',
		code: 'Chiang Rai',
		name: 'เชียงราย'
	},
	{
		id: 'TH-58',
		code: 'Mae Hong Son',
		name: 'แม่ฮ่องสอน'
	},
	{
		id: 'TH-60',
		code: 'Nakhon Sawan',
		name: 'นครสวรรค์'
	},
	{
		id: 'TH-61',
		code: 'Uthai Thani',
		name: 'อุทัยธานี'
	},
	{
		id: 'TH-62',
		code: 'Kamphaeng Phet',
		name: 'กำแพงเพชร'
	},
	{
		id: 'TH-63',
		code: 'Tak',
		name: 'ตาก'
	},
	{
		id: 'TH-64',
		code: 'Sukhothai',
		name: 'สุโขทัย'
	},
	{
		id: 'TH-65',
		code: 'Phitsanulok',
		name: 'พิษณุโลก'
	},
	{
		id: 'TH-66',
		code: 'Phichit',
		name: 'พิจิตร'
	},
	{
		id: 'TH-67',
		code: 'Phetchabun',
		name: 'เพชรบูรณ์'
	},
	{
		id: 'TH-70',
		code: 'Ratchaburi',
		name: 'ราชบุรี'
	},
	{
		id: 'TH-71',
		code: 'Kanchanaburi',
		name: 'กาญจนบุรี'
	},
	{
		id: 'TH-72',
		code: 'Suphan Buri',
		name: 'สุพรรณบุรี'
	},
	{
		id: 'TH-73',
		code: 'Nakhon Pathom',
		name: 'นครปฐม'
	},
	{
		id: 'TH-74',
		code: 'Samut Sakhon',
		name: 'สมุทรสาคร'
	},
	{
		id: 'TH-75',
		code: 'Samut Songkhram',
		name: 'สมุทรสงคราม'
	},
	{
		id: 'TH-76',
		code: 'Phetchaburi',
		name: 'เพชรบุรี'
	},
	{
		id: 'TH-77',
		code: 'Prachuap Khiri Khan',
		name: 'ประจวบคีรีขันธ์'
	},
	{
		id: 'TH-80',
		code: 'Nakhon Si Thammarat',
		name: 'นครศรีธรรมราช'
	},
	{
		id: 'TH-81',
		code: 'Krabi',
		name: 'กระบี่'
	},
	{
		id: 'TH-82',
		code: 'Phangnga',
		name: 'พังงา'
	},
	{
		id: 'TH-83',
		code: 'Phuket',
		name: 'ภูเก็ต'
	},
	{
		id: 'TH-84',
		code: 'Surat Thani',
		name: 'สุราษฎร์ธานี'
	},
	{
		id: 'TH-85',
		code: 'Ranong',
		name: 'ระนอง'
	},
	{
		id: 'TH-86',
		code: 'Chumphon',
		name: 'ชุมพร'
	},
	{
		id: 'TH-90',
		code: 'Songkhla',
		name: 'สงขลา'
	},
	{
		id: 'TH-91',
		code: 'Satun',
		name: 'สตูล'
	},
	{
		id: 'TH-92',
		code: 'Trang',
		name: 'หอก'
	},
	{
		id: 'TH-93',
		code: 'Phatthalung',
		name: 'พัทลุง'
	},
	{
		id: 'TH-94',
		code: 'Pattani',
		name: 'ปัตตานี'
	},
	{
		id: 'TH-95',
		code: 'Yala',
		name: 'ยะลา'
	},
	{
		id: 'TH-96',
		code: 'Narathiwat',
		name: 'นราธิวาส'
	},
	{
		id: 'TH-LK',
		code: 'Songkhla Lake',
		name: 'ทะเลสาบสงขลา'
	}
];
