export default {
	nav: {
		data: {
			default: 'My Workspace',
			overview: 'Dashboard'
		},
		product: {
			default: 'สินค้า',
			attributes: 'คุณลักษณะสินค้า',
			products: 'รายการสินค้า',
		},
		inventory: {
			default: 'สต็อกสินต้า',
			imports: 'นำเข้าสินค้า',
			movements: 'ความเคลื่อนไหวสินค้า'
		}
	},
	overview: {
		welcome: {
			getting_started: {
				header: 'Welcome to',
				header_2: 'Workspace',
				sub_header: 'We\'re here to show you around and help you get started',
			},
			btn_later: 'I\'ll do it later',
			btn_start: 'Start Tour',
			btn_finish: 'Ready to work',
		}
	},
	product: {
		attributes: {
			title: 'รายการคุณลักษณะสินค้า',
			header: 'รายการคุณลักษณะสินค้า',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อคุณลักษณะ',
			result_title: 'รายการคุณลักษณะทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'รายละเอียดย่อย',
			item_col3: 'แก้ไขล่าสุด',
			item_col4: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการคุณลักษณะสินค้า',
			delete_success: 'ลบข้อมูลคุณลักษณะสินค้าเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลคุณลักษณะสินค้า',
				save_fail: 'ไม่สามารถสร้างข้อมูลคุณลักษณะสินค้าได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการคุณลักษณะสินค้า',
				header: 'จัดการข้อมูลคุณลักษณะสินค้า',
				form_header: 'แก้ไขคุณลักษณะสินค้า',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับการจัดการสินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อคุณลักษณะ',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				values: 'รายละเอียดย่อย',
				values_hint: 'ไม่จำกัดจำนวนรายละเอียดย่อย',
				values_placeholder: 'กรอกรายละเอียดย่อยและกดปุ่ม "Enter"',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงคุณลักษณะสินค้าต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลคุณลักษณะสินค้าใหม่เรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อคุณลักษณะ',
				},
				related_list: {
					header: 'สินค้าที่เกี่ยวข้อง',
					btn_see_all: 'ดูสินค้าทั้งหมด',
					no_data: 'ไม่พบข้อมูลรายการสินค้าในหมวดหมู่นี้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลคุณลักษณะสินค้า'
				}
			}
		},
		products: {
			title: 'รายการสินค้า',
			header: 'รายการสินค้า',
			search_placeholder: 'ค้นหาชื่อสินค้า',
			result_title: 'รายการสินค้าทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'หมวดหมู่',
			item_col3: 'จำนวนคุณลักษณะ',
			item_col3_no_attribute: 'ไม่ระบุ',
			item_col4: 'แก้ไขล่าสุด',
			item_col5: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการสินค้า',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			detail: {
				title: 'รายละเอียดและคุณลักษณะสินค้า',
				header: 'รายละเอียดและคุณลักษณะสินค้า',
				tab: {
					default: 'ข้อมูลทั่วไป',
					updated_date: 'วันที่แก้ไขล่าสุด',
					btn_view: 'ดูรายละเอียดสินค้า',
					variations: {
						action: 'เพิ่มรายการ',
						result_title: 'รายการคุณลักษณะทั้งหมด',
						result_hint: 'รายการที่พบ',
						item_col: 'สร้างโดย',
						item_col2: 'รายละเอียดย่อย',
						no_data: {
							title: 'ไม่พบข้อมูลรายการรายละเอียดย่อยของสินค้านี้',
							desc: 'คุณสามารถกำหนดลักษณะสินค้าใหม่เพิ่มเติมได้ด้วยตัวเอง',
							desc_2: 'ผ่านการเพิ่มรายการด้านบน เพื่อให้สินค้าของคุณมีความหลากหลายมากขึ้น',
						},
						dialog: {
							title: 'คำเตือน !!',
							delete_data_message: 'การลบคุณลักษณะนี้ออกจากสินค้า จะส่งผลกระทบโดยตรงกับการกำหนดสินค้า คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
						},
						delete_success: 'ลบข้อมูลนำเข้าสินค้าเรียบร้อยแล้ว',
						create: {
							header: 'เพิ่มข้อมูลลักษณะสินค้า',
							variation_id: 'เลือกคุณลักษณะ',
							variation_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						},
					},
					save_success: 'บันทึกรายการคุณลักษณะใหม่ของสินค้าเรียบร้อยแล้ว',
				},
				tab2: {
					default: 'รายการย่อย',
					result_title: 'รายการสินค้าย่อยทั้งหมด',
					item_col: 'สร้างโดย',
					item_col2: 'ลักษณะสินค้า',
					item_col3: 'แก้ไขล่าสุด',
					item_col4: 'สถานะ',
					delete_success: 'ลบข้อมูลรายการสินค้าย่อยเรียบร้อยแล้ว',
					sorting: {
						item: 'SKU',
						item2: 'ล่าสุด',
					},
					no_data: {
						title: 'ไม่พบข้อมูลรายการสินค้าย่อย',
						desc: 'คุณสร้างสามารถสร้างรายการสินค้าย่อยเองได้',
						desc_2: 'ผ่านตัวช่วยสร้างสินค้าที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
						btn_add: 'เพิ่มสินค้าย่อยใหม่ทันที',
						remark: 'เพื่อดำเนินการต่อ',
					},
					create: {
						header: 'สร้างข้อมูลสินค้าย่อย',
						save_fail: 'ไม่สามารถสร้างข้อมูลสินค้าย่อยได้ กรุณาติดต่อผู้ดูแลระบบ'
					},
					detail: {
						title: 'แก้ไขข้อมูลสินค้าย่อย',
						header: 'แก้ไขข้อมูลสินค้าย่อย',
						form_header: 'แก้ไขสินค้าย่อย',
						form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับการจัดการสินค้า ซึ่งจะแสดงผลต่อลูกค้าเมื่อมีการเปิดเผยต่อสาธารณะ',
						attribute_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
						sku: 'หน่วยสินค้า (SKU)',
						sku_hint: 'จำกัดตัวอักษร %{count}/100',
						life_time: 'อายุสินค้า',
						life_time_hint: 'กรอก 0 หากไม่ต้องการระบุวันหมดอายุ',
						price: 'ราคาเต็ม',
						sale_price: 'ราคาขาย',
						is_enabled: 'เปิดเผยต่อสาธารณะ',
						is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงรายการสินค้าย่อยต่อลูกค้า',
						save_success: 'บันทึกรายการข้อมูลสินค้าย่อยใหม่เรียบร้อยแล้ว',
						validator: {
							sku: '^กรุณากรอกหน่วยสินค้า (SKU)',
							life_time: '^กรุณากรอกอายุสินค้า',
							price: '^กรุณากรอกราคาเต็ม',
							sale_price: '^กรุณากรอกราคาขาย',
						},
						other_actions: {
							header: 'Other Actions',
							delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
							btn_delete: 'ลบข้อมูลสินค้าย่อย'
						}
					}
				},
			}
		}
	},
	inventory: {
		imports: {
			title: 'รายการนำเข้าสินค้า',
			header: 'รายการนำเข้าสินค้า',
			action: 'เพิ่มรายการ',
			result_title: 'รายการนำเข้าสินค้าทั้งหมด',
			item_col: 'นำเข้าโดย',
			item_col2: 'จำนวนสินค้า',
			item_col3: 'ต้นทุนต่อหน่วย',
			item_col4: 'วันที่นำเข้า',
			no_data: 'ไม่พบข้อมูลรายการนำเข้าสินค้า',
			delete_success: 'ลบข้อมูลนำเข้าสินค้าเรียบร้อยแล้ว',
			search: {
				search_placeholder: 'ค้นหาชื่อสินค้า',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลนำเข้าสินค้า',
				save_fail: 'ไม่สามารถสร้างข้อมูลนำเข้าสินค้าได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				header: 'แก้ไขข้อมูลนำเข้าสินค้า',
				product_id: 'ชื่อสินค้า',
				product_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				variation_id: 'รายการย่อย',
				variation_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				variation_id_required: '***ไม่พบรายการสินค้าย่อย กรุณาตรวจสอบอีกครั้ง',
				qty: 'จำนวนนำเข้า',
				cost_per_unit: 'ต้นทุนต่อหน่วย',
				save_success: 'บันทึกรายการข้อมูลนำเข้าสินค้าใหม่เรียบร้อยแล้ว',
				validator: {
					product_id: '^กรุณาเลือกสินค้า',
					variation_id: '^กรุณาเลือกรายการย่อย',
					qty: '^กรุณากรอกจำนวนนำเข้า',
					cost_per_unit: '^กรุณากรอกต้นทุนต่อหน่วย',
				},
			}
		},
		movements: {
			title: 'รายการความเคลื่อนไหวสินค้า',
			header: 'รายการความเคลื่อนไหวสินค้า',
			result_title: 'รายการความเคลื่อนไหวสินค้าทั้งหมด',
			item_col: 'แหล่งที่มา',
			item_col_import: 'นำเข้า',
			item_col_order: 'คำสั่งซื้อ ',
			item_col2: 'จำนวนสินค้า',
			item_col3: 'วันที่เกิดขึ้น',
			no_data: 'ไม่พบข้อมูลรายการความเคลื่อนไหวสินค้า',
			search: {
				search_placeholder: 'ค้นหาชื่อสินค้า',
				start_date: 'วันเริ่มต้น',
				end_date: 'ถึง'
			},
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
		},
	}
};
