import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Backdrop,
	Button,
	Card,
	CardActions,
	CardContent,
	Fade,
	MobileStepper,
	Modal,
} from '@material-ui/core';

import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import GettingStarted from './GettingStarted';
import VideoContent from './VideoContent';

import SwipeableViews from 'react-swipeable-views';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		outline: 'none',
		boxShadow: theme.shadows[20],
		width: 500,
		maxHeight: '100%',
		overflowY: 'auto',
		maxWidth: '100%',
		transition: 'width 150ms, height 150ms !important',
	},
	gettingStarted: {
		width: 500,
	},
	videoContent: {
		width: 700,
	},
	content: {
		padding: theme.spacing(0, 0, 2),
	},
	editor: {
		boxShadow: 'none',
		'& img': {
			width: '100%'
		}
	},
	stepper: {
		width: '100%',
		flexGrow: 1,
		justifyContent: 'center',
		backgroundColor: 'transparent',
		marginTop: theme.spacing(1)
	},
	actions: {
		backgroundColor: theme.palette.background.default,
		justifyContent: 'flex-end',
		'& button': {
			textTransform: 'none'
		}
	},
}));

const WelcomeModal = (props) => {
	const {
		className,
		content,
		open,
		onClose,
		...rest
	} = props;

	const classes = useStyles();

	const [forceClose, setForceClose] = useState(false);
	const [activeStep, setActiveStep] = useState(0);

	//	Functions

	const handleNext = () => {
		setActiveStep(1);
	};

	const handleClose = () => {
		setForceClose(true);

		//	Set onClose after animation has done
		setTimeout(() => {
			onClose(false);
		}, 300);
	};

	const handleBack = () => {
		setActiveStep(0);
	};

	const handleFinish = () => {
		handleClose();
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	return (
		<Modal
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			disableBackdropClick
			closeAfterTransition
			onClose={() => {
				onClose(false);
			}}
			open={open && !forceClose}
		>
			<Fade in={open && !forceClose}>
				<Card
					{...rest}
					className={clsx(
						classes.root, {
							[classes.gettingStarted]: activeStep === 0,
							[classes.videoContent]: activeStep === 1,
						},
						className
					)}
				>
					<CardContent className={classes.content}>
						<SwipeableViews
							index={activeStep}
							onChangeIndex={handleStepChange}
							enableMouseEvents
						>
							<GettingStarted />
							<VideoContent />
						</SwipeableViews>
						<MobileStepper
							className={classes.stepper}
							variant="dots"
							steps={2}
							position="static"
							activeStep={activeStep}
						/>
					</CardContent>
					<CardActions className={classes.actions}>
						{activeStep === 0 && (
							<>
								<Button
									onClick={handleClose}
									variant="text"
								>
									{locale.t('sales.overview.welcome.btn_later')}
								</Button>
								<Button
									color="secondary"
									onClick={handleNext}
									variant="contained"
									startIcon={<PlayCircleFilledRoundedIcon />}
								>
									{locale.t('sales.overview.welcome.btn_start')}
								</Button>
							</>
						)}
						{activeStep === 1 && (
							<>
								<Button
									onClick={handleBack}
									variant="text"
									startIcon={<ArrowBackRoundedIcon />}
								>
									{locale.t('btn.back')}
								</Button>
								<Button
									color="primary"
									onClick={handleFinish}
									variant="contained"
									startIcon={<CheckCircleRoundedIcon />}
								>
									{locale.t('sales.overview.welcome.btn_finish')}
								</Button>
							</>
						)}
					</CardActions>
				</Card>
			</Fade>
		</Modal>
	);
};

WelcomeModal.displayName = 'WelcomeModal';

WelcomeModal.propTypes = {
	className: PropTypes.string,
	content: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool
};

WelcomeModal.defaultProps = {
	open: false,
	onClose: () => { }
};

export default WelcomeModal;
