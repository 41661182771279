/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const { NODE_ENV } = process.env;

const useStyles = makeStyles(() => ({
	root: {
		minHeight: 'calc(100vh - 96px)'
	}
}));

function Page({
	children, title, className, ...rest
}) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	useEffect(() => {
		if (NODE_ENV !== 'production') {
			// eslint-disable-next-line no-useless-return
			return;
		}

		// if (window.gtag) {
		// 	window.gtag('config', GA_MEASUREMENT_ID, {
		// 		page_path: location.pathname,
		// 		page_name: `${locale.t('app_name')} - ${title}`
		// 	});
		// }

		// eslint-disable-next-line
	}, []);

	return (
		<div
			className={clsx(classes.root, className)}
			{...rest}
		>
			<Helmet>
				<title>{`${session.website.site_title} - ${title}`}</title>
			</Helmet>
			{children}
		</div>
	);
}

Page.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	className: PropTypes.string
};

export default Page;
