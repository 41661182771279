export default {
	page_editor: {
		no_data: 'ไม่พบการแสดงผลในหน้านี้ กรุณาเลือกเครื่องมือทางด้านขวา',
		save_success: 'บันทึกข้อมูลเซลเพจเรียบร้อยแล้ว',
		floating_menu: {
			text: 'ข้อความ',
			image: 'รูปภาพ',
			video: 'วิดีโอ',
			contact_form: 'ฟอร์มติดต่อ',
			call_to_actions: 'Call to Actions',
			slide_images: 'สไลด์รูปภาพ',
			slide_products: 'ขายสินค้า',
			countdown_timer: 'Flash Sale',
		},
		blocks: {
			slide_images: {
				image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				image_info: 'ภาพทั้งหมดที่นำมาใส่ควรมีขนาดเท่ากัน'
			},
			slide_products: {
				price: 'ราคาเต็ม',
				sale_price: 'ราคาขาย',
				no_data: 'ไม่พบรายการสินค้า กด + เพื่อเพิ่มสินค้า',
				modal: {
					header: 'เพิ่มรายการสินค้า',
					search_placeholder: 'ค้นหาชื่อสินค้า',
				}
			},
			image: {
				hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				info: 'ขนาดภาพแนะนำ 800x800',
				action: 'ตั้งค่ารูปภาพ',
				settings: {
					header: 'ตั้งค่ารูปภาพ',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
					link: 'ลิงค์',
				}
			},
			contact_form: {
				email: 'อีเมล',
				email_hint: 'john.doe@mail.com',
				firstname: 'ชื่อจริง',
				firstname_hint: 'John',
				lastname: 'นามสกุล',
				lastname_hint: 'Doe',
				phone: 'เบอร์โทรศัพท์',
				phone_hint: '089 281 8212',
				action: 'ตั้งค่าฟอร์ม',
				settings: {
					header: 'ตั้งค่าฟอร์มติดต่อ',
					sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ ได้ตรงตามความต้องการ',
					mode: 'รูปแบบการแสดงผล',
					mode_normal: 'ปกติ',
					mode_popup: 'ป๊อบอัพ',
				}
			},
			video: {
				label: 'Url วิดีโอ',
				hint: 'รองรับ YouTube, Vimeo'
			},
			two_block_image: {
				hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				info: 'ขนาดภาพแนะนำ 800x800'
			},
			countdown_timer: {
				title: 'รายละเอียด Flash Sale',
				target_date: 'วันที่สิ้นสุด',
				target_time: 'เวลา'
			}
		},
		actions: {
			up: 'เลื่อนขึ้น',
			down: 'เลื่อนลง',
			remove: 'นำออก'
		},
		butttons: {
			header: 'ตั้งค่าปุ่ม Call to Actions',
			sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้ปุ่มแสดงผลต่อผู้ใช้ได้ตรงตามความต้องการ',
			text: 'ข้อความ',
			link: 'ลิงค์',
			color: 'สี',
			outlined: 'เส้นขอบ',
			outlined_hint: 'แสดงเส้นขอบพื้นหลักตามสีที่เลือก และไม่แสดงผลพื้นหลังของปุ่ม'
		},
		settings: {
			header: 'ตั้งค่า',
			sub_header: 'ผู้ใช้สามารถตั้งค่าการแสดงผลตามตัวเลือกต่างๆ เพื่อให้การจัดการ Page ทำได้ง่ายและตามความต้องการของผู้ใช้มากขึ้น',
			percent_line: 'แสดงระดับการ Scroll',
			percent_line_hint: 'สำหรับ Page Scroll 0 - 100% ซึ่งจะสอดคล้องกับ Facebook Pixel',
			display_mobile: 'ตัวเลือกแสดงผลบน PC, Laptop หรือมือถือ',
			display_mobile_hint: '"เปิด" เพื่อตั้งค่า Page เป็นแบบกว้างสำหรับ PC, Laptop และ "ปิด" เพื่อตั้งค่าแบบเล็กสำหรับบนมือถือ',
		},
	},
};
