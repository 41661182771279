import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Typography
} from '@material-ui/core';

import {
	Skeleton
} from '@material-ui/lab';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import Bar from './Bar';
import Placeholder from './Placeholder';

import _ from 'lodash';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		outline: 'none',
	},
	detailButton: {
		padding: 0,
		marginLeft: theme.spacing(3),
		'& .MuiButton-endIcon': {
			marginLeft: 0
		},
		'& .MuiGridListTileBar-title': {
			height: 28
		}
	},
	content: {
		// display: 'flex',
		// alignItems: 'center',
		// flexDirection: 'column',
		paddingTop: theme.spacing(1),
		'& > * + *': {
			marginTop: theme.spacing(1)
		}
	},
}));

function Products({ className, ...rest }) {
	const classes = useStyles();

	const [totalPrice, setTotalPrice] = useState(0);
	const [objects, setObjects] = useState(null);

	const colors = ['#2ecc71', '#3498db', '#e67e22', '#f1c40f', '#34495e'];

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.OVERVIEW_PRODUCTS)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setTotalPrice(data.data.total_price);
							setObjects(data.data.items);
						}
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card
			{...rest}
			className={clsx(classes.root, className)}
		>
			<CardHeader
				title={(
					<Box
						display="flex"
					>
						<Typography
							variant="h5"
						>
							<strong>{locale.t('sales.overview.products.header')}</strong>
						</Typography>
						<Button
							className={classes.detailButton}
							size="small"
							color="primary"
							endIcon={<ChevronRightRoundedIcon />}
							component={RouterLink}
							to="/sales/products"
						>
							{locale.t('btn.detail')}
						</Button>
					</Box>
				)}
			/>
			<CardContent className={classes.content}>
				{!objects && (
					<>
						{_.times(5, (i) => (
							<Box marginBottom={-1.5}>
								<Skeleton key={i} animation="wave" height={48} width={`${100 / (i + 1)}%`} style={{ borderRadius: 18 }} />
							</Box>
						))}
					</>
				)}
				{(objects && objects.length === 0) && (
					<Placeholder />
				)}
				{(objects && objects.length > 0) && (
					<>
						{objects.map((item, i) => (
							<Bar
								key={item.id}
								color={colors[i]}
								data={item}
								max={objects[0].total_price}
								total={totalPrice}
							/>
						))}
					</>
				)}
			</CardContent>
		</Card>
	);
}

Products.propTypes = {
	className: PropTypes.string,
};

export default Products;
