export default {
	app_name: 'TumTum',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		ok: 'ตกลง',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม',
		submit: 'Submit',
		setting: 'ตั้งค่า',
		see_all: 'ดูทั้งหมด',
		view_report: 'ดูรายงาน',
		export_excel: 'ดาวน์โหลด Excel'
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'ยกเลิการเปลี่ยนแปลงที่เกิดขึ้น?',
		delete_data_message: 'คุณแน่ใจที่จะลบข้อมูลใช่หรือไม่?',
		remove_component_message: 'ต้องการลบข้อมูลนี้ออกใช่หรือไม่?'
	},
	alert: {
		title: {
			recommend: 'คำแนะนำ',
			warning: 'คำแนะนำ'
		}
	},
	badge: {
		status: {
			active: 'เปิดสาธารณะ',
			inactive: 'ซ่อนการมองเห็น',
		},
		member_order: {
			rejected: 'ไม่อนุมัติ',
			waiting: 'รอการตรวจสอบ',
			waiting_admin: 'ส่งต่อผู้ดูแลตรวจสอบ',
			approved: 'อนุมัติแล้ว',
		},
		app: {
			enable: 'เปิดการใช้งาน',
			disable: 'ปิดการใช้งาน',
		},
		kpi: {
			system: 'System'
		}
	},
	preloader: {
		loading: 'ระบบกำลังเตรียมความพร้อม, กรุณารอซักครู่...',
		loading_2: 'เริ่มการแสดงผลข้อมูลที่จำเป็นสำหรับคุณ',
		too_long: 'หากใช้เวลานานเกินไป, กรุณาติดต่อผู้ดูแลระบบ'
	},
	unit: {
		minute: 'นาที',
		time: 'ครั้ง',
		people: 'คน',
		point: 'คะแนน',
	},
	order: {
		status: {
			cancelled: 'ยกเลิกคำสั่งซื้อ',
			new: 'ได้รับคำสั่งซื้อ',
			paid: 'จ่ายเงินแล้ว',
			confirm_paid: 'ยืนยันการจ่ายแล้ว',
			ready_to_ship: 'พร้อมจัดส่ง',
			shipped: 'จัดส่งแล้ว',
			delivered: 'ส่งถึงปลายทางแล้ว',
		},
	},
	topbar: {
		menu: {
			wallet_member: 'บัตรตัวแทน',
			profile: 'ข้อมูลส่วนตัว',
			logout: 'ออกจากระบบ',
		},
		signout: {
			title: 'แน่ใจหรือไม่?',
			message: 'คุณกำลังดำเนินการออกจากระบบ กรุณาตรวจสอบอีกครั้งเพื่อยืนยัน'
		}
	},
};
