import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Avatar,
	Box,
	Button,
	colors,
	Grid,
	Typography,
} from '@material-ui/core';

import {
	Skeleton
} from '@material-ui/lab';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMallRounded';
import StoreMallDirectoryRoundedIcon from '@material-ui/icons/StoreMallDirectoryRounded';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';

import NumberFormat from 'react-number-format';
import DigitRoll from 'digit-roll-react';

import axios from 'src/utils/axios';
import * as WebAPI from 'src/configs/WebAPI';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		paddingTop: 0
	},
	detailButton: {
		padding: 0,
		marginLeft: theme.spacing(3),
		'& .MuiButton-endIcon': {
			marginLeft: 0
		},
		'& .MuiGridListTileBar-title': {
			height: 28
		}
	},
	mainDigitRoll: {
		border: '0',
		'& .DigitRoll__Cell, .DigitRoll__Divider': {
			width: 'auto !important',
			fontSize: 56,
		}
	},
	digitRoll: {
		border: '0',
		'& .DigitRoll__Cell, .DigitRoll__Divider': {
			fontSize: 56,
		}
	},
	avatar: {
		borderRadius: 12,
		width: 48,
		height: 48,
		fontSize: theme.spacing(4)
	},
}));

function ProjectSummary({ className, ...rest }) {
	const classes = useStyles();

	const [object, setObject] = useState(null);

	//	Functions

	useEffect(() => {
		let mounted = true;

		const fetchObject = () => {
			axios.get(WebAPI.OVERVIEW_SALES)
				.then((response) => {
					if (mounted) {
						const { data } = response;
						if (data.success) {
							setObject(data.data);
						}
					}
				});
		};
		fetchObject();

		return () => {
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Box
				width="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				<Box
					display="flex"
					flexDirection="column"
				>
					<Box
						display="flex"
					>
						<Typography
							variant="h5"
						>
							<strong>{locale.t('sales.overview.sales.total_price')}</strong>
						</Typography>
						<Button
							className={classes.detailButton}
							size="small"
							color="primary"
							endIcon={<ChevronRightRoundedIcon />}
							component={RouterLink}
							to="/sales/orders/summary"
						>
							{locale.t('btn.detail')}
						</Button>
					</Box>
					<Box
						display="flex"
						alignItems="center"
						marginTop={1}
					>
						{!object && (
							<Skeleton animation="wave" width="50%" height={84} />
						)}
						{object && (
							<DigitRoll
								className={classes.mainDigitRoll}
								num={object.total_price}
								divider=","
								width={3}
								height={4}
							/>
						)}
					</Box>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					marginTop={10}
				>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							md={6}
							xs={6}
						>
							<Box display="flex">
								<Avatar
									className={classes.avatar}
									variant="rounded"
									style={{ backgroundColor: colors.red[500] }}
								>
									<StoreMallDirectoryRoundedIcon />
								</Avatar>
								<Box marginLeft={2}>
									<Typography variant="h5">
										{locale.t('sales.overview.sales.total_orders')}
									</Typography>
									{!object && (
										<Skeleton animation="wave" width={100} height={28} />
									)}
									{object && (
										<Typography variant="h4">
											<strong>
												<NumberFormat value={object.total_orders || 0} displayType="text" thousandSeparator />
											</strong>
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							md={6}
							xs={6}
						>
							<Box display="flex">
								<Avatar
									className={classes.avatar}
									variant="rounded"
									style={{ backgroundColor: colors.green[400] }}
								>
									<LocalMallRoundedIcon />
								</Avatar>
								<Box marginLeft={2}>
									<Typography variant="h5">
										{locale.t('sales.overview.sales.total_new')}
									</Typography>
									{!object && (
										<Skeleton animation="wave" width={100} height={28} />
									)}
									{object && (
										<Typography variant="h4">
											<strong>
												<NumberFormat value={object.total_new || 0} displayType="text" thousandSeparator />
											</strong>
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							md={6}
							xs={6}
						>
							<Box display="flex">
								<Avatar
									className={classes.avatar}
									variant="rounded"
									style={{ backgroundColor: colors.blue[500] }}
								>
									<LocalOfferOutlinedIcon />
								</Avatar>
								<Box marginLeft={2}>
									<Typography variant="h5">
										{locale.t('sales.overview.sales.total_ready_to_ship')}
									</Typography>
									{!object && (
										<Skeleton animation="wave" width={100} height={28} />
									)}
									{object && (
										<Typography variant="h4">
											<strong>
												<NumberFormat value={object.total_ready_to_ship || 0} displayType="text" thousandSeparator />
											</strong>
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>
						<Grid
							item
							md={6}
							xs={6}
						>
							<Box display="flex">
								<Avatar
									className={classes.avatar}
									variant="rounded"
									style={{ backgroundColor: colors.orange[500] }}
								>
									<LocalShippingOutlinedIcon />
								</Avatar>
								<Box marginLeft={2}>
									<Typography variant="h5">
										{locale.t('sales.overview.sales.total_delivered')}
									</Typography>
									{!object && (
										<Skeleton animation="wave" width={100} height={28} />
									)}
									{object && (
										<Typography variant="h4">
											<strong>
												<NumberFormat value={object.total_delivered || 0} displayType="text" thousandSeparator />
											</strong>
										</Typography>
									)}
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}

ProjectSummary.propTypes = {
	className: PropTypes.string,
};

export default ProjectSummary;
