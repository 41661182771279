import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Typography
} from '@material-ui/core';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		overflowY: 'initial',
		minWidth: 400,
		borderRadius: 18,
	},
	floatingIcon: {
		width: 72,
		height: 72,
		borderRadius: '50%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		zIndex: 999,
		top: '-16%',
		left: '50%',
		transform: 'translate(-50%, 0)'
	},
	title: {
		marginTop: theme.spacing(4)
	},
	content: {
		paddingBottom: theme.spacing(3),
		textAlign: 'center'
	},
	actions: {
		padding: 0,
		justifyContent: 'space-evenly',
		'& button:last-child': {
			borderBottomRightRadius: 18
		},
		'& button': {
			width: '50%',
			borderRadius: 0,
			padding: theme.spacing(1.5)
		}
	},
}));

function ConfirmDialog({
	title, message, open, onClose, primaryAction, secondaryAction
}) {
	const classes = useStyles();

	//	Functions

	const handleSubmit = () => {
		onClose(true);
	};

	const handleClose = () => {
		onClose(false);
	};

	return (
		<Dialog
			classes={{ paper: classes.root }}
			open={open}
			keepMounted
			onClose={handleClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			<Box className={classes.floatingIcon}>
				<CheckCircleRoundedIcon fontSize="large" />
			</Box>
			<DialogTitle
				className={classes.title}
				id="alert-dialog-slide-title"
				disableTypography
			>
				<Typography
					variant="h4"
					align="center"
				>
					<strong>{title}</strong>
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<DialogContentText
					id="alert-dialog-slide-description"
				>
					{message}
				</DialogContentText>
			</DialogContent>
			<Divider />
			<DialogActions className={classes.actions}>
				<Button
					color="secondary"
					onClick={handleClose}
				>
					{secondaryAction || locale.t('btn.cancel')}
				</Button>
				<Button
					color="primary"
					variant="contained"
					onClick={handleSubmit}
				>
					{primaryAction || locale.t('btn.ok')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

ConfirmDialog.propTypes = {
	title: PropTypes.string,
	message: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	primaryAction: PropTypes.string,
	secondaryAction: PropTypes.string,
};

ConfirmDialog.defaultProps = {
	title: '',
	open: true,
	onClose: () => { },
};

export default ConfirmDialog;
