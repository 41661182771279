import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
	Button,
	Typography
} from '@material-ui/core';

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
		padding: theme.spacing(5, 3)
	},
}));

function Placeholder({ className, ...rest }) {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Typography variant="h4" gutterBottom>
				<strong>{locale.t('sales.overview.products.no_data.title')}</strong>
			</Typography>
			<Typography variant="body1" color="textSecondary" component="p" gutterBottom>
				{locale.t('sales.overview.products.no_data.desc')}
				<br />
				{locale.t('sales.overview.products.no_data.desc_2')}
			</Typography>
			<Button
				color="primary"
				variant="contained"
				href="/sales/products"
				endIcon={<ArrowForwardRoundedIcon />}
			>
				{locale.t('sales.overview.products.no_data.btn_add')}
			</Button>
			<Typography variant="body1" color="textSecondary" component="p" style={{ marginTop: 8 }}>
				{locale.t('sales.overview.products.no_data.remark')}
			</Typography>
		</div>
	);
}

Placeholder.propTypes = {
	className: PropTypes.string,
};

export default Placeholder;
