/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';

import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';

import PagesRoundedIcon from '@material-ui/icons/PagesRounded';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import CakeRoundedIcon from '@material-ui/icons/CakeRounded';
import WebIcon from '@material-ui/icons/Web';

import ListRoundedIcon from '@material-ui/icons/ListRounded';
import BallotRoundedIcon from '@material-ui/icons/BallotRounded';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';

import MoneyRoundedIcon from '@material-ui/icons/MoneyRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';

import LocalMallIcon from '@material-ui/icons/LocalMall';

import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';

import ViewListRoundedIcon from '@material-ui/icons/ViewListRounded';
import ApartmentRoundedIcon from '@material-ui/icons/ApartmentRounded';
import EmojiTransportationRoundedIcon from '@material-ui/icons/EmojiTransportationRounded';

import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';

import Label from 'src/components/Label';

const { REACT_APP_VERSION } = { REACT_APP_VERSION : '1.0' };//process.env;

export default {
	sales: {
		title: 'My Workspace',
		items: [
			{
				subheader: 'sales.nav.data.default',
				items: [
					{
						id: 'overview',
						title: 'sales.nav.data.overview',
						href: '/sales/overview',
						icon: DashboardRoundedIcon,
						label: () => <Label color={colors.green.A100}>{`V${REACT_APP_VERSION}`}</Label>
					},
				]
			},
			{
				subheader: 'sales.nav.content.default',
				items: [
					{
						id: 'pages',
						title: 'sales.nav.page.pages',
						href: '/sales/pages',
						icon: PagesRoundedIcon,
					},
					{
						id: 'content_articles',
						title: 'sales.nav.content.articles',
						href: '/sales/content/articles',
						icon: BookOutlinedIcon,
					},
					{
						id: 'content_promotions',
						title: 'sales.nav.content.promotions',
						href: '/sales/content/promotions',
						icon: CakeRoundedIcon,
					},
					{
						id: 'content_promotions',
						title: 'sales.nav.content.website',
						href: '/sales/website',
						icon: WebIcon,
					},
				]
			},
			{
				subheader: 'sales.nav.product.default',
				items: [
					{
						id: 'product_categories',
						title: 'sales.nav.product.categories',
						href: '/sales/product/categories',
						icon: ListRoundedIcon,
					},
					{
						id: 'product_products',
						title: 'sales.nav.product.products',
						href: '/sales/products',
						icon: BallotRoundedIcon,
					},
					{
						id: 'product_menus',
						title: 'sales.nav.product.menu.default',
						href: '/sales/menu',
						icon: MenuBookRoundedIcon,
						items: [
							{
								title: 'sales.nav.product.menu.categories',
								href: '/sales/menu/categories',
							},
							{
								title: 'sales.nav.product.menu.menus',
								href: '/sales/menus',
							}
						],
					},
				]
			},
			{
				subheader: 'sales.nav.promotion.default',
				items: [
					{
						id: 'coupons',
						title: 'sales.nav.promotion.coupons',
						href: '/sales/coupons',
						icon: MoneyRoundedIcon,
					},
					{
						id: 'coupon_histories',
						title: 'sales.nav.promotion.coupon_histories',
						href: '/sales/coupon/histories',
						icon: HistoryRoundedIcon,
					},
				]
			},
			{
				subheader: 'sales.nav.order.default',
				items: [
					{
						id: 'account_orders',
						title: 'sales.nav.order.orders',
						href: '/sales/orders/summary',
						icon: LocalMallIcon,
					},
				]
			},
			// {
			// 	subheader: 'sales.nav.apps.default',
			// 	items: [
			// 		// {
			// 		// 	id: 'apps_shipping',
			// 		// 	title: 'sales.nav.shipping.default',
			// 		// 	href: '/apps/shipping/general',
			// 		// 	icon: MotorcycleOutlinedIcon,
			// 		// 	items: [
			// 		// 		{
			// 		// 			title: 'sales.nav.shipping.general',
			// 		// 			href: '/sales/apps/shipping/general',
			// 		// 		},
			// 		// 		// {
			// 		// 		// 	title: 'sales.nav.shipping.couriers',
			// 		// 		// 	href: '/sales/apps/shipping/couriers',
			// 		// 		// }
			// 		// 	],
			// 		// },
			// 		{
			// 			id: 'apps_payment',
			// 			title: 'sales.nav.payment.default',
			// 			href: '/apps/payment/general',
			// 			icon: PaymentOutlinedIcon,
			// 			items: [
			// 				{
			// 					title: 'sales.nav.payment.general',
			// 					href: '/sales/apps/payment/general',
			// 				},
			// 				{
			// 					title: 'sales.nav.payment.methods',
			// 					href: '/sales/apps/payment/methods',
			// 				}
			// 			],
			// 		},
			// 	],
			// },
		]
	},
	stock: {
		title: 'Stock',
		items: [
			{
				subheader: 'Stock Workspace',
				items: [
					{
						id: 'overview',
						title: 'stock.nav.data.overview',
						href: '/stock/overview',
						icon: DashboardRoundedIcon,
						label: () => <Label color={colors.green.A100}>{`V${REACT_APP_VERSION}`}</Label>
					},
				]
			},
			{
				subheader: 'stock.nav.product.default',
				items: [
					{
						id: 'product_categories',
						title: 'stock.nav.product.attributes',
						href: '/stock/product/attributes',
						icon: ViewListRoundedIcon,
					},
					{
						id: 'product_categories',
						title: 'stock.nav.product.products',
						href: '/stock/products',
						icon: BallotRoundedIcon,
					},
				]
			},
			{
				subheader: 'stock.nav.inventory.default',
				items: [
					{
						id: 'product_categories',
						title: 'stock.nav.inventory.imports',
						href: '/stock/inventory/imports',
						icon: ApartmentRoundedIcon,
					},
					{
						id: 'product_products',
						title: 'stock.nav.inventory.movements',
						href: '/stock/inventory/movements',
						icon: EmojiTransportationRoundedIcon,
					},
				]
			},
		]
	},
	crm: {
		title: 'CRM',
		items: [
			{
				subheader: 'CRM Workspace',
				items: [
					{
						id: 'overview',
						title: 'crm.nav.data.overview',
						href: '/crm/overview',
						icon: DashboardRoundedIcon,
						label: () => <Label color={colors.green.A100}>{`V${REACT_APP_VERSION}`}</Label>
					}
				],
			},
			{
				subheader: 'crm.nav.account.default',
				items: [
					{
						id: 'product_categories',
						title: 'crm.nav.account.accounts',
						href: '/crm/accounts',
						icon: FaceRoundedIcon,
					},
					// {
					// 	id: 'product_products',
					// 	title: 'crm.nav.account.products',
					// 	href: '/crm/products',
					// 	icon: BallotRoundedIcon,
					// },
				]
			},
			// {
			// 	subheader: 'ติดต่อลูกค้า',
			// 	items: [
			// 		{
			// 			id: 'product_categories',
			// 			title: 'crm.nav.broadcast.sms',
			// 			href: '/crm/broadcast/sms',
			// 			icon: ChatRoundedIcon,
			// 		},
			// 	]
			// },
			// {
			// 	subheader: 'รายงาน',
			// 	items: [
			// 		{
			// 			id: 'product_categories',
			// 			title: 'ยอดสั่งซื้อมากที่สุด',
			// 			href: '/ฟหกฟหกฟproduct/categories',
			// 			icon: ListRoundedIcon,
			// 		},
			// 		{
			// 			id: 'product_products',
			// 			title: 'รายงานอื่นๆ',
			// 			href: '/products',
			// 			icon: BallotRoundedIcon,
			// 		},
			// 	]
			// },
		]
	},
};
