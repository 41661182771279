import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	createMuiTheme,
	LinearProgress,
	Typography
} from '@material-ui/core';

import { baseTheme } from 'src/theme';

import Preloader from './Preloader';

import AuthGuard from 'src/components/AuthGuard';

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		'@media all and (-ms-high-contrast:none)': {
			height: 0 // IE11 fix
		}
	},
	content: {
		// paddingTop: 64,
		paddingTop: 0,
		flexGrow: 1,
		maxWidth: '100%',
		overflowX: 'hidden',
		[theme.breakpoints.down('md')]: {
			paddingTop: 56
		}
	},
	noNav: {
		paddingLeft: '0 !important'
	},
	preLoader: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	footer: {
		marginTop: theme.spacing(5),
		padding: theme.spacing(2, 3),
		backgroundColor: theme.palette.background.paper
	}
}));

function Full({ route }) {
	const classes = useStyles();
	const session = useSelector((state) => state.session);

	const attrs = {
		...baseTheme,
		palette: {
			...baseTheme.palette,
		}
	};
	if (session.website.theme) {
		attrs.palette = {
			...attrs.palette,
			...session.website.theme.palette
		};
	}
	const theme = createMuiTheme(attrs);

	return (
		<>
			<ThemeProvider theme={theme}>
				<div className={classes.container}>
					<div className={classes.content}>
						<Suspense fallback={(
							<>
								<LinearProgress color="secondary" />
								<div className={classes.preLoader}>
									<Preloader />
								</div>
							</>
						)}
						>
							{renderRoutes(route.routes)}
						</Suspense>
						<div className={classes.footer}>
							<Typography
								align="center"
								variant="subtitle2"
							>
								© 2022, The Butler Tech, All Right Reserved
							</Typography>
						</div>
					</div>
				</div>
				{/* <AuthGuard roles={['GUEST', 'MEMBER']} /> */}
			</ThemeProvider>
		</>
	);
}

Full.propTypes = {
	route: PropTypes.object
};

export default Full;
