import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	AppBar,
	Avatar,
	Badge,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	IconButton,
	Toolbar,
	Typography,
	Hidden,
	colors
} from '@material-ui/core';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import MenuIcon from '@material-ui/icons/Menu';

import NotificationsPopover from 'src/components/NotificationsPopover';
import AvatarMenuPopover from 'src/components/AvatarMenuPopover';

import InfoSnackbar from 'src/components/Snackbars/InfoSnackbar';

import moment from 'src/utils/moment';

import { logout } from 'src/actions';

import getInitials from 'src/utils/getInitials';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: 'none',
		'-webkit-app-region': 'drag',
	},
	flexGrow: {
		flexGrow: 1
	},
	label: {
		marginLeft: theme.spacing(1)
	},
	search: {
		backgroundColor: 'rgba(255,255,255, 0.1)',
		borderRadius: 4,
		flexBasis: 300,
		height: 36,
		padding: theme.spacing(0, 2),
		display: 'flex',
		alignItems: 'center'
	},
	searchIcon: {
		marginRight: theme.spacing(2),
		color: 'inherit'
	},
	searchInput: {
		flexGrow: 1,
		color: 'inherit',
		'& input::placeholder': {
			opacity: 1,
			color: 'inherit'
		}
	},
	searchPopper: {
		zIndex: theme.zIndex.appBar + 100
	},
	searchPopperContent: {
		marginTop: theme.spacing(1)
	},
	trialButton: {
		marginLeft: theme.spacing(2),
		color: theme.palette.common.white,
		backgroundColor: '#541b66',
		'&:hover': {
			backgroundColor: colors.green[900]
		}
	},
	trialIcon: {
		marginRight: theme.spacing(1)
	},
	menuButton: {
		marginRight: theme.spacing(1)
	},
	chatButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsButton: {
		marginLeft: theme.spacing(1)
	},
	notificationsBadge: {
		backgroundColor: colors.green[200]
	},
	avatarMenu: {
		'& h5': {
			color: theme.palette.primary.contrastText,
			marginLeft: theme.spacing(1),
			textTransform: 'capitalize'
		}
	},
	avatar: {
		width: theme.spacing(3.5),
		height: theme.spacing(3.5),
		marginLeft: theme.spacing(1)
	},
	divider: {
		margin: theme.spacing(2.5, 1),
		backgroundColor: '#AAAAAA'
	},
	logoutButton: {
		marginLeft: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			marginLeft: 0,
		}
	},
	logoutIcon: {
		marginRight: theme.spacing(1)
	}
}));

function TopBar({
	onOpenNavBarMobile,
	className,
	...rest
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const session = useSelector((state) => state.session);
	const history = useHistory();

	const notificationsRef = useRef(null);
	const [notifications, setNotifications] = useState([]);
	const [openNotifications, setOpenNotifications] = useState(false);

	const avatarMenuRef = useRef(null);
	const [openAvatarMenu, setOpenAvatarMenu] = useState(false);

	const [openDialog, setOpenDialog] = useState(false);

	const snackbarMessage = 'Notification'; // locale.t('auth.login.login_fail');
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const handleLogout = () => {
		dispatch(logout());

		//	Clear session timer first
		const { localStorage } = window;
		localStorage.setItem('session-timer', null);
		setTimeout(() => {
			window.location = '/auth/login';
		}, 300);
	};

	const handleNotificationsOpen = () => {
		setOpenNotifications(true);
	};

	const handleNotificationsClose = () => {
		setOpenNotifications(false);
	};

	const handleItemClick = () => {
		setOpenNotifications(false);
		history.push('/orders');
	};

	const handleAvatarMenuOpen = () => {
		setOpenAvatarMenu(true);
	};

	const handleAvatarMenuClose = () => {
		setOpenAvatarMenu(false);
	};

	const handleAvatarItemClick = (code) => {
		if (code === 'wallet/member') {
			const { origin } = window.location;
			const shareUrl = `${origin}/wallet/member/${session.user.no}`;
			window.open(shareUrl, '_blank');
		} else if (code === 'profile') {
			history.push('/settings/profile');
		} else if (code === 'logout') {
			setOpenDialog(true);
		}
		setOpenAvatarMenu(false);
	};

	const handleClose = () => {
		setOpenDialog(false);
	};

	const handleSnackbarClose = () => {
		setOpenSnackbar(false);
	};

	useEffect(() => {
		if (session.user.role === 'GUEST') {
			const items = [
				{
					id: 1,
					title: 'Welcome to our Platform !!',
					type: 'feature',
					created_date: moment(session.user.register_date)
				}
			];
			setNotifications(items);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AppBar
			{...rest}
			className={clsx(classes.root, className)}
			// color="transparent"
			color="primary"
		>
			<Toolbar>
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						color="inherit"
						onClick={onOpenNavBarMobile}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				{/* <RouterLink to="/">
					<img
						alt="Logo"
						src={session.website.logo}
						// src="/images/logos/logo--white.png"
						style={{ height: 44 }}
					/>
				</RouterLink> */}
				<div className={classes.flexGrow} />
				{/* <Hidden mdDown> */}
				{/* <IconButton
					className={classes.notificationsButton}
					color="inherit"
					onClick={handleNotificationsOpen}
					ref={notificationsRef}
				>
					<Badge
						badgeContent={session.user.role === 'GUEST' ? 1 : 1}
						classes={{ badge: classes.notificationsBadge }}
					>
						<NotificationsNoneRoundedIcon />
					</Badge>
				</IconButton>
				<Divider className={classes.divider} orientation="vertical" flexItem /> */}
				<Button
					className={classes.avatarMenu}
					aria-label="menu"
					onClick={handleAvatarMenuOpen}
					ref={avatarMenuRef}
				>
					<Avatar
						alt={session.user.firstname}
						className={classes.avatar}
						src={session.user.avatar}
					>
						{getInitials(session.user.firstname)}
					</Avatar>
					<Hidden mdDown>
						<Typography variant="h5">Hi,</Typography>
						<Typography variant="h5"><strong>{session.user.firstname}</strong></Typography>
					</Hidden>
				</Button>
				{/* <Button
					className={classes.logoutButton}
					color="inherit"
					onClick={handleConfirmLogout}
				>
					<InputIcon className={classes.logoutIcon} />
					<Hidden mdDown>
						{locale.t('btn.signout')}
					</Hidden>
				</Button> */}
				{/* </Hidden> */}
			</Toolbar>
			<NotificationsPopover
				anchorEl={notificationsRef.current}
				notifications={notifications}
				onClose={handleNotificationsClose}
				onItemClick={handleItemClick}
				open={openNotifications}
			/>
			<AvatarMenuPopover
				anchorEl={avatarMenuRef.current}
				onClose={handleAvatarMenuClose}
				onItemClick={handleAvatarItemClick}
				open={openAvatarMenu}
			/>
			<Dialog
				open={openDialog}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{locale.t('topbar.signout.title')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{locale.t('topbar.signout.message')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{locale.t('btn.cancel')}
					</Button>
					<Button onClick={handleLogout} color="primary">
						{locale.t('btn.signout')}
					</Button>
				</DialogActions>
			</Dialog>
			<InfoSnackbar
				message={snackbarMessage}
				onClose={handleSnackbarClose}
				open={openSnackbar}
			/>
		</AppBar>
	);
}

TopBar.propTypes = {
	className: PropTypes.string,
	onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
