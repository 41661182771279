export default {
	nav: {
		data: {
			default: 'My Workspace',
			overview: 'Dashboard'
		},
		content: {
			default: 'Content',
			articles: 'บทความ',
			promotions: 'โปรโมชัน',
			website: 'ตั้งค่าเว็บไซต์'
		},
		product: {
			default: 'สินค้า',
			categories: 'หมวดหมู่สินค้า',
			products: 'รายการสินค้า',
			menu: {
				default: 'เมนูหน้าร้าน',
				categories: 'หมวดหมู่',
				menus: 'รายการ',
			}
		},
		page: {
			default: 'สนับสนุนงานขาย',
			pages: 'เว็บเพจ',
		},
		promotion: {
			default: 'คูปอง',
			coupons: 'รายการคูปอง',
			coupon_histories: 'ประวัติการใช้คูปอง'
		},
		order: {
			default: 'คำสั่งซื้อ',
			orders: 'รายการคำสั่งซื้อ',
			waiting: 'รอการจัดส่ง',
			tracking: 'ติดตามการจัดส่ง',
		},
		settings: {
			default: 'ตั้งค่า',
			general: 'ข้อมูลทั่วไป',
			introduction: 'วิดีโอธุรกิจ',
			password: 'เปลี่ยนรหัสผ่าน',
			upgrade: 'อัพเกรดสถานะ',
		},
		apps: {
			default: 'เชื่อมต่อบริการอื่นๆ',
			liff: 'Line Share',
			page: 'เว็บเพจ',
			facebook_group: 'กลุ่มเฟซบุ๊ก'
		},
		shipping: {
			default: 'ระบบขนส่ง',
			general: 'ตั้งค่าทั่วไป',
			couriers: 'รายการขนส่ง'
		},
		payment: {
			default: 'ระบบจ่ายเงินออนไลน์',
			general: 'ตั้งค่าทั่วไป',
			methods: 'วิธีการจ่ายเงิน'
		},
		website: {
			default: 'ตั้งค่าเว็บไซต์หลัก',
			general: 'ข้อมูลทั่วไป',
			analytics: 'Analytics'
		},
	},
	overview: {
		title: 'Sales Dashboard',
		header: 'Sales Dashboard',
		sales: {
			total_price: 'ยอดขายทั้งหมด',
			total_orders: 'คำสั่งซื้อทั้งหมด',
			total_new: 'คำสั่งซื้อใหม่',
			total_ready_to_ship: 'รอจัดส่ง',
			total_delivered: 'จัดส่งแล้ว',
		},
		products: {
			header: 'สินค้าขายดี',
			no_data: {
				title: 'ไม่พบรายการสินค้า',
				desc: 'คุณสร้างสามารถสร้างสินค้าของคุณเองได้',
				desc_2: 'ผ่านตัวช่วยสร้างสินค้าที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
				btn_add: 'ไปหน้าจัดการสินค้า',
				remark: 'เพื่อดำเนินการต่อ',
			},
		},
		activities: {
			header: 'กิจกรรมการซื้อของลูกค้าที่เกิดขึ้น',
			sub_header: 'ตั้งแต่วันที่',
			tooltip: '<strong>{{count}} รายการ</strong> ของวันที่ {{date}}',
			total_count: '{{count}} รายการ ในปี {{year}}'
		},
		knowledges: {
			header: 'แดชบอร์ดใหม่ !',
			description: 'ส่วนช่วยสนับสนุนงานขาย พร้อมเครื่องมือต่างๆ ที่จะช่วยให้งานขายง่ายขึ้น',
		}
	},
	content: {
		articles: {
			title: 'รายการบทความ',
			header: 'รายการบทความ',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อบทความ',
			result_title: 'รายการบทความทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'แก้ไขล่าสุด',
			item_col3: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการบทความ',
			delete_success: 'ลบข้อมูลบทความเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลบทความ',
				save_fail: 'ไม่สามารถสร้างข้อมูลบทความได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลบทความ',
				form_header: 'แก้ไขบทความ',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับบทความในเว็บไซต์ ซึ่งจะแสดงผลต่อเมื่อมีการเปิดเผยต่อสาธารณะ',
				title_: 'ชื่อบทความ',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				alias: 'ลิงก์บทความ',
				alias_hint: 'จำกัดตัวอักษร %{count}/50',
				description: 'รายละเอียดย่อ',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				content: 'เนื้อหาบทความ',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงบทความบนเว็บไซต์',
				save_success: 'บันทึกรายการข้อมูลบทความใหม่เรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อบทความ',
					alias: '^กรุณากรอกลิงก์บทความ',
				},
				image: {
					header: 'อัพโหลดภาพปก',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 350x240'
				},
				cover_image: {
					header: 'อัพโหลดภาพรายละเอียด',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 1920x1280'
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงบทความที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลบทความ'
				}
			}
		},
		promotions: {
			title: 'รายการโปรโมชัน',
			header: 'รายการโปรโมชัน',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อโปรโมชัน',
			result_title: 'รายการโปรโมชันทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'แก้ไขล่าสุด',
			item_col3: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการโปรโมชัน',
			delete_success: 'ลบข้อมูลโปรโมชันเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลโปรโมชัน',
				save_fail: 'ไม่สามารถสร้างข้อมูลโปรโมชันได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลโปรโมชัน',
				form_header: 'แก้ไขโปรโมชัน',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับโปรโมชันในเว็บไซต์ ซึ่งจะแสดงผลต่อเมื่อมีการเปิดเผยต่อสาธารณะ',
				title_: 'ชื่อโปรโมชัน',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				alias: 'ลิงก์บทความ',
				alias_hint: 'จำกัดตัวอักษร %{count}/50',
				description: 'รายละเอียดย่อ',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				content: 'เนื้อหาโปรโมชัน',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงโปรไมชันบนเว็บไซต์',
				save_success: 'บันทึกรายการข้อมูลโปรโมชันใหม่เรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อโปรโมชัน',
					alias: '^กรุณากรอกลิงก์บทความ',
				},
				image: {
					header: 'อัพโหลดภาพปก',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 1920x1280'
				},
				cover_image: {
					header: 'อัพโหลดภาพรายละเอียด',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 1920x1280'
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงโปรโมชันที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลโปรโมชัน'
				}
			}
		},
	},
	product: {
		categories: {
			title: 'รายการหมวดหมู่',
			header: 'รายการหมวดหมู่',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อหมวดหมู่',
			result_title: 'รายการหมวดหมู่ทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'จำนวนสินค้า',
			item_col3: 'แก้ไขล่าสุด',
			item_col4: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
			delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลหมวดหมู่',
				save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลหมวดหมู่',
				form_header: 'แก้ไขหมวดหมู่',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อหมวดหมู่',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				alias: 'ลิงก์หมวดหมู่',
				alias_hint: 'จำกัดตัวอักษร %{count}/50',
				description: 'รายละเอียด',
				description_hint: 'จำกัดตัวอักษร %{count}/255',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
				image: {
					header: 'อัพโหลดภาพแบนเนอร์',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 1920x1280'
				},
				validator: {
					name: '^กรุณากรอกชื่อหมวดหมู่',
					alias: '^กรุณากรอกลิงก์หมวดหมู่',
					description: '^กรุณากรอกรายละเอียด',
				},
				related_list: {
					header: 'สินค้าที่เกี่ยวข้อง',
					btn_see_all: 'ดูสินค้าทั้งหมด',
					no_data: 'ไม่พบข้อมูลรายการสินค้าในหมวดหมู่นี้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลหมวดหมู่'
				}
			}
		},
		products: {
			title: 'รายการสินค้า',
			header: 'รายการสินค้า',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อสินค้า',
			result_title: 'รายการสินค้าทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'หมวดหมู่',
			item_col3: 'จำนวนสินค้า',
			item_col4: 'แก้ไขล่าสุด',
			item_col5: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการสินค้า',
			delete_success: 'ลบข้อมูลสินค้าเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลสินค้า',
				save_fail: 'ไม่สามารถสร้างข้อมูลสินค้าได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการสินค้า',
				header: 'จัดการข้อมูลสินค้า',
				form_header: 'แก้ไขสินค้า',
				form_sub_header: 'รายละเอียดสินค้าสำหรับเว็บไซต์สินค้า',
				category_id: 'หมวดหมู่สินค้า',
				category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				type: 'ประเภท',
				type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				name: 'ชื่อสินค้า',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				alias: 'ลิงก์สินค้า',
				alias_hint: 'จำกัดตัวอักษร %{count}/50',
				short_desc: 'รายละเอียดย่อ',
				short_desc_hint: 'จำกัดตัวอักษร %{count}/100',
				price: 'ราคาเต็ม',
				sale_price: 'ราคาขาย',
				virtual_stock: 'Virtual Stock',
				virtual_stock_hint: 'สร้างคลังสินค้าเสมือนของคุณเอง โดยการกำหนดจำนวนสินค้าในสต็อก',
				life_time: 'อายุสินค้า',
				life_time_hint: 'กรอก 0 หากไม่ต้องการระบุวันหมดอายุ',
				stock: 'จำนวนสินค้าใน Stock',
				notify_stock: 'แจ้งเตือนจำนวนสินค้าใน Stock ขั้นต่ำ',
				has_attribute: 'ตัวเลือกลักษณะสินค้า',
				has_attribute_hint: 'คุณได้ทำการสร้างคุณลักษณะสินค้าแล้ว สามารถดำเนินแก้ไขลักษณะสินค้าเพิ่มเติมได้ผ่าน Stock Workspace โดยคลิก',
				btn_go_to_stock: 'ไปที่ Stock Workspace',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงสินค้านี้ต่อผู้ใช้ระบบ โดยจะมีผลภายใน 24 ชม.',
				variation_name: 'ชื่อตัวเลือกลักษณะสินค้า',
				variation_name_hint: 'จำกัดตัวอักษร %{count}/50',
				description: 'รายละเอียดสินค้า',
				save_success: 'บันทึกรายการข้อมูลสินค้าเรียบร้อยแล้ว',
				validator: {
					name: '^กรุณากรอกชื่อสินค้า',
					alias: '^กรุณากรอกลิงก์สินค้า',
					short_desc: '^กรุณากรอกรายละเอียดย่อ',
					price: '^กรุณากรอกราคาเต็ม',
					sale_price: '^กรุณากรอกราคาขาย',
					life_time: '^กรุณากรอกอายุสินค้า',
					stock: '^กรุณากรอกจำนวนสินค้า',
					notify_stock: '^กรุณากรอกแจ้งเตือนจำนวนสินค้า',
					variation_name: '^กรุณากรอกชื่อตัวเลือกลักษณะสินค้า',
				},
				images: {
					header: 'ภาพประกอบสินค้า',
					sub_header: 'ความสูงภาพแนะนำ 800px และภาพที่ 1 จะกลายเป็นภาพหลักของสินค้า',
				},
				variations: {
					header: 'กำหนดคุณลักษณะรายการสินค้า',
					sku: 'SKU',
					stock: 'จำนวน',
					name: 'ลักษณะ',
					price: 'ราคาต่อชิ้น',
					sale_price: 'ราคาขาย',
					no_data: 'กรุณาเพิ่มรายการคุณลักษณะรายการสินค้า',
				},
				features: {
					header: 'รายละเอียดเพิ่มเติม',
					origin: 'Origin',
					process: 'Process',
					altitude: 'Altitude',
					variety: 'Variety',
					roast_level: 'Roast level',
					taste_note: 'Taste note',
					validator: {
						origin: '^กรุณากรอก Origin',
						process: '^กรุณากรอก Process',
						altitude: '^กรุณากรอก Altitude',
						variety: '^กรุณากรอก Variety',
						roast_level: '^กรุณากรอก Roast level',
						taste_note: '^กรุณากรอก Taste note',
					},
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลสินค้า'
				}
			}
		},
		stock: {
			inventories: {
				title: 'รายการสินค้า',
				header: 'รายการสินค้า',
				search_placeholder: 'ชื่อสินค้า',
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'จำนวน',
				item_col4: 'อัพเดตล่าสุด',
				no_data: 'ไม่พบรายการสินค้าในสต็อก'
			},
			movements: {
				title: 'รายการความเคลื่อนไหวสินค้า',
				header: 'รายการความเคลื่อนไหวสินค้า',
				search: {
					search_placeholder: 'ค้นหาชื่อสินค้า',
					start_date: 'วันเริ่มต้น',
					end_date: 'ถึง'
				},
				result_title: 'รายการสินค้าทั้งหมด',
				item_col: 'ชื่อสินค้า',
				item_col2: 'หมวดหมู่',
				item_col3: 'ต้นทาง/ปลายทาง',
				item_col3_default: 'บริษัท',
				item_col4: 'จำนวน',
				item_col5: 'เกิดขึ้นเมื่อ',
				no_data: 'ไม่พบรายการความเคลื่อนไหวสินค้า'
			},
		},
	},
	menu: {
		categories: {
			title: 'รายการหมวดหมู่',
			header: 'รายการหมวดหมู่',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อหมวดหมู่',
			result_title: 'รายการหมวดหมู่ทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'จำนวนสินค้า',
			item_col3: 'แก้ไขล่าสุด',
			item_col4: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการหมวดหมู่',
			delete_success: 'ลบข้อมูลหมวดหมู่เรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลหมวดหมู่',
				save_fail: 'ไม่สามารถสร้างข้อมูลหมวดหมู่ได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการหมวดหมู่',
				header: 'จัดการข้อมูลหมวดหมู่',
				form_header: 'แก้ไขหมวดหมู่',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				name: 'ชื่อหมวดหมู่',
				name_hint: 'จำกัดตัวอักษร %{count}/100',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงหมวดหมู่ต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลหมวดหมู่ใหม่เรียบร้อยแล้ว',
				image: {
					header: 'อัพโหลดภาพไอคอน',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำ 96x96'
				},
				validator: {
					name: '^กรุณากรอกชื่อหมวดหมู่',
				},
				related_list: {
					header: 'เมนูที่เกี่ยวข้อง',
					btn_see_all: 'ดูเมนูทั้งหมด',
					no_data: 'ไม่พบข้อมูลรายการเมนูในหมวดหมู่นี้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลหมวดหมู่'
				}
			}
		},
		menus: {
			title: 'รายการเมนู',
			header: 'รายการเมนู',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อเมนู',
			result_title: 'รายการเมนูทั้งหมด',
			item_col: 'สร้างโดย',
			item_col2: 'แก้ไขล่าสุด',
			item_col3: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการเมนู',
			delete_success: 'ลบข้อมูลเมนูเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลเมนู',
				save_fail: 'ไม่สามารถสร้างข้อมูลเมนูได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการเมนู',
				header: 'จัดการข้อมูลเมนู',
				form_header: 'แก้ไขเมนู',
				form_sub_header: 'ข้อมูลนี้จะมีความเกี่ยวข้องกับสินค้าในเว็บไซต์สินค้า ซึ่งจะแสดงผลต่อสมาชิกเมื่อมีการเปิดเผยต่อสาธารณะ',
				category_id: 'หมวดหมู่',
				category_id_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				title_: 'ชื่อเมนู',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				description: 'รายละเอียด',
				description_hint: 'จำกัดตัวอักษร %{count}/250',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงเมนูต่อสมาชิกที่ใช้ระบบ',
				save_success: 'บันทึกรายการข้อมูลเมนูใหม่เรียบร้อยแล้ว',
				image: {
					header: 'อัพโหลดภาพ',
					hint: 'คลิกหรือลากไฟล์มาตรงนี้',
					info: 'ขนาดภาพแนะนำความสูง 480px'
				},
				validator: {
					title: '^กรุณากรอกชื่อเมนู',
					description: '^กรุณากรอกรายละเอียด',
				},
				related_list: {
					header: 'สินค้าที่เกี่ยวข้อง',
					btn_see_all: 'ดูสินค้าทั้งหมด',
					no_data: 'ไม่พบข้อมูลรายการสินค้าในเมนูนี้',
				},
				other_actions: {
					header: 'Other Actions',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลเมนู'
				}
			}
		},
	},
	promotion: {
		coupons: {
			title: 'รายการคูปอง',
			header: 'รายการคูปอง',
			action: 'เพิ่มรายการ',
			search_placeholder: 'ค้นหาชื่อคูปอง',
			result_title: 'รายการคูปองทั้งหมด',
			item_col: 'สร้างโดย',
			item_col_type_percent: 'ส่วนลดเปอร์เซ็นต์',
			item_col_type_cash: 'ส่วนลดเงินสด',
			item_col2: 'จำนวนคงเหลือ',
			item_col3: 'แก้ไขล่าสุด',
			item_col4: 'สถานะแสดงผล',
			no_data: 'ไม่พบข้อมูลรายการคูปอง',
			delete_success: 'ลบข้อมูลคูปองเรียบร้อยแล้ว',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
			create: {
				header: 'สร้างข้อมูลคูปอง',
				header_2: 'แก้ไขข้อมูลคูปอง',
				save_fail: 'ไม่สามารถสร้างข้อมูลคูปองได้ กรุณาติดต่อผู้ดูแลระบบ'
			},
			detail: {
				title: 'แก้ไขรายการคูปอง',
				header: 'จัดการข้อมูลคูปอง',
				form_header: 'รายละเอียดคูปอง',
				form_sub_header: 'รายละเอียดคูปองส่วนลดสำหรับสินค้าผ่านเว็บไซต์',
				title_: 'ชื่อคูปอง',
				title_hint: 'จำกัดตัวอักษร %{count}/100',
				type: 'ประเภทส่วนลด',
				type_hint: 'เลือกได้ 1 ตัวเลือกเท่านั้น',
				type_percent: 'เปอร์เซ็นต์',
				type_cash: 'เงินสด',
				discount_percent: 'ส่วนลดเปอร์เซ็นต์',
				discount_price: 'ส่วนลดเงินสด',
				min_price: 'จำนวนเงินขั้นต่ำ',
				code: 'โค้ดส่วนลด',
				code_hint: 'จำกัดตัวอักษร %{count}/10',
				auto_generate: 'สร้างโค้ดอัตโนมัติ',
				auto_generate_hint: 'เปิด-ปิด เพื่อสร้างคูปองอัตโนมัติ ตามจำนวนที่ต้องการ',
				auto_generate_qty: 'จำนวนโค้ดอัตโนมัติ',
				qty: 'จำนวนคูปอง',
				member_limit: 'จำนวนที่สมาชิกใช้ได้',
				start_date: 'เริ่มคูปองตั้งแต่',
				end_date: 'จนถึง',
				is_enabled: 'เปิดเผยต่อสาธารณะ',
				is_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าคูปองสำหรับสินค้าทั้งหมดบนเว็บเพจ',
				save_success: 'บันทึกรายการข้อมูลคูปองเรียบร้อยแล้ว',
				validator: {
					title: '^กรุณากรอกชื่อคูปอง',
					code: '^กรุณากรอกโค้ดส่วนลด',
					min_price: '^กรุณากรอกจำนวนเงินขั้นต่ำ',
					qty: '^กรุณากรอกจำนวนคูปอง',
					start_date: '^กรุณากรอกเวลาเริ่มต้น',
					end_date: '^กรุณากรอกเวลาสิ้นสุด',
				},
				other_actions: {
					header: 'ดำเนินการอื่นๆ',
					delete_hint: 'การลบข้อมูลนี้จะมีผลกับการแสดงข้อมูลสินค้าที่เกี่ยวข้อง ซึ่งจะทำให้ไม่สามารถนำข้อมูลนี้กลับมาใช้งานได้ หากเกิดความผิดพลาดกรุณาติดต่อผู้ดูแลระบบ',
					btn_delete: 'ลบข้อมูลคูปอง'
				}
			}
		},
		coupon_histories: {
			title: 'รายการประวัติการใช้คูปอง',
			header: 'รายการประวัติการใช้คูปอง',
			search_placeholder: 'ค้นหาชื่อคูปอง',
			result_title: 'รายการประวัติการใช้คูปองทั้งหมด',
			item_col: 'จำนวนส่วนลด',
			item_col2: 'วันที่ใช้คูปอง',
			no_data: 'ไม่พบข้อมูลรายการคูปอง',
			sorting: {
				item: 'ชื่อ ก-ฮ',
				item2: 'ล่าสุด',
			},
		},
	},
	orders: {
		title: 'รายการคำสั่งซื้อจากลูกค้า',
		header: 'รายการคำสั่งซื้อจากลูกค้า',
		search: {
			search_placeholder: 'ค้นหาหมายเลขคำสั่งซื้อ, ชื่อลูกค้า',
			status: 'สถานะ',
			all_status: 'ทั้งหมด',
			start_date: 'วันเริ่มต้น',
			end_date: 'ถึง'
		},
		result_title: 'รายการคำสั่งซื้อทั้งหมด',
		item_col: 'จาก',
		item_col_cod: 'COD',
		item_col2: 'วันที่',
		no_data: 'ไม่พบข้อมูลรายการคำสั่งซื้อจากลูกค้า',
		sorting: {
			item: 'หมายเลขคำสั่งซื้อ',
			item2: 'ล่าสุด',
		},
		save_status_success: 'บันทึกสถานะสั่งซื้อเรียบร้อยแล้ว',
		status: {
			cancelled: 'ยกเลิกคำสั่งซื้อ',
			new: 'ได้รับคำสั่งซื้อ',
			paid: 'จ่ายเงินแล้ว',
			confirm_paid: 'ยืนยันการจ่ายแล้ว',
			ready_to_ship: 'พร้อมจัดส่ง',
			shipped: 'จัดส่งแล้ว',
			delivered: 'ส่งถึงปลายทางแล้ว',
			cancel: 'ยกเลิก',
			return: 'ส่งสินค้าคืน',
		},
		detail: {
			title: 'คำสั่งซื้อจากลูกค้า',
			header: 'จัดการข้อมูลคำสั่งซื้อจากลูกค้า',
			status_action: 'บันทึกสถานะ',
			shipping_action: 'เลือกขนส่งที่จะใช้บริการ',
			form_header: 'หมายเลขคำสั่งซื้อ',
			form_sub_header: 'รายการสินค้าและรายละเอียดที่อยู่จัดส่ง',
			created_date: 'จัดทำวันที่',
			results: {
				item_col0: 'ลำดับ',
				item_col: 'ชื่อสินค้า',
				item_col2: 'ราคาต่อชิ้น',
				item_col3: 'จำนวน',
				item_col4: 'ยอดรวม',
				unit_price: 'ราคา',
				qty: 'จำนวน',
				total: 'ยอดรวม',
				shipping_price: 'ค่าจัดส่ง',
				discount_price: 'ส่วนลด',
				total_price: 'ราคาสินค้า',
				net_price: 'ยอดที่ได้รับชำระ',
			},
			payment: {
				header: 'รายละเอียดการชำระเงิน',
				cod: 'ลูกค้าเลือกชำระปลายทาง (COD)',
				no_data: 'ยังไม่ได้รับแจ้งการจ่ายเงิน',
				action: 'ยืนยันรับชำระเงิน',
				confirm_message: 'ยืนยันการชำระเงินถูกต้องตามคำสั่งซื้อ?',
				save_success: 'ยืนยันการชำระเงินเรียบร้อยแล้ว'
			},
			parcels: {
				header: 'รายการจัดเตรียมพัสดุ',
				sub_header: 'หากมีการปรับเปลี่ยนข้อมูลพัสดุ กรุณาบันทึกข้อมูล เพื่อแสดงรายการขนส่งตามจำนวนพัสดุ',
				cod_hint: '***ค่าเก็บเงินปลายทางอยู่ในช่วง 100 - 50,000 บาท',
				is_parcel_ready: 'เตรียมพัสดุพร้อมแล้วสำหรับการจัดส่ง',
				confirm_ready: 'กรุณายืนยันการเตรียมพัสดุก่อนการส่ง ซึ่งจะทำให้คุณจะไม่สามารถแก้ไขรายการพัสดุและตรวจสอบขนส่งได้',
				no_data: 'กรุณาเพิ่มรายการพัสดุที่บรรจุสินค้า',
				box: 'ขนาดกล่อง / ซอง',
				parcel_weight: 'น้ำหนัก',
				parcel_width: 'กว้าง',
				parcel_length: 'ยาว',
				parcel_height: 'สูง',
				g: 'กรัม',
				cm: 'ซม',
				cod_amount: 'เก็บเงินปลายทาง',
			},
			shipping: {
				header: 'ตรวจสอบขนส่ง',
				courier_id: 'เลือกขนส่ง',
				courier_parcel: 'ชิ้นที่ %{index} - %{remark}',
				courier_parcel_optional: 'สามารถส่งได้',
				courier_parcel_hint: '***ตัวอักษรสีแดงแทนพัสดุที่ขนส่งไม่สามารถส่งได้ กรุณาตรวจสอบพัสดุและเลือกขนส่งใหม่อีกครั้ง',
			},
			booking: {
				header: 'รายการจัดส่งพัสดุ',
				courier: 'ดำเนินการขนส่งโดย : %{courier}',
				date: 'อัพเดตสถานะเมื่อวันที่ : %{time}',
				parcel: 'พัสดุชิ้นที่ #%{index}',
				description: '%{title}, น้ำหนัก %{weight} กรัม',
				cod: 'เก็บเงินปลายทาง',
			},
			printing: {
				title: 'รายละเอียดใบสั่งซื้อ #%{no}',
				header: 'รายละเอียดใบสั่งซื้อ #%{no}',
				action: 'สั่งพิมพ์ใบสั่งซื้อ',
				order_no: 'หมายเลขคำสั่งซื้อ #%{no}',
				email: 'อีเมล',
				phone: 'เบอร์โทรศัพท์',
				created_date: 'วันที่สั่งซื้อ',
				issued_date: 'วันที่ออกเอกสาร',
				notes: 'กรุณาตรวจสอบรายละเอียดของสินค้าและข้อมูลของผู้ซื้อ-ผู้ขายให้เรียบร้อยก่อนการนำส่งเอกสาร',
			},
			remark: {
				header: 'หมายเหตุ',
				remark: 'ระบุข้อมูลอื่นๆ'
			},
			save_success: 'บันทึกข้อมูลเรียบร้อยแล้ว',
		},
	},
	page: {
		title: 'เว็บเพจ',
		header: 'รายการเว็บเพจ',
		action: 'เพิ่มรายการ',
		import_action: 'ดาวน์โหลดเว็บเพจตั้งต้น',
		limit: 'จำนวนหน้าที่สร้างแล้ว %{total}/%{limit} หน้า',
		no_data: {
			title: 'ไม่พบรายการเว็บเพจ',
			desc: 'คุณสร้างสามารถสร้างเว็บเพจของคุณเองได้',
			desc_2: 'ผ่านตัวช่วยสร้างเว็บเพจที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
			btn_add: 'เพิ่มเว็บเพจใหม่ทันที',
			remark: 'เพื่อดำเนินการต่อ',
		},
		card: {
			default: 'หน้าหลัก',
		},
		sorting: {
			item: 'ชื่อ ก-ฮ',
			item2: 'ล่าสุด',
		},
		create: {
			header: 'สร้างข้อมูลเว็บเพจ',
			save_fail: 'ไม่สามารถบันทึกข้อมูลเว็บเพจ เนื่องจากลิงค์โปรโมทมีอยู่แล้วในระบบ',
		},
		main: {
			title: 'ดาวน์โหลดเว็บเพจตั้งต้น',
			subheader: 'ด้านล่างนี้เป็นเว็บเพจกลางที่ผู้ใช้สามารถนำไปใช้โปรโมทได้',
			item_action: 'ดาวน์โหลด',
			item_status: 'ดาวน์โหลดแล้ว',
		},
		detail: {
			title: 'แก้ไขรายการ​เว็บเพจ',
			header: 'จัดการข้อมูลเว็บเพจ',
			shopping_header: 'โปรโมทเว็บไซต์สินค้า',
			tab: {
				title: 'Banner',
				action: 'เพิ่มรายการ',
				result_title: 'รายการแบนเนอร์ทั้งหมด',
				item_col: 'สร้างโดย',
				item_col2: 'แก้ไขล่าสุด',
				no_data: {
					title: 'ไม่พบรายการแบนเนอร์',
					desc: 'คุณสร้างสามารถสร้างแบนเนอร์ของคุณเองได้',
					desc_2: 'ผ่านตัวช่วยสร้างแบนเนอร์ที่ระบบจัดเตรียมไว้ให้สำหรับคุณ, เพียงคลิก',
					btn_add: 'เพิ่มแบนเนอร์ใหม่ทันที',
					remark: 'เพื่อดำเนินการต่อ',
				},
				delete_success: 'ลบแบนเนอร์เรียบร้อยแล้ว',
				create: {
					header: 'สร้างแบนเนอร์',
					header_2: 'แก้ไขแบนเนอร์',
					title: 'ชื่อแบนเนอร์',
					title_hint: 'จำกัดตัวอักษร %{count}/100',
					href: 'แนบลิงค์',
					href_placeholder: 'Ex. https://www.example.com',
					image: {
						header: 'อัพโหลดภาพ',
						hint: 'คลิกหรือลากไฟล์มาตรงนี้',
						info: 'ขนาดภาพแนะนำ 1920x1280'
					},
					validator: {
						title: '^กรุณากรอกชื่อแบนเนอร์',
					},
					save_success: 'บันทึกแบนเนอร์เรียบร้อยแล้ว',
				},
			},
			tab2: {
				title: 'Page Editor',
				title_2: 'Thanks Editor',
				no_data: 'ไม่พบการแสดงผลในหน้านี้ กรุณาเลือกเครื่องมือทางด้านขวา',
				save_success: 'บันทึกข้อมูลเว็บเพจเรียบร้อยแล้ว',
			},
			tab3: {
				title: 'Analytics',
				seo_title: 'Google SEO',
				seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
				meta_title: 'Title',
				meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
				meta_desc: 'Description',
				meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
				meta_keyword: 'Keyword',
				meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
				meta_keyword_placeholder: 'กรอกคีย์เวิร์ดและกดปุ่ม "Enter"',
				pixel_title: 'Facebook Pixel',
				pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
				og_title: 'Title',
				og_title_hint: 'จำกัดตัวอักษร %{count}/95',
				og_desc: 'Description',
				og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
				og_image: 'ภาพประกอบ',
				og_image_hint: 'คลิกหรือลากไฟล์มาตรงนี้',
				og_image_info: 'ขนาดภาพแนะนำ 1200x630',
				validator: {
					meta_title: '^กรุณากรอก Title',
					meta_desc: '^กรุณากรอก Description',
					og_title: '^กรุณากรอก Title',
					og_desc: '^กรุณากรอก Description',
				},
				save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
			},
			tab4: {
				title: 'Ads & Pixel',
				google: {
					title: 'Google Tag Manager',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					gtm_container_id: 'รหัสคอนเทนเนอร์',
					validator: {
						gtm_container_id: '^กรุณากรอกรหัสคอนเทนเนอร์',
					}
				},
				facebook: {
					title: 'Facebook Pixel',
					sub_header: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เพื่อบันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์',
					facebook_domain_verify: 'รหัสยืนยันโดเมน',
					facebook_domain_verify_hint: 'อัพเกรดความน่าเชื่อถือของโดเมนเพื่อรองรับ iOS 14',
					main_facebook_pixel: 'Pixel หลัก',
					others_facebook_pixel: 'Pixel รอง',
					others_facebook_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_facebook_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					main_facebook_token: 'Conversion Token หลัก',
					others_facebook_token: 'Conversion Token รอง',
					others_facebook_token_hint: 'ไม่จำกัดจำนวน Conversion Token รอง',
					others_facebook_token_placeholder: 'กรอก Conversion Token และกดปุ่ม "Enter"',
					validator: {
						facebook_domain_verify: '^กรุณากรอกรหัสยืนยันโดเมน',
						main_facebook_pixel: '^กรุณากรอก Pixel หลัก',
						main_facebook_token: '^กรุณากรอก Conversion Token หลัก',
					}
				},
				tiktok: {
					title: 'Tiktok - Website Pixel',
					sub_header: 'เพิ่มประสิทธิภาพในการเข้าถึงเว็บไซต์ของคุณผ่าน Tiktok',
					main_tiktok_pixel: 'Pixel หลัก',
					others_tiktok_pixel: 'Pixel รอง',
					others_tiktok_pixel_hint: 'ไม่จำกัดจำนวน Pixel รอง',
					others_tiktok_pixel_placeholder: 'กรอก Pixel และกดปุ่ม "Enter"',
					validator: {
						main_tiktok_pixel: '^กรุณากรอก Pixel หลัก',
					}
				},
				save_success: 'บันทึกข้อมูล Ads & Pixel เรียบร้อยแล้ว',
			},
		}
	},
	apps: {
		shipping: {
			title: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			header: 'ตั้งค่าเชื่อมต่อระบบขนส่ง',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					is_cod_enabled: 'เปิดการใช้งานตัวเลือกชำระเงิน Cash on Delivery (COD)',
					is_cod_enabled_hint: 'เปิดเพื่อให้ลูกค้าสามารถเลือกรูปแบบการชำระเงินกับระบบขนส่งได้',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการส่งสินค้าผ่าน Shippop',
					is_enabled_hint: 'เชื่อมต่อกับ Shippop หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณากรอกแบบฟอร์มด้านล่างเพื่อรอเจ้าหน้าที่ติดต่อกลับ, หรือปิดตัวเลือกนี้หากต้องการจัดการส่งสินค้าดัวตัวเอง',
					api_key: 'API Key',
					api_key_placeholder: 'เช่น dv2e3e186c8a17f392451502354b134ae...',
					api_key_hint: 'ข้อมูลนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ Shippop แล้ว',
				},
				embeded: {
					header: 'สมัครเพื่อเปิดใช้งานขนส่งกับ Shippop',
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'รายการขนส่ง',
				header: 'รายการขนส่งทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการขนส่งที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
		payment: {
			title: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			header: 'ตั้งค่าเชื่อมต่อระบบจ่ายเงิน',
			tab: {
				title: 'ตั้งค่าทั่วไป',
				general: {
					header: 'ข้อมูลการตั้งค่าทั่วไป',
					bank_code: 'ชื่อธนาคาร',
					bank_account_no: 'หมายเลขบัญชีธนาคาร',
					bank_account_no_placeholder: 'XXX-X-XXXXX-X',
					bank_account_name: 'ชื่อบัญชีธนาคาร',
					bank_account_name_placeholder: 'ชื่อเจ้าของบัญชี',
					is_enabled: 'เปิดการใช้งานเชื่อมต่อการจ่ายเงินผ่าน GB Prime Pay',
					is_enabled_hint: 'เชื่อมต่อกับ GB Prime Pay หากคุณยังไม่ได้สมัครเพื่อเปิดการใช้งาน, กรุณาจัดเตรียมเอกสารตามแบบฟอร์มด้านล่าง, หรือปิดตัวเลือกนี้หากต้องการใช้เฉพาะวิธีการจ่ายแบบโอนผ่านบัญชีธนาคาร',
					token_key: 'Token Key',
					token_key_placeholder: 'เช่น XKfU1U4NMU20umP7DsDuYgf5iQMcweOiF...',
					public_key: 'Public Key',
					public_key_placeholder: 'เช่น giYI8590ihq14zdTOEDeVK6JhG6qd1Nq',
					secret_key: 'Secret Key',
					secret_key_placeholder: 'เช่น q3YzkesZgHOe4bTrtdfVE15uHl1hJffo',
					secret_key_hint: 'ข้อมูล 3 ชุดนี้จะได้รับหลังจากสมัครเริ่มใช้งานกับ GB Prime Pay แล้ว',
					validator: {
						bank_code: '^กรุณาเลือกธนาคาร',
						bank_account_no: '^กรุณากรอกหมายเลขบัญชีธนาคาร',
						bank_account_name: '^กรุณากรอกชื่อบัญชีธนาคาร',
					}
				},
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			},
			tab2: {
				title: 'วิธีการจ่ายเงิน',
				header: 'รายการวิธีจ่ายเงินทั้งหมด',
				subheader: 'เปิด-ปิด เพื่อเลือกรายการวิธีจ่ายเงินที่คุณต้องการเปิดให้ลูกค้าเลือกใช้บริการ',
				save_success: 'บันทึกข้อมูลการตั้งค่าเรียบร้อยแล้ว',
			}
		},
	},
	website: {
		title: 'ตั้งค่าเว็บไซต์หลัก',
		header: 'เว็บไซต์หลัก',
		tab: {
			title: 'ข้อมูลทั่วไป',
			image: {
				header: 'โลโก้และไอคอน',
				logo: 'โลโก้บนหน้าเว็บไซต์',
				logo_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลดโลโก้',
				logo_info: 'ขนาดความสูงภาพแนะนำ 64px (ความกว้างขึ้นอยู่กับสัดส่วนภาพ)',
				favicon: 'Favicon',
				favicon_desc: 'ไอคอนสำหรับแสดงผลบน Web browser และ Bookmark, ขนาดภาพแนะนำ 64x64',
				favicon_hint: 'คลิกหรือลากไฟล์ตรงนี้เพื่ออัพโหลด Favicon',
				favicon_info: 'ขนาดภาพแนะนำ 64x64',
			},
			general: {
				header: 'General',
				site_title: 'Site title',
				site_title_hint: 'จำกัดตัวอักษร %{count}/50',
				tagline: 'Tagline',
				tagline_hint: 'คำอธิบายสั้นๆ สำหรับเว็บไซต์, จำกัดตัวอักษร %{count}/100',
				editor: 'ข้อความต้อนรับ',
				editor_placeholder: 'กรอกข้อความต้อนรับ',
				is_welcome_enabled: 'เปิดเผยต่อสาธารณะ',
				is_welcome_enabled_hint: 'เปิด-ปิด เพื่อตั้งค่าการแสดงผลข้อความต้อนรับบนเว็บไซต์ผู้มุ่งหวัง',
				validator: {
					site_title: '^กรุณากรอก Site title',
					tagline: '^กรุณากรอก Tag line',
					email: '^กรุณากรอกอีเมล',
					phone: '^กรุณากรอกเบอร์โทรศัพท์',
					address: '^กรุณากรอกที่อยู่',
				},
			},
			social: {
				header: 'Social Network',
				line: 'Line Id',
				line_placeholder: '@line',
				facebook: 'Facebook',
				facebook_placeholder: 'www.facebook.com',
				instagram: 'Instagram',
				instagram_placeholder: 'www.instagram.com',
			},
			save_success: 'บันทึกข้อมูลเว็บไซต์หลักเรียบร้อยแล้ว',
		},
		tab2: {
			title: 'Analytics',
			seo_title: 'Google SEO',
			seo_subheader: 'เพิ่มจำนวและคุณภาพ Traffic การเข้าถึงเว็บไซต์ของคุณผ่าน Organic search engine',
			meta_title: 'Title',
			meta_title_hint: 'จำกัดตัวอักษร %{count}/60',
			meta_desc: 'Description',
			meta_desc_hint: 'จำกัดตัวอักษร %{count}/160',
			meta_keyword: 'Keyword',
			meta_keyword_hint: 'จำกัดตัวอักษร %{count}/255',
			meta_keyword_placeholder: 'Enter keyword and then press "Enter"',
			pixel_title: 'Facebook Pixel',
			pixel_subheader: 'ชุดสคริปต์ที่เก็บข้อมูลกิจกรรมบนเว็บไซต์ เป็น Analytics Tools ที่ช่วยให้ Facebook บันทึกข้อมูลที่เกิดขึ้นบนเว็บไซต์ได้',
			og_title: 'Title',
			og_title_hint: 'จำกัดตัวอักษร %{count}/95',
			og_desc: 'Description',
			og_desc_hint: 'จำกัดตัวอักษร %{count}/155',
			og_image: 'ภาพประกอบ',
			og_image_info: 'ขนาดภาพแนะนำ 1200x630',
			validator: {
				meta_title: '^กรุณากรอก Title',
				meta_desc: '^กรุณากรอก Description',
				og_title: '^กรุณากรอก Title',
				og_desc: '^กรุณากรอก Description',
			},
			save_success: 'บันทึกข้อมูล Analytics เรียบร้อยแล้ว',
		}
	},
};
