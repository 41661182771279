export default {
	title: 'ติดตั้งแอพพลิเคชัน',
	crm: {
		install: {
			loading: 'จัดเตรียมข้อมูลเริ่มต้น.....',
			loading_2: 'วิเคราะห์ข้อมูลคำสั่งซื้อทั้งหมด',
			loading_3: 'นำเข้าข้อมูลลูกค้าจากคำสั่งซื้อ',
			loading_4: 'เชื่อมโยงข้อมูลกับผู้ใช้งานกับระบบ',
			done: 'ติดตั้งบริการสำเร็จแล้ว',
			start: 'เริ่มต้นใช้งาน'
		}
	},
	accounting: {
		install: {
			loading: 'จัดเตรียมข้อมูลเริ่มต้น.....',
			loading_2: 'วิเคราะห์รูปแบบค่าใช้จ่าย',
			loading_3: 'เชื่อมโยงต้นทุนสินค้าทั้งหมด',
			done: 'ติดตั้งบริการสำเร็จแล้ว',
			start: 'เริ่มต้นใช้งาน'
		}
	},
	stock: {
		install: {
			loading: 'จัดเตรียมข้อมูลเริ่มต้น.....',
			loading_2: 'วิเคราะห์ข้อมูลสินค้าทั้งหมด',
			loading_3: 'เชื่อมโยงข้อมูลกับผู้ใช้งานกับระบบ',
			done: 'ติดตั้งบริการสำเร็จแล้ว',
			start: 'เริ่มต้นใช้งาน'
		}
	}
};
