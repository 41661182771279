export default {
	login: {
		title: 'Marketing Workspace',
		header: 'Marketing Workspace',
		email: 'อีเมล / ชื่อล็อกอิน',
		password: 'รหัสผ่าน',
		login: 'เข้าสู่ระบบ',
		forgot_password: 'ลืมรหัสผ่าน ?',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
			password: '^กรุณากรอกรหัสผ่าน'
		},
		login_fail: 'อีเมล์ / ชื่อล็อกอิน หรือรหัสผ่านไม่ถูกต้อง, ลองอีกครั้ง!',
	},
	forgot_password: {
		title: 'Marketing Workspace',
		header: 'ระบบจะส่งชื่อผู้ใช้และรหัสผ่านใหม่ ไปยังอีเมลที่ระบุด้านล่างนี้',
		email: 'อีเมล',
		forgot: 'ส่งคำขอรหัสผ่าน',
		validator: {
			email: '^กรุณากรอกอีเมล์',
			email_invalid: '^อีเมล์ไม่ถูกต้อง',
		},
		save_success: 'ระบบทำการส่งรายละเอียดเข้าอีเมลเรียบร้อยแล้ว',
		save_fail: 'อีเมล์นี้ไม่มีในระบบ กรุณาติดต่อผู้ดูแลอีกครั้ง',
	},
	privacy: {
		title: 'สัญญาเงื่อนไขและกฏระเบียบ',
		sub_header: 'สัญญาเงื่อนไขและกฏระเบียบ',
		btn_action: 'ดาวน์โหลดเอกสาร PDF'
	},
};
