import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Typography,
} from '@material-ui/core';

import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	bar: {
		display: 'flex',
		alignItems: 'center',
		width: '10%',
		height: 36,
		borderRadius: 18,
		backgroundColor: theme.palette.primary.main,
		transition: '1.0s width ease',
		'& > span': {
			marginLeft: theme.spacing(2),
			color: theme.palette.common.white
		},
	},
	caption: {
		marginTop: -4
	}
}));

function Bar({
	className, color, data, max, total, ...rest
}) {
	const classes = useStyles();

	const [width, setWidth] = useState(10);

	useEffect(() => {
		setTimeout(() => {
			setWidth((data.total_price * 100) / max);
		}, 1500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			{...rest}
			className={clsx(classes.root, className)}
		>
			<Box
				className={classes.bar}
				style={{ width: `${width}%`, backgroundColor: color }}
			>
				<Typography
					variant="body1"
					component="span"
				>
					{data.name}
				</Typography>
			</Box>
			<Box marginLeft={1}>
				<Typography
					variant="body1"
				>
					<NumberFormat value={data.total_price} displayType="text" prefix="฿" thousandSeparator />
				</Typography>
				{/* <Typography
					className={classes.caption}
					variant="body2"
				>
					{((data.total_price * 100) / total).toFixed(0)}
					{'%'}
				</Typography> */}
			</Box>
		</Box>
	);
}

Bar.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	data: PropTypes.object,
	max: PropTypes.number,
	total: PropTypes.number
};

export default Bar;
